import * as React from 'react';
import { ReactComponent as Icon } from 'assets/icons/icon_loading.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        display: 'inline-block',
        animation: '$circular-rotate 1.0s linear infinite',
    },
    '@keyframes circular-rotate': {
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
    svg: {
        display: 'block',
    },
});

export default function Loading() {
    const classes = useStyles();
    return (
        <div id={'loading'} className={classes.root}>
            <Icon title={'Loading'} className={classes.svg} />
        </div>
    );
}
