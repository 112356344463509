import { combineEpics } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, tap, ignoreElements } from 'rxjs/operators';

import {
    createCart as createCartService,
    saveForLater as saveForLaterService,
    placeOrder as placeOrderService,
    updateCart as updateCartService,
} from '../services/cartSevices';
import {
    createCart,
    createCartSuccess,
    createCartError,
    saveForLater,
    placeOrder,
    placeOrderSuccess,
    placeOrderError,
    updateCartSuccess,
    updateCartError,
    updateCart,
} from '../actions/cartActions';
import { RootState } from '../reducers';
import { selectCart } from '../selectors/cart';
import { Dependencies } from '../index';
import { getOrder } from '../actions/orderActions';
import { AppRoute } from '../../models/route';
import { selectProductCriteriaState } from '../selectors/productCriteria';

const EVENT_CATEGORY_CART = 'Cart Behavior';
export const createCart$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(createCart),
        withLatestFrom(state$.pipe(map(selectCart)), state$.pipe(map(selectProductCriteriaState))),
        switchMap(([action, cart, productCriteria]) =>
            createCartService(
                {
                    email: cart?.contactInformation?.email,
                    items: cart?.items,
                    contactInformation: cart?.contactInformation,
                },
                {
                    gaProductListName: productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search',
                    gaEventCategory: EVENT_CATEGORY_CART,
                }
            ).pipe(
                map((resp) => saveForLater(resp.data)),
                catchError((error) => {
                    alert.error('Unable to create cart');
                    return of(createCartError(error));
                })
            )
        )
    );

export const saveForLater$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(saveForLater),
        switchMap((action) =>
            saveForLaterService(action.payload).pipe(
                map((resp) => createCartSuccess(resp.data)),
                catchError((error) => {
                    alert.error('Unable to save for later');
                    return of(createCartError(error));
                })
            )
        )
    );

export const placeOrder$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(placeOrder),
        switchMap((action) =>
            updateCartService(action.payload.cart.cartId as string, action.payload.cart).pipe(
                map((cart) => ({ action, cart: cart.data, error: null })),
                catchError((error) => {
                    return of({ action, error, cart: null });
                })
            )
        ),
        switchMap(({ action, error }) => {
            if (error) {
                alert.error('Unable to place an order');
                return of(placeOrderError(error));
            }
            return placeOrderService(action.payload.cart.cartId as string, action.payload.paymentMethod).pipe(
                switchMap((resp) => {
                    // alert.success('Your order had been successfully save');
                    return [placeOrderSuccess(resp.data), getOrder({ id: resp.data.id, orderId: resp.data.orderId })];
                }),
                catchError((error) => {
                    alert.error('Unable to place an order');
                    return of(placeOrderError(error));
                })
            );
        })
    );

export const updateCart$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(updateCart),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) => {
            if (!cart.cartId) {
                throw new Error('cartId is required.');
            }
            return updateCartService(cart.cartId, action.payload.cart).pipe(
                map((resp) => updateCartSuccess(resp.data)),
                catchError((error) => {
                    alert.error('Unable to update cart');
                    return of(updateCartError(error));
                })
            );
        })
    );

export const navigateToChoosePaymentOptions$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(createCartSuccess),
        tap(() => history.push(AppRoute.ChoosePaymentOption)),
        ignoreElements()
    );

export default combineEpics(createCart$, saveForLater$, placeOrder$, updateCart$, navigateToChoosePaymentOptions$);
