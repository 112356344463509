import React, { Fragment } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/made-in-usa/usa_story_image.png';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';

function MadeInUsa() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
        };
    });
    const classes = useStyles();
    return (
        <SubPage
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Made in USA'} />
                    </Grid>
                    <Grid item xs={12} className={classes.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Energy Star'} />
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading
                            variant={'h3'}
                            color={'primary'}
                            label={'Designed, Engineered, and Assembled in the USA  '}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            All Goodman HVAC equipment is proudly Designed, Engineered, and Assembled in America.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Goodman's new manufacturing facility, currently the largest HVAC building in the USA, is
                            located just outside of Houston, TX. The 4.2 million square feet Daikin Texas Technology
                            Park brings together Goodman’s manufacturing, engineering, logistics, and customer support
                            under one very large roof.
                        </Paragraph>
                    </Grid>

                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Why American Pride Matters'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            By keeping assembly operations in the US, Goodman can provide these benefits:
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12}>
                        <ul>
                            <li>
                                <Paragraph>Provide jobs for Americans</Paragraph>
                            </li>
                            <li>
                                <Paragraph>Harness the innovation and productivity of America</Paragraph>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            <Link href={'https://www.goodmanmfg.com/about/why-goodman'} target={'_blank'}>
                                {' Five Reasons to Select the Goodman Brand'}
                            </Link>
                        </Paragraph>
                        <Paragraph>
                            <Link href={'https://www.goodmanmfg.com/resources/customer-reviews'} target={'_blank'}>
                                {' Customer Reviews'}
                            </Link>
                        </Paragraph>
                    </Grid>
                </Fragment>
            }
        />
    );
}

export default MadeInUsa;
