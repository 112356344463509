import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Button';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import ContentContainer from 'components/ContentContainer';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
import { ThemeWithImages } from '../../themes/theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppRoute } from '../../models/route';
import { useHistory } from 'react-router-dom';
import { openChatWindow } from '../../store/services/acquireServices';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(
    (theme: Theme) => ({
        homeAvatar: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
        heading: {
            paddingBottom: theme.spacing(2),
        },
        content: {
            paddingBottom: theme.spacing(4),
        },
    }),
    { name: 'home-how-this-work' }
);

function HowThisWork(props: WithWidth) {
    const classes = useStyles();
    const theme = useTheme<ThemeWithImages>();
    const history = useHistory();
    const iPadProPortrait = useMediaQuery('(max-width:1024px)'); // handle for iPad Pro 12.9" Portrait view
    return (
        <ContentContainer disableGrid background={'bg3'}>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer size={'large'}>
                    <Grid container direction={'column'}>
                        {/*Heading*/}
                        <Grid className={classes.heading} item container justify={'center'}>
                            <Grid item md={iPadProPortrait ? 9 : 8} container justify={'center'}>
                                <Heading
                                    variant={'h2'}
                                    color={'primary'}
                                    align={'center'}
                                    label={'The New Way to Buy HVAC! How does it work?'}
                                />
                            </Grid>
                        </Grid>
                        {/*Heading*/}
                        {/*Content*/}
                        <Grid className={classes.content} item container justify={'center'}>
                            <Grid item md={10} container justify={'center'}>
                                <Grid container spacing={3}>
                                    <Hidden xsDown>
                                        <Grid item sm={1} md={2} />
                                    </Hidden>
                                    <Grid item xs={12} sm={5} md={2}>
                                        <Grid
                                            container
                                            direction={'column'}
                                            justify={'center'}
                                            alignItems={'center'}
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Avatar src={theme.images.homeHow2} className={classes.homeAvatar}>
                                                    Choose your equipment
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                                    Choose your equipment
                                                </Paragraph>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={2}>
                                        <Grid
                                            container
                                            direction={'column'}
                                            justify={'center'}
                                            alignItems={'center'}
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Avatar src={theme.images.homeHow3} className={classes.homeAvatar}>
                                                    Financing or Payment Options
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                                    Financing or Payment Options
                                                </Paragraph>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Hidden xsDown mdUp>
                                        <Grid item sm={1} />
                                        <Grid item sm={1} />
                                    </Hidden>
                                    <Grid item xs={12} sm={5} md={2}>
                                        <Grid
                                            container
                                            direction={'column'}
                                            justify={'center'}
                                            alignItems={'center'}
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Avatar src={theme.images.homeHow4} className={classes.homeAvatar}>
                                                    {' '}
                                                    Schedule an Pre-Installation Inspection
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                                    Schedule an Pre-Installation Inspection
                                                </Paragraph>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={2}>
                                        <Grid
                                            container
                                            direction={'column'}
                                            justify={'center'}
                                            alignItems={'center'}
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Avatar src={theme.images.homeHow5} className={classes.homeAvatar}>
                                                    Schedule your Installation & Enjoy!
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                                    Schedule your Installation & Enjoy!
                                                </Paragraph>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/*<Hidden xsDown mdUp>*/}
                                    {/*    <Grid item sm={1} />*/}
                                    {/*    <Grid item sm={1} />*/}
                                    {/*</Hidden>*/}

                                    {/*<Hidden xsDown mdUp>*/}
                                    {/*    <Grid item sm={5} />*/}
                                    {/*</Hidden>*/}
                                    <Hidden xsDown>
                                        <Grid item sm={1} md={2} />
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*Content*/}
                        <Grid item />
                        {/*Button*/}
                        <Grid item container justify={'center'} spacing={2}>
                            <Grid item xs={12} sm={'auto'} md={'auto'}>
                                <Button
                                    id={'how-this-work-kc-btn'}
                                    color={'secondary'}
                                    onClick={() => {
                                        history.push(AppRoute.WhatIsOurProcess);
                                    }}
                                >
                                    Our Process
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={'auto'} md={'auto'}>
                                <Button
                                    id={'how-this-work-chat-btn'}
                                    color={'primary'}
                                    onClick={() => {
                                        openChatWindow();
                                    }}
                                >
                                    Let's chat!
                                </Button>
                            </Grid>
                        </Grid>
                        {/*Button*/}
                    </Grid>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}

export default withWidth()(HowThisWork);
