import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import ProTipAndAdditionalLinks from 'components/subpage/ProTipAndAdditionalLinks';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/knowledges/kc_packaged-unit_story_image.png';
import { getSubPageMenus } from './menu-util';

function PackagedUnits() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(5),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            contentInteractive: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(2),
            },
        };
    });

    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('packaged_units'),
    };
    const classes = useStyles();
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Packaged Units'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph align={'justify'}>
                            A packaged unit is an "all-in-one system" that can provide both cooling and heating from a
                            single piece of equipment and can be located on the ground or roof.{' '}
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Packaged Units'} />
                    </Grid>
                </Fragment>
            }
            content2={
                <ProTipAndAdditionalLinks
                    proTip={{
                        headLine: 'Pro Tip',
                        paragraph: (
                            <Fragment>
                                SEER ratings of new systems we install start at 14 SEER and go up from there. The higher
                                the SEER rating, the less energy the unit uses, which means you save money on your
                                electricity bill.{' '}
                            </Fragment>
                        ),
                    }}
                    additionalLinks={{
                        headLine: 'Want to learn more?  Check out these links:',
                        links: [
                            {
                                text: 'Learn More About Packaged Units ',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/all-in-one-comfort-how-an-hvac-packaged-system-works',
                            },
                            {
                                text: 'Goodman Customer Reviews',
                                url: 'https://www.goodmanmfg.com/resources/customer-reviews',
                            },
                        ],
                    }}
                />
            }
        />
    );
}

export default PackagedUnits;
