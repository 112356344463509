import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppRoute } from 'models/route';
import { Order } from 'models/order';
import { selectOrder } from 'store/selectors/order';
import Confirmation from './Confirmation';

function ConfirmationContainer() {
    const order: Order = useSelector(selectOrder);

    // validation state before render
    if (!order || !order.id) {
        return <Redirect to={AppRoute.Home} />;
    }

    return <Confirmation order={order} />;
}

export default ConfirmationContainer;
