import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthDown, isWidthUp, WithWidth } from '@material-ui/core/withWidth';
import CreditCard from 'components/icons/CreditCard';
import ContentContainer from 'components/ContentContainer';
import { AppRoute } from '../models/route';
import { FooterLink, ExternalLink } from 'components/Link';
import { Logo } from './Logo';
import Container from '@material-ui/core/Container';
import Facebook from './icons/Facebook';
import Instagram from './icons/Instagram';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {},
        credit: {
            paddingTop: theme.spacing(0.5),
        },
        links: {
            marginTop: theme.spacing(-1),
            '& .MuiGrid-item': {
                padding: theme.spacing(0.5),
            },
        },
    }),
    { name: 'footer' }
);

function Footer(props: WithWidth) {
    const classes = useStyles();
    const { width } = props;
    const isXs = isWidthDown('xs', width);
    const isMd = isWidthUp('md', width);
    return (
        <ContentContainer disableGrid background={'footer'} className={classes.root}>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer size={'footer'}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2} md={1} container justify={'center'}>
                            <Logo color={'secondary'} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} container justify={isXs ? 'center' : undefined}>
                            <Grid item className={classes.credit}>
                                <CreditCard />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                            <Grid container className={classes.links} alignItems={isXs ? 'center' : 'flex-start'}>
                                <Grid item={!isXs} xs={12} sm={12} md={4} container={!isXs}>
                                    <Grid item xs={12} container={isXs} justify={isXs ? 'center' : undefined}>
                                        <Grid item container spacing={2} justify={isXs ? 'center' : undefined}>
                                            <Grid item>
                                                <ExternalLink
                                                    id={'external-link-facebook'}
                                                    variant={'subtitle2'}
                                                    color={'secondary'}
                                                    href={'https://www.facebook.com/AirAndHeatDoneRight/'}
                                                >
                                                    <Facebook />
                                                </ExternalLink>
                                            </Grid>
                                            <Grid item>
                                                <ExternalLink
                                                    id={'external-link-instrgram'}
                                                    variant={'subtitle2'}
                                                    color={'secondary'}
                                                    href={'https://www.instagram.com/airandheatdoneright/'}
                                                >
                                                    <Instagram />
                                                </ExternalLink>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container={isXs} justify={isXs ? 'center' : undefined}>
                                        <ExternalLink
                                            id={'external-link-email-sales'}
                                            variant={'subtitle2'}
                                            color={'secondary'}
                                            href={'mailto:sales@airandheatdoneright.com'}
                                            label={'Email Sales'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} container={isXs} justify={isXs ? 'center' : undefined}>
                                        <ExternalLink
                                            id={'external-link-email-cs'}
                                            variant={'subtitle2'}
                                            color={'secondary'}
                                            href={'mailto:cs@airandheatdoneright.com'}
                                            label={'Email Customer Support'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} container={isXs} justify={isXs ? 'center' : undefined}>
                                        <ExternalLink
                                            id={'external-link-tel-sales'}
                                            variant={'subtitle2'}
                                            color={'secondary'}
                                            href={'tel:8333717706'}
                                            label={'(833) 371-7706'}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item={!isXs} xs={12} sm={12} md={4} container={!isXs}>
                                    <Grid
                                        item
                                        xs={isXs && 12}
                                        md={isMd && 12}
                                        container={isXs}
                                        justify={isXs ? 'center' : undefined}
                                    >
                                        <FooterLink
                                            id={'footer-link-financing'}
                                            color={'secondary'}
                                            href={AppRoute.Financing}
                                            label={'Financing Made Easy'}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={isXs && 12}
                                        md={isMd && 12}
                                        container={isXs}
                                        justify={isXs ? 'center' : undefined}
                                    >
                                        <FooterLink
                                            id={'footer-link-knowledge-center'}
                                            color={'secondary'}
                                            href={AppRoute.KnowledgeCenter}
                                            label={'Knowledge Center'}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={isXs && 12}
                                        md={isMd && 12}
                                        container={isXs}
                                        justify={isXs ? 'center' : undefined}
                                    >
                                        <FooterLink
                                            id={'footer-link-who-we-are'}
                                            color={'secondary'}
                                            href={AppRoute.WhoWeAre}
                                            label={'Who We Are'}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={isXs && 12}
                                        md={isMd && 12}
                                        container={isXs}
                                        justify={isXs ? 'center' : undefined}
                                    >
                                        <FooterLink
                                            id={'footer-link-pricing'}
                                            color={'secondary'}
                                            href={AppRoute.AboutYourHome}
                                            label={'Pricing'}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={isXs && 12}
                                        md={isMd && 12}
                                        container={isXs}
                                        justify={isXs ? 'center' : undefined}
                                    >
                                        <FooterLink
                                            id={'footer-link-warranty'}
                                            color={'secondary'}
                                            href={AppRoute.Warranty}
                                            label={'Warranty'}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item={!isXs} xs={12} sm={12} md={4} container={!isXs}>
                                    <Grid
                                        item
                                        xs={isXs && 12}
                                        md={isMd && 12}
                                        container={isXs}
                                        justify={isXs ? 'center' : undefined}
                                    >
                                        <FooterLink
                                            id={'footer-link-energy-star'}
                                            color={'secondary'}
                                            href={AppRoute.EnergyStar}
                                            label={'Energy Star'}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={isXs && 12}
                                        md={isMd && 12}
                                        container={isXs}
                                        justify={isXs ? 'center' : undefined}
                                    >
                                        <FooterLink
                                            id={'footer-link-made-in-usa'}
                                            color={'secondary'}
                                            href={AppRoute.MadeInUsa}
                                            label={'Made in US'}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={isXs && 12}
                                        md={isMd && 12}
                                        container={isXs}
                                        justify={isXs ? 'center' : undefined}
                                    >
                                        <FooterLink
                                            id={'footer-link-security-and-privacy-policy'}
                                            color={'secondary'}
                                            href={AppRoute.SecurityAndPrivacyPolicy}
                                            label={'Security & Privacy Policy'}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={isXs && 12}
                                        md={isMd && 12}
                                        container={isXs}
                                        justify={isXs ? 'center' : undefined}
                                    >
                                        <FooterLink
                                            id={'footer-link-terms-and-conditions'}
                                            color={'secondary'}
                                            href={AppRoute.TermsAndConditions}
                                            label={'Terms & Conditions'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}

export default withWidth()(Footer);
