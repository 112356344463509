import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import SubPageInteractive from 'components/subpage/SubPageInteractive';
import ProTipAndAdditionalLinks from 'components/subpage/ProTipAndAdditionalLinks';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/knowledges/kc_heat-pumps_story_image.png';
import { getSubPageMenus } from './menu-util';
import VideoOverlay from '../../components/VideoOverlay';

function HeatPumps() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(5),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            contentInteractive: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(2),
            },
        };
    });

    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('heat_pumps'),
    };
    const classes = useStyles();
    const [openVdo, setOpenVdo] = React.useState(false);
    const handleCloseVdo = () => {
        setOpenVdo(false);
    };
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Heat Pumps'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph align={'justify'}>
                            A heat pump transfers heat from one place to another. It sounds simple, but where is the
                            heat coming from if fuel isn’t being burned? An air source heat pump system uses advanced
                            technology and the refrigeration cycle to heat and cool your home. This allows a heat pump
                            to provide year-round indoor comfort – no matter the season. In the warmer months, the heat
                            pump acts as an air conditioner - drawing out interior heat and humidity, and transfers it
                            outside. During colder months, heat from the outdoor air is extracted and transferred to the
                            interior of your home. Believe it or not, even a 32°F day produces enough heat to warm your
                            home. Science is amazing!
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} container className={classes.contentInteractive}>
                        <SubPageInteractive
                            id={'sub-page-01'}
                            title={'Watch this video to learn how a Heat Pump system keeps you comfortable'}
                            buttonLabel={'Watch'}
                            onClick={() => {
                                setOpenVdo(true);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Heat Pumps'} />
                    </Grid>
                    <VideoOverlay
                        open={openVdo}
                        onClose={handleCloseVdo}
                        heading={'Heat Pumps'}
                        src={
                            'https://res.cloudinary.com/motili/video/upload/v1594976189/ecomm/video/Goodman_HowItWorks_HeatPump_Clean_1080p.mp4'
                        }
                    />
                </Fragment>
            }
            content2={
                <ProTipAndAdditionalLinks
                    proTip={{
                        headLine: 'Pro Tip',
                        paragraph: (
                            <Fragment>
                                SEER ratings of new systems we install start at 14 SEER and go up from there. The higher
                                the SEER rating, the less energy the unit uses, which means you save money on your
                                electricity bill.
                            </Fragment>
                        ),
                    }}
                    additionalLinks={{
                        headLine: 'Want to learn more?  Check out these links:',
                        links: [
                            {
                                text: 'Heat Pump and Gas Furnace: What’s the Difference?',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/whats-the-difference-between-a-heat-pump-and-gas-furnace',
                            },
                            {
                                text: 'What is SEER and Why Does It Matter?',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/before-you-buy/what-is-seer-and-why-does-it-matter',
                            },
                            {
                                text: 'What is an Air Handler?',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/what-is-an-air-handler',
                            },
                            {
                                text: 'What is a Two-Stage AC or Heap Pump?',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/before-you-buy/what-is-a-two-stage-air-conditioner-or-heat-pump',
                            },
                            {
                                text: 'Goodman Customer Reviews',
                                url: 'https://www.goodmanmfg.com/resources/customer-reviews',
                            },
                        ],
                    }}
                />
            }
        />
    );
}

export default HeatPumps;
