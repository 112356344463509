import Axios from 'axios-observable';
import config from '../../config';

const http = Axios.create({
    baseURL: config.apiHost,
    headers: {
        store: config.storeCode,
    },
    timeout: config.httpTimeout,
});

export default http;
