import React, { Fragment } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/financing/financing_story_image.png';
import CardMedia from '@material-ui/core/CardMedia';

function Financing() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
        };
    });
    const classes = useStyles();
    return (
        <SubPage
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Financing Overview'} />
                    </Grid>
                    <Grid item xs={12} className={classes.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Energy Star'} />
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Buy now, pay over time'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            At Mosaic, we know that upgrading your home is an investment. That's why
                            AirandHeatDoneRight.com has partnered with Mosaic to help you finance your project instead
                            of paying upfront. Through Mosaic, you can get pre-approved with one of our lending partners
                            in less than a minute and with no hit to your credit score until your loan funds. Your low
                            monthly payments will begin only once the project is complete.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Affordable'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            A $6,000 HVAC system, financed over 120 months at 10.24% APR for example, will cost you $83
                            per month with financing through Mosaic. The final monthly payment is visible to you even
                            before you apply
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'EASY'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Get pre-approved in less than a minute, no hit to your credit score until your loan funds
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'FLEXIBLE'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            If the total cost of your system installation changes before it is installed, your loan
                            amount and monthly payment will be adjusted.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'PEACE OF MIND'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>Start paying only after your system has been installed</Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'TRANSPARENT'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>No prepayment penalty; all costs are disclosed up-front</Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'A hundred thousand homes and counting '} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Mosaic's mission is to empower millions of people to prosper by creating the best way to
                            finance clean energy solutions. We have helped over a hundred thousand homeowners nationwide
                            finance their HVAC and other clean energy home improvement projects. Homeowners and
                            merchants like AirandHeatDoneRight.com choose us because our process is simple, the terms
                            are transparent, and the monthly payments are affordable.
                        </Paragraph>
                    </Grid>
                </Fragment>
            }
        />
    );
}

export default Financing;
