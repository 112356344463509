import { combineEpics } from 'redux-observable';
import { Action } from 'ts-action';
import { RootState } from '../reducers';
import { autoUpdateServiceWorker$ } from './serviceWorkerEpic';
import { googleAnalytic$, googleAnalyticAddItem$ } from './googleAnalyticEpic';
import store$ from './storeEpics';
import productSearchEpic$ from './productSearchEpic';
import { acquireChat$ } from './acquireEpic';
import hubSpotEpic$ from './hubspotEpics';
import cartEpic$ from './cartEpics';
import { getCountry$ } from './countryEpics';
import financialEpic$ from './financialEpics';
import orderEpics$ from './orderEpics';
import { getProductAttribute$ } from './productAttributesEpic';
import { processCriteria$ } from './productCriteriaEpic';

export const rootEpic = combineEpics<Action, Action, RootState>(
    autoUpdateServiceWorker$,
    googleAnalytic$,
    googleAnalyticAddItem$,
    store$,
    productSearchEpic$,
    cartEpic$,
    acquireChat$,
    hubSpotEpic$,
    financialEpic$,
    getCountry$,
    orderEpics$,
    getProductAttribute$,
    processCriteria$
);
