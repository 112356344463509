import React from 'react';
import { FormikProps, withFormik } from 'formik';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ContentContainer from '../../components/ContentContainer';
import WizardHeader from '../../components/WizardHeader';
import WizardCriteriaFooter from '../../components/WizardCriteriaFooter';
import WizardSelection from '../../components/WizardSelection';
import HeadlineParagraph from '../../components/HeadlineParagraph';
import { HelpButton } from '../../components/HelpButton';
import {
    FurnaceEfficiency,
    FurnaceOrAirHandler,
    HeatPumpOrGasPack,
    HomeSystem,
    PackageSystemLocation,
    ProductCriteria,
    SplitSystemLocation,
} from '../../models/productCriteria';
import { Options } from '../../components/WizardInputs';
import ContentOverlay from '../../components/ContentOverlay';
import { ThemeWithImages } from '../../themes/theme';
import Container from '@material-ui/core/Container';
import WizardTips from './WizardTips';
import { schemaAboutYourSystem } from './wizard-schema';
import { ExternalLink } from '../../components/Link';
import LoadingOverlay from '../../components/common/LoadingOverlay';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${theme.spacing(22)}px)`,
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(14),
        },
    },
    wizard: {
        minHeight: `calc(100vh - ${theme.spacing(28)}px)`,
        [theme.breakpoints.up('md')]: {
            minHeight: `calc(100vh - ${theme.spacing(22)}px)`,
        },
    },
    actions: {
        [theme.breakpoints.up('md')]: {
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
        },
    },
    helpFooter: {
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

interface FormValues {
    homeSystem: string;
    splitLocation: string;
    packageLocation: string;
    furnaceOrAirHandler: string;
    heatPumpOrGasPack: string;
    efficiency: string;
}

interface Props {
    loading: boolean;
    criteria: ProductCriteria;
    onBack: (data: ProductCriteria) => void;
    onNext: (data: ProductCriteria) => void;
    onExit: () => void;
}

const HomeSystemOptions: Options[] = [
    { id: 'home-system-opt1', value: HomeSystem.Split, label: 'Split-System' },
    { id: 'home-system-opt2', value: HomeSystem.Package, label: 'Package System' },
];
const SplitLocationOptions: Options[] = [
    { id: 'split-location-opt1', value: SplitSystemLocation.Garage, label: 'Garage' },
    { id: 'split-location-opt2', value: SplitSystemLocation.Closet, label: 'Closet' },
    { id: 'split-location-opt3', value: SplitSystemLocation.Basement, label: 'Basement' },
    { id: 'split-location-opt4', value: SplitSystemLocation.Attic, label: 'Attic' },
    { id: 'split-location-opt5', value: SplitSystemLocation.CrawlSpace, label: 'Crawl Space' },
];
const PackageLocationOptions: Options[] = [
    { id: 'package-location-opt1', value: PackageSystemLocation.Roof, label: 'On the Roof' },
    { id: 'package-location-opt2', value: PackageSystemLocation.Ground, label: 'On the Ground' },
];
const SplitUnitTypeOptions: Options[] = [
    { id: 'split-unit-type-opt1', value: FurnaceOrAirHandler.Furnace, label: 'Furnace' },
    { id: 'split-unit-type-opt2', value: FurnaceOrAirHandler.AirHandler, label: 'Air Handler or Electric Furnace' },
];
const PackageUnitTypeOptions: Options[] = [
    { id: 'package-unit-type-opt1', value: HeatPumpOrGasPack.GasPack, label: 'Gas Heat' },
    { id: 'package-unit-type-opt2', value: HeatPumpOrGasPack.HeatPump, label: 'Electric Heat or Heat Pump' },
];
const EfficiencyOptions: Options[] = [
    { id: 'efficiency-opt1', value: FurnaceEfficiency.Percent80, label: '≤ 80% Efficient, metal pipe' },
    { id: 'efficiency-opt2', value: FurnaceEfficiency.Percent90, label: '≥ 90% Efficient, plastic pipe(s)' },
];

function WizardYourSystem(props: Props & FormikProps<FormValues>) {
    const [openHelp, setOpenHelp] = React.useState(false);

    const classes = useStyles();
    const { loading, isValid, submitForm } = props;
    const { images } = useTheme<ThemeWithImages>();
    return (
        <div className={classes.root}>
            <LoadingOverlay open={loading} />
            <WizardHeader showBackButton={false} />
            <Divider />
            <div className={classes.main}>
                <ContentContainer disableGrid background={'bg3'}>
                    <Container maxWidth={'lg'} disableGutters>
                        <ContentContainer size={'wizard'} className={classes.wizard}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={5}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <HeadlineParagraph label={'Next...'} />
                                        </Grid>
                                        <Hidden smDown>
                                            <Grid item xs={12}>
                                                <HeadlineParagraph headlineSize={'h3'}>
                                                    <HelpButton
                                                        onClick={() => {
                                                            setOpenHelp(true);
                                                        }}
                                                    />
                                                </HeadlineParagraph>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <WizardSelection
                                                id={'question-home-system'}
                                                name="homeSystem"
                                                pic={images.wizardP2Q1}
                                                label={'What type of heating and cooling system do you currently have?'}
                                                options={HomeSystemOptions}
                                            />
                                        </Grid>
                                        {props.values.homeSystem === HomeSystem.Split && (
                                            <Grid item xs={12}>
                                                <WizardSelection
                                                    id={'question-split-location'}
                                                    name="splitLocation"
                                                    pic={images.wizardP2Q2}
                                                    label={'Where is your indoor unit located?'}
                                                    options={SplitLocationOptions}
                                                />
                                            </Grid>
                                        )}
                                        {props.values.homeSystem === HomeSystem.Split && (
                                            <Grid item xs={12}>
                                                <WizardSelection
                                                    id={'question-split-type'}
                                                    name="furnaceOrAirHandler"
                                                    pic={images.wizardP2Q4}
                                                    label={'What type of indoor unit do you have?'}
                                                    options={SplitUnitTypeOptions}
                                                />
                                            </Grid>
                                        )}
                                        {props.values.homeSystem === HomeSystem.Split &&
                                            props.values.furnaceOrAirHandler === FurnaceOrAirHandler.Furnace && (
                                                <Grid item xs={12}>
                                                    <WizardSelection
                                                        id={'question-furnace-efficiency'}
                                                        name="efficiency"
                                                        pic={images.wizardP2Q6}
                                                        label={'What is the efficiency of your current furnace? '}
                                                        options={EfficiencyOptions}
                                                    />
                                                </Grid>
                                            )}

                                        {props.values.homeSystem === HomeSystem.Package && (
                                            <Grid item xs={12}>
                                                <WizardSelection
                                                    id={'question-package-location'}
                                                    name="packageLocation"
                                                    pic={images.wizardP2Q3}
                                                    label={'Where is your outdoor unit located ?'}
                                                    options={PackageLocationOptions}
                                                />
                                            </Grid>
                                        )}
                                        {props.values.homeSystem === HomeSystem.Package && (
                                            <Grid item xs={12}>
                                                <WizardSelection
                                                    id={'question-package-type'}
                                                    name="heatPumpOrGasPack"
                                                    pic={images.wizardP2Q5}
                                                    label={'What type of outdoor unit do you have?'}
                                                    options={PackageUnitTypeOptions}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ContentContainer>
                    </Container>
                </ContentContainer>
                <Hidden mdUp>
                    <ContentContainer background={'bg2'} disableGrid className={classes.helpFooter}>
                        <ContentContainer>
                            <HelpButton
                                onClick={() => {
                                    setOpenHelp(true);
                                }}
                            />
                        </ContentContainer>
                    </ContentContainer>
                </Hidden>
            </div>
            <div className={classes.actions}>
                <WizardTips>
                    We use only Goodman HVAC systems, which are designed, engineered, and assembled in the U.S. just
                    outside of Houston, TX.
                </WizardTips>
                <WizardCriteriaFooter onBack={() => handleOnBack(props)} onNext={submitForm} disabled={!isValid} />
            </div>

            <HelpContent open={openHelp} onClose={() => setOpenHelp(false)} />
        </div>
    );
}

interface HelpProps {
    open: boolean;
    onClose: () => void;
}

function HelpContent(props: HelpProps) {
    const { open, onClose } = props;
    const contactUs = (
        <>
            <p>
                Contact Sales:{' '}
                <ExternalLink
                    id={'contactus-link-email-sales'}
                    variant={'subtitle1'}
                    color={'primary'}
                    href={'mailto:sales@airandheatdoneright.com'}
                    label={'sales@airandheatdoneright.com'}
                />
            </p>
            <p>
                Contact Customer Support:{' '}
                <ExternalLink
                    id={'contactus-link-email-cs'}
                    variant={'subtitle1'}
                    color={'primary'}
                    href={'mailto:cs@airandheatdoneright.com'}
                    label={'cs@airandheatdoneright.com'}
                />
            </p>
            <p>
                Call Us:{' '}
                <ExternalLink
                    id={'external-link-tel-sales'}
                    variant={'subtitle1'}
                    color={'primary'}
                    href={'tel:8333717706'}
                    label={'(833) 371-7706'}
                />
            </p>
        </>
    );
    return (
        <ContentOverlay
            open={open}
            onClose={() => onClose()}
            closeButtonOnTop={true}
            justifyContent={'flex-start'}
            helps={[
                {
                    headLine: 'Contact Us',
                    paragraph: contactUs,
                },
                {
                    headLine: 'What type of heating and cooling system do you currently have?',
                    paragraph: `This system type will have two pieces of equipment: one inside your home, usually in a attic,
                        basement, garage, closet or crawlspace AND one outside of your home. Don’t worry if it's not
                        exact, one our technicians will confirm the equipment selected is what your home needs prior to
                        any purchase or installation. These systems will have just one piece of equipment that provides
                        both heating and cooling outside of your home. The indoor unit is usually located in a closet,
                        attic, basement, or crawlspace.`,
                },
                {
                    headLine: 'Where is your indoor unit located?',
                    paragraph: `Furnaces use natural gas or propane to heat your home. These systems will typically have a metal
                        or plastic pipe/s coming out of the top or side of the unit leading outside to exhaust fumes.
                        Air Handlers and Electric Furnaces do not have gas or propane or exhaust pipes. They use heating
                        systems powered by electricity to heat your home.`,
                },
                {
                    headLine: 'What type of outdoor unit do you have?',
                    paragraph: `Electric Heat or Heat Pumps do not have gas or propane or exhaust pipes. They use heating
                        systems powered by electricity to heat your home. Gas Packaged units use natural gas or propane
                        to heat your home. These systems will have gas lines connected to the side of the unit.`,
                },
                {
                    headLine: 'What is the efficiency of your current furnace?',
                    paragraph: `Choose this if your indoor unit has a metal exhaust pipe coming out of it. Choose this if your
                        indoor unit has plastic exhaust a pipe/s coming out of it.`,
                },
            ]}
        />
    );
}

function handleOnBack(props: Props & FormikProps<FormValues>) {
    const values = props.values;
    props.onBack({
        homeSystem: values.homeSystem as HomeSystem,
        splitLocation: values.splitLocation as SplitSystemLocation,
        packageLocation: values.packageLocation as PackageSystemLocation,
        furnaceOrAirHandler: values.furnaceOrAirHandler as FurnaceOrAirHandler,
        heatPumpOrGasPack: values.heatPumpOrGasPack as HeatPumpOrGasPack,
        efficiency: values.efficiency as FurnaceEfficiency,
    });
}

export default withFormik<Props, FormValues>({
    mapPropsToValues: ({ criteria }) => ({
        homeSystem: criteria.homeSystem || '',
        splitLocation: criteria.splitLocation || '',
        packageLocation: criteria.packageLocation || '',
        furnaceOrAirHandler: criteria.furnaceOrAirHandler || '',
        heatPumpOrGasPack: criteria.heatPumpOrGasPack || '',
        efficiency: criteria.efficiency || '',
    }),
    handleSubmit: (values, { props }) => {
        props.onNext({
            homeSystem: values.homeSystem as HomeSystem,
            splitLocation: values.splitLocation as SplitSystemLocation,
            packageLocation: values.packageLocation as PackageSystemLocation,
            furnaceOrAirHandler: values.furnaceOrAirHandler as FurnaceOrAirHandler,
            heatPumpOrGasPack: values.heatPumpOrGasPack as HeatPumpOrGasPack,
            efficiency: values.efficiency as FurnaceEfficiency,
        });
    },
    validateOnMount: true,
    validationSchema: schemaAboutYourSystem,
})(WizardYourSystem);
