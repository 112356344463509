export interface ProductCriteria {
    squareFootage?: SquareFootage;
    homeStyle?: HomeStyle;
    homeSystem?: HomeSystem;
    furnaceOrAirHandler?: FurnaceOrAirHandler;
    efficiency?: FurnaceEfficiency | string;
    heatPumpOrGasPack?: HeatPumpOrGasPack;
    singleOrMultiple?: SingleOrMultiple;
    splitLocation?: SplitSystemLocation;
    packageLocation?: PackageSystemLocation;
    promotionCode?: string;
}

export enum SquareFootage {
    Square750 = '750',
    Square1000 = '1000',
    Square1250 = '1250',
    Square1500 = '1500',
    Square1750 = '1750',
    Square2000 = '2000',
    Square2500 = '2500',
    Square2600 = '2600',
}

export enum HomeStyle {
    Ranch = 'Ranch',
    TwoStoryOrMore = 'TwoStory Or More',
    TownhomeOrCondo = 'Townhome Or Condo',
}

export enum HomeSystem {
    Split = 'Split',
    Package = 'Package',
}

export enum SingleOrMultiple {
    Single = 'Single',
    Multiple = 'Multiple',
}

export enum PackageSystemLocation {
    Roof = 'Roof',
    Ground = 'Ground',
}

export enum SplitSystemLocation {
    Attic = 'Attic',
    Basement = 'Basement',
    Closet = 'Closet',
    Garage = 'Garage',
    CrawlSpace = 'Crawl Space',
}

export enum FurnaceOrAirHandler {
    Furnace = 'Furnace',
    AirHandler = 'Air Handler',
}

export enum HeatPumpOrGasPack {
    HeatPump = 'Heat Pump',
    GasPack = 'Gas Pack',
}

export enum FurnaceEfficiency {
    Percent80 = '80',
    Percent90 = '90',
}
