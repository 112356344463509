import { combineEpics } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, tap, ignoreElements, withLatestFrom } from 'rxjs/operators';

import { setupCheckOut as setupCheckOutService, mosaicInfo } from '../services/cartSevices';
import {
    getBrainTree,
    getBrainTreeError,
    getBrainTreeSuccess,
    getMosaic,
    getMosaicError,
    getMosaicSuccess,
    resetFinancial,
} from '../actions/financialActions';
import { RootState } from '../reducers';
import { Dependencies } from '../index';
import { AppRoute } from '../../models/route';
import { createCartSuccess, resetCart, updateCartSuccess } from '../actions/cartActions';
import { selectCart } from '../selectors/cart';
import { selectFinancial } from '../selectors/financial';

export const getBrianTree$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(getBrainTree),
        switchMap((action) =>
            setupCheckOutService(action.payload.cartId, {
                code: 'braintree',
            }).pipe(
                map((resp) => getBrainTreeSuccess(resp.data)),
                catchError((error) => {
                    alert.error('Unable to setup checkout process');
                    return of(getBrainTreeError(error));
                })
            )
        )
    );

export const getMosaic$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(getMosaic),
        switchMap((action) =>
            mosaicInfo(action.payload.cartId).pipe(
                map((resp) => getMosaicSuccess(resp.data)),
                catchError((error) => {
                    alert.error('Unable to get financial info');
                    return of(getMosaicError(error));
                })
            )
        )
    );

export const navigateToCreditCard$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(getBrainTreeSuccess),
        tap(() => {
            history.push(AppRoute.CreditCard);
        }),
        ignoreElements()
    );

export const navigateToMosaic$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(getMosaicSuccess),
        tap(() => {
            history.push(AppRoute.Mosaic);
        }),
        ignoreElements()
    );

export const cleanupDependentState$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(createCartSuccess, updateCartSuccess, resetCart),
        withLatestFrom(state$.pipe(map(selectCart)), state$.pipe(map(selectFinancial))),
        switchMap(([_, cart, financial]) => {
            if (!cart.cartId || cart.cartId !== financial.cartId) {
                return [resetFinancial()];
            }
            return [];
        })
    );

export default combineEpics(
    getBrianTree$,
    getMosaic$,
    navigateToCreditCard$,
    navigateToMosaic$,
    cleanupDependentState$
);
