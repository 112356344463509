import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Button';
import Hidden from '@material-ui/core/Hidden';
import ContentContainer from 'components/ContentContainer';
import { Paragraph } from '../Paragraph';
import { openChatWindow } from '../../store/services/acquireServices';
import Container from '@material-ui/core/Container';

export default function ContactUs() {
    return (
        <ContentContainer disableGrid background={'bg1'}>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer size={'small'}>
                    <Grid container spacing={3} alignItems={'center'}>
                        <Hidden xsDown>
                            <Grid item sm={1} />
                        </Hidden>
                        <Grid item xs={12} sm={7} md={8}>
                            <Paragraph size={'normal'} color={'secondary'}>
                                Unsure of what to do next or still have questions?
                            </Paragraph>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <Button
                                id={'contact-us-btn'}
                                color={'secondary'}
                                onClick={() => {
                                    openChatWindow();
                                }}
                            >
                                Chat with us now!
                            </Button>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}
