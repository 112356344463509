import React, { PropsWithChildren } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import WizardInput from './WizardInput';

const WizardInputButtonGroup = withStyles(
    (theme: Theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            width: `calc(100% + ${theme.spacing(1)})`,
            margin: theme.spacing(-0.5),
            backgroundColor: theme.palette.common.white,
        },
        grouped: {
            margin: theme.spacing(0.5),
            height: theme.spacing(4),
            padding: theme.spacing(1, 2),
            '&:first-child': {
                borderRadius: 1,
            },
            '&:not(:first-child)': {
                borderRadius: 1,
                marginLeft: theme.spacing(0.5),
                borderLeft: `1px solid ${theme.palette.divider}`,
                '&:hover': {
                    borderLeft: `1px solid ${theme.palette.action.hover}`,
                },
            },
        },
    }),
    { name: 'common' }
)(ToggleButtonGroup);

export interface Options {
    id: string;
    value: string;
    label: string;
}

interface Props {
    id: string;
    options: Options[];
    onChange: (id: string) => void;
    value?: string;
}

export default function WizardInputs(props: PropsWithChildren<Props>) {
    const { options, onChange, ...other } = props;

    return (
        <WizardInputButtonGroup exclusive onChange={(e, v) => onChange(v)} {...other}>
            {options.map((option) => (
                <WizardInput key={option.id} id={option.id} value={option.value}>
                    {option.label}
                </WizardInput>
            ))}
        </WizardInputButtonGroup>
    );
}
