import React, { Fragment } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';

function WhoWeAre() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
        };
    });
    const classes = useStyles();
    return (
        <SubPage
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'About Us'} />
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Mission Statement'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            To proactively transform the residential HVAC replacement experience using the best people,
                            technology, and analytics – for homeowners in the local markets we serve. We treat our
                            customers’ properties as if they were our own.
                        </Paragraph>
                    </Grid>

                    {/* About Us Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'About Us'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            AirandHeatDoneRight.com started out as a project of passion by several HVAC industry
                            veterans. The idea was simple - there had to be a simpler, more efficient way for homeowners
                            to shop for heating and air conditioning equipment.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            In the past, the typical process for homeowners involved finding an HVAC contractor, vetting
                            the contractor, price shopping contractors to get the best deal, researching equipment, and
                            finally making a decision. This process, while functional, requires a lot of resources, time
                            and effort on the part of the homeowner, often leading to frustrating experiences and time
                            wasted on finding the right contractor and equipment.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Our HVAC experts knew there was a better way, using technology, to make the experience of
                            shopping for HVAC much easier, and so AirandHeatDoneRight.com was created. The biggest
                            difference between AirandHeatDoneRight.com and the traditional approach is: we provide a
                            simple tool which will guide you through the process of identifying which HVAC setup you
                            have, recommending the most efficient HVAC system, connecting you to a qualified and
                            experienced contractor and completing the job. This means no more looking for contractors or
                            trying to figure out which system you have or looking through hundreds of options.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            AirandHeatDoneRight.com makes upgrading your HVAC system fast, easy, with one point of
                            contact for the entire process.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Our foundation is based on a simple process: you complete the HVAC guide to determine which
                            is the best system for you, we connect you to a contractor who validates the choice of
                            system, we schedule your installation. That’s it!
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Our network of over 2,000 contractors around the US means no matter where you are, we can
                            help.
                        </Paragraph>
                    </Grid>
                    {/* About Us End */}

                    {/* Service Area Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Service Area'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>We proudly serve the following markets:</Paragraph>
                    </Grid>
                    <Grid item xs={12}>
                        <ul>
                            <li>
                                <Paragraph>Greater Chicagoland Area</Paragraph>
                            </li>
                            <li>
                                <Paragraph>Greater Kansas City Metro Area</Paragraph>
                            </li>
                            <li>
                                <Paragraph>Greater St. Louis Metro Area</Paragraph>
                            </li>
                            <li>
                                <Paragraph>Greater Baltimore / Washington, D.C. Metro Area</Paragraph>
                            </li>
                        </ul>
                    </Grid>
                    {/* Service Area End */}

                    {/*  100% Satisfaction Pledge Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'100% Satisfaction Pledge'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Our pledge to you is that you will be 100% satisfied with the installation and operation of
                            your new HVAC system or we'll make it right!
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>We're able to make that pledge because;</Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            First we take the guesswork and hassle out of selecting and scheduling your new HVAC system!
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Next, we back that up with some of the best warranties, local HVAC contractors, and products
                            in the industry!
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            And finally, Homeowners really appreciate our simple online payment methods, knowing you pay
                            only when the work is done!
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Now let's get started selecting the right HVAC system for your home so you too can be our
                            next 100% Satisfied Homeowner, it's our Pledge to you.
                        </Paragraph>
                    </Grid>
                    {/*  100% Satisfaction Pledge End */}
                </Fragment>
            }
        />
    );
}

export default WhoWeAre;
