import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';
import WizardYourSystem from './WizardYourSystem';
import { resetProductCriteria, setProductCriteria } from '../../store/actions/productCriteriaActions';
import { AppRoute } from '../../models/route';
import { schemaAboutYouHome } from './wizard-schema';
import { searchProduct } from '../../store/actions/productSearchActions';
import { selectProductLoading } from '../../store/selectors/productSearch';

function AboutYourSystem() {
    const history = useHistory();
    const dispatch = useDispatch();
    const criteria = useSelector(selectProductCriteriaState);
    const loading = useSelector(selectProductLoading);
    if (!schemaAboutYouHome.isValidSync(criteria)) {
        return <Redirect to={AppRoute.AboutYourHome} />;
    }
    return (
        <WizardYourSystem
            loading={loading}
            criteria={criteria}
            onBack={(payload) => {
                dispatch(setProductCriteria(payload));
                history.push(AppRoute.AboutYourHome);
            }}
            onNext={(payload) => {
                dispatch(setProductCriteria(payload));
                dispatch(searchProduct());
            }}
            onExit={() => {
                dispatch(resetProductCriteria());
                history.push(AppRoute.Home);
            }}
        />
    );
}

export default AboutYourSystem;
