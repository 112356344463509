import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import LoadingInternal from '../icons/Loading';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

interface Props {
    backdrop?: boolean;
}
export default function Loading(props: Props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props.backdrop ? (
                <Backdrop open>
                    <LoadingInternal />
                </Backdrop>
            ) : (
                <LoadingInternal />
            )}
        </div>
    );
}
