import React, { useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import ContentContainer from 'components/ContentContainer';
import TextField from '../../components/FormikTextField';
import PhoneField from '../../components/FormikPhoneField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Divider from '@material-ui/core/Divider';
import WizardHeader from '../../components/WizardHeader';
import HeadlineParagraph from '../../components/HeadlineParagraph';
import WizardCriteriaFooter from '../../components/WizardCriteriaFooter';
import Checkbox from '../../components/FormikCheckBox';
import SelectInput from '../../components/common/SelectInput';
import { Formik, FormikValues } from 'formik';
import { Cart, PaymentMethod } from '../../models/cart';
import { Braintree, HostedField } from 'react-braintree-fields';
import * as yup from 'yup';
import { Region } from '../../models/country';
import { useAlert } from 'components/common/AlertProvider';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import Container from '@material-ui/core/Container';
import { Hidden } from '@material-ui/core';
import { validate as validatePhone } from 'validations/phoneNumber';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingBottom: 0,
    },
    input: {
        height: theme.spacing(4),
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(0, 1.2),
        borderRadius: '1px',
        '&:hover': {
            borderColor: theme.palette.text.primary,
        },
        '&.braintree-hosted-fields-focused': {
            borderColor: theme.palette.text.primary,
        },
    },
    checkbox: {
        '&.MuiCheckbox-root': {
            color: theme.palette.text.primary,
        },
        '&.Mui-checked': {
            color: theme.palette.text.primary,
        },
    },
}));

function CreditCard(props: Props) {
    const classes = useStyles();
    const theme = useTheme();
    const alert = useAlert();
    const { cart, brainTree, loading } = props;
    const regions = props?.regions?.map((region) => ({ id: region.region, display: region.display }));
    const billingAddress = {
        ...cart?.contactInformation,
        ...cart?.billingAddress,
        country: 'US',
    };
    const shippingAddress = {
        ...cart?.contactInformation,
        ...cart?.shippingAddress,
        country: 'US',
    };

    const [requestNonce, setRequestNonce] = useState<boolean>(false);
    const [brainTreeReady, setBrainTreeReady] = useState<boolean>(false);
    const [tokenizer, setTokenizeFunc] = useState<any>(null);

    let _billReg = regions ? regions[0] : null;
    let _shipReg = regions ? regions[0] : null;
    if (regions && cart.billingAddress && cart.billingAddress.region) {
        _billReg = regions.find((reg) => reg.id === cart?.billingAddress?.region) || null;
    }

    if (regions && cart.shippingAddress && cart.shippingAddress.region) {
        _shipReg = regions.find((reg) => reg.id === cart?.shippingAddress?.region) || null;
    }
    const [billingRegion, setBillingRegion] = useState(_billReg);
    const [shippingRegion, setShippingRegion] = useState(_shipReg);

    const getCart = (values: any) => {
        const _billingAddress = {
            ...values.billingAddress,
            region: billingRegion?.id,
            telephone: values.telephone?.replace(/\./g, ''),
        };
        const _shippingAddress = values.sameAddress
            ? _billingAddress
            : {
                  ...billingAddress,
                  ...values.shippingAddress,
                  region: shippingRegion?.id,
                  telephone: values.telephone?.replace(/\./g, ''),
              };
        return {
            ...cart,
            contactInformation: {
                ...cart.contactInformation,
                email: values.email,
                firstname: values.firstname,
                lastname: values.lastname,
            },
            email: values.email,
            shippingAddress: _shippingAddress,
            billingAddress: _billingAddress,
        };
    };

    const [validatingPhone, setValidatingPhone] = React.useState(false);
    const [showPhoneValidMessage, setShowPhoneValidMessage] = React.useState(false);
    const [isMounted, setIsMounted] = React.useState(false);
    const [checkedPhones, setCheckedPhones] = React.useState(new Array<{ phone: string; valid: boolean }>());
    React.useEffect(() => {
        setIsMounted(true);
    }, []);

    const validate = async (values: FormikValues) => {
        // validate phoneNumber
        const validatePhoneMsg = await validatePhone(values.telephone?.replace(/_|\./gi, ''), {
            isMounted: isMounted,
            setShowValidMessage: setShowPhoneValidMessage,
            setValidating: setValidatingPhone,
            setCheckedPhones: setCheckedPhones,
            checkedPhones: checkedPhones,
        });
        if (validatePhoneMsg) {
            return {
                telephone: validatePhoneMsg,
            };
        }
    };

    return (
        <Formik
            initialValues={{
                billingAddress: {
                    ...billingAddress,
                },
                shippingAddress: {
                    ...shippingAddress,
                },
                firstname: cart?.contactInformation?.firstname,
                lastname: cart?.contactInformation?.lastname,
                email: cart.email,
                telephone: cart?.shippingAddress?.telephone || cart?.contactInformation?.phone,
                sameAddress: cart?.billingAddress?.address1 === cart?.shippingAddress?.address1,
            }}
            onSubmit={async (values) => {
                setRequestNonce(true);
                const token = await tokenizer.ref().catch(() => {
                    window.scrollTo(0, 0);
                    alert.error('Please correct the credit card information.');
                });
                setRequestNonce(false);
                if (token && token.nonce) {
                    props.placeOrderFunction(getCart(values), {
                        code: 'braintree',
                        additionalData: {
                            payment_method_nonce: token.nonce,
                        },
                    });
                }
            }}
            validateOnMount={true}
            validate={validate}
            validationSchema={validationSchema}
            initialTouched={{
                telephone: true,
            }}
        >
            {({ submitForm, isValid, values }) => (
                <div>
                    <LoadingOverlay open={loading || requestNonce} />
                    <WizardHeader showBackButton={true} showRestartButton={false} onBack={() => props.onBack()} />
                    <Divider />
                    <ContentContainer disableGrid background={'bg2'}>
                        <Container maxWidth={'lg'} disableGutters>
                            <ContentContainer size={'medium'} className={classes.root}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={5}>
                                        <Grid item>
                                            <HeadlineParagraph
                                                label={'Schedule your home visit.'}
                                                headlineSize={'h1'}
                                                textSize={'small'}
                                            >
                                                You won't be charged or have a hold on your credit card. Let's get your
                                                pre-installation inspection scheduled.
                                            </HeadlineParagraph>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={7}>
                                        <Grid container spacing={4}>
                                            <Hidden xsDown>
                                                <Grid item sm={2} md={1} />
                                            </Hidden>
                                            <Grid item xs={12} sm={8} md={10}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography id={'card-name-label'}>
                                                                    Name on Card *
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id={'card-first-name'}
                                                                    name={'firstname'}
                                                                    placeholder={'First Name'}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id={'card-last-name'}
                                                                    name={'lastname'}
                                                                    placeholder={'Last Name'}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography id={'card-info-label'}>
                                                                    Credit Card Information *
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Braintree
                                                                    classname={brainTreeReady ? '' : 'disabled'}
                                                                    authorization={brainTree?.clientToken}
                                                                    onAuthorizationSuccess={() => {
                                                                        setBrainTreeReady(true);
                                                                    }}
                                                                    getTokenRef={(ref: any) => {
                                                                        setTokenizeFunc({ ref });
                                                                    }}
                                                                    styles={{
                                                                        input: {
                                                                            'font-size':
                                                                                theme.typography.body1.fontSize,
                                                                            'font-family': `${theme.typography.body1.fontFamily}, Roboto, Helvetica, Arial, Sans-serif`,
                                                                            'font-weight':
                                                                                theme.typography.body1.fontWeight,
                                                                            color: theme.palette.text.primary,
                                                                        },
                                                                        ':focus': {
                                                                            color: theme.palette.text.primary,
                                                                        },
                                                                        '.valid': {
                                                                            color: theme.palette.success.main,
                                                                        },
                                                                        '.invalid': {
                                                                            color: theme.palette.error.main,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={12}>
                                                                            <HostedField
                                                                                id={'card-number-text-field'}
                                                                                type="number"
                                                                                placeholder={
                                                                                    'Card Number: 0000 0000 0000 0000'
                                                                                }
                                                                                className={classes.input}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6} md={6}>
                                                                            <HostedField
                                                                                id={'card-exp-date-text-field'}
                                                                                type="expirationDate"
                                                                                placeholder={'Exp Date: 00/00'}
                                                                                className={classes.input}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6} md={6}>
                                                                            <HostedField
                                                                                id={'ccv-text-field'}
                                                                                type="cvv"
                                                                                placeholder={'CVV'}
                                                                                className={classes.input}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Braintree>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Hidden xsDown>
                                                <Grid item sm={2} md={1} />
                                            </Hidden>

                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>

                                            <Hidden xsDown>
                                                <Grid item sm={2} md={1} />
                                            </Hidden>
                                            <Grid item xs={12} sm={8} md={10}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography id={'billing-address-label'}>
                                                            Billing Address *
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id={'billing-first-name'}
                                                            name={'billingAddress.firstname'}
                                                            placeholder={'First Name'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id={'billing-last-name'}
                                                            name={'billingAddress.lastname'}
                                                            placeholder={'Last Name'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id={'billing-address'}
                                                            name={'billingAddress.address1'}
                                                            placeholder={'Street Address'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id={'billing-address2'}
                                                            name={'billingAddress.address2'}
                                                            placeholder={'Street Address 2'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id={'billing-city'}
                                                            name={'billingAddress.city'}
                                                            placeholder={'City'}
                                                            fullWidth
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <SelectInput
                                                            id={'billing-region'}
                                                            value={billingRegion}
                                                            options={regions}
                                                            onSelect={(region: any) => setBillingRegion(region)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <TextField
                                                            id={'billing-zipcode'}
                                                            name={'billingAddress.postcode'}
                                                            placeholder={'Zip Code'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Hidden xsDown>
                                                <Grid item sm={2} md={1} />
                                            </Hidden>

                                            <Hidden xsDown>
                                                <Grid item sm={2} md={1} />
                                            </Hidden>
                                            <Grid item xs={12} sm={8} md={10}>
                                                <Checkbox
                                                    id={'use-billing-for-shipping-checkbox-input'}
                                                    name={'sameAddress'}
                                                    label={'Use Billing Address for Shipping Address'}
                                                />
                                            </Grid>
                                            <Hidden xsDown>
                                                <Grid item sm={2} md={1} />
                                            </Hidden>

                                            {!values.sameAddress && (
                                                <>
                                                    <Hidden xsDown>
                                                        <Grid item sm={2} md={1} />
                                                    </Hidden>
                                                    <Grid item xs={12} sm={8} md={10}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography id={'shipping-address-label'}>
                                                                    Shipping Address *
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id={'shipping-first-name'}
                                                                    name={'shippingAddress.firstname'}
                                                                    placeholder={'First Name'}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id={'shipping-last-name'}
                                                                    name={'shippingAddress.lastname'}
                                                                    placeholder={'Last Name'}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id={'shipping-address'}
                                                                    name={'shippingAddress.address1'}
                                                                    placeholder={'Street Address'}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id={'shipping-address2'}
                                                                    name={'shippingAddress.address2'}
                                                                    placeholder={'Street Address 2'}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id={'shipping-city'}
                                                                    name={'shippingAddress.city'}
                                                                    placeholder={'City'}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <SelectInput
                                                                    id={'shipping-region'}
                                                                    value={shippingRegion}
                                                                    options={regions}
                                                                    onSelect={(region: any) =>
                                                                        setShippingRegion(region)
                                                                    }
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <TextField
                                                                    id={'shipping-zipcode'}
                                                                    placeholder={'Zip Code'}
                                                                    name={'shippingAddress.postcode'}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Hidden xsDown>
                                                        <Grid item sm={2} md={1} />
                                                    </Hidden>
                                                </>
                                            )}

                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>

                                            <Hidden xsDown>
                                                <Grid item sm={2} md={1} />
                                            </Hidden>
                                            <Grid item xs={12} sm={8} md={10}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id={'email-address'}
                                                            name={'email'}
                                                            label={'Email Address *'}
                                                            type={'email'}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <PhoneField
                                                            id={'phone-number'}
                                                            name={'telephone'}
                                                            label={'Phone Number *'}
                                                            fullWidth
                                                            placeholder={'000.000.0000'}
                                                            enableErrorDisplay={showPhoneValidMessage}
                                                            validating={validatingPhone}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Hidden xsDown>
                                                <Grid item sm={2} md={1} />
                                            </Hidden>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ContentContainer>
                        </Container>
                    </ContentContainer>
                    <WizardCriteriaFooter onNext={submitForm} disabled={!isValid || !tokenizer} />
                </div>
            )}
        </Formik>
    );
}

export default CreditCard;

interface Props {
    loading: boolean;
    cart: Cart;
    regions?: [Region];
    brainTree?: any;
    placeOrderFunction: (cart: Cart, paymentMethod: PaymentMethod) => void;
    onBack: () => void;
    onExit: () => void;
}

const requiredShippingAddressValidation = yup.object().shape({
    address1: yup.string().required('Required'),
    address2: yup.string(),
    city: yup.string().required('Required'),
    postcode: yup
        .string()
        .length(5, 'Only 5 Characters')
        .matches(/^[0-9]{5}$/, 'Invalid Zip Code Characters')
        .required('Required'),
});

const requiredAddressValidation = yup.object().shape({
    address1: yup.string().required('Required'),
    address2: yup.string(),
    city: yup.string().required('Required'),
    postcode: yup
        .string()
        .length(5, 'Only 5 Characters')
        .matches(/^[0-9]{5}$/, 'Invalid Zip Code Characters')
        .required('Required'),
});

const validationSchema = yup.object().shape({
    billingAddress: requiredAddressValidation,
    shippingAddress: yup.object().when('sameAddress', {
        is: false,
        then: requiredShippingAddressValidation,
    }),
    sameAddress: yup.boolean(),
    email: yup.string().email().required('Required'),
    firstname: yup.string().required('Required'),
    lastname: yup.string().required('Required'),
});
