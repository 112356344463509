import React, { PropsWithChildren } from 'react';
import Grid from '@material-ui/core/Grid';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';
import ContentContainer from './ContentContainer';
import Hidden from '@material-ui/core/Hidden';

interface Props {
    id?: string;
    label?: string;
    headlineSize?: 'h1' | 'h2' | 'h3';
    textSize?: 'normal' | 'small';
    color?: 'primary' | 'secondary';
    italic?: boolean;
}

export default function HeadlineParagraph(props: PropsWithChildren<Props>) {
    const { label, headlineSize = 'h1', color = 'primary', textSize = 'normal', italic = false, children } = props;
    return (
        <ContentContainer disableGrid>
            <Grid container direction={'column'} spacing={2}>
                {label && (
                    <Grid item>
                        <Heading color={color} variant={headlineSize} label={label} />
                    </Grid>
                )}
                <Grid item>
                    <Paragraph color={color} size={textSize} italic={italic}>
                        {children}
                    </Paragraph>
                </Grid>
                <Hidden lgUp>
                    <Grid item />
                </Hidden>
            </Grid>
        </ContentContainer>
    );
}
