import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import SubPageInteractive from 'components/subpage/SubPageInteractive';
import ProTipAndAdditionalLinks from 'components/subpage/ProTipAndAdditionalLinks';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/knowledges/kc_furnace_story_image.png';
import { getSubPageMenus } from './menu-util';
import VideoOverlay from '../../components/VideoOverlay';

function Furnaces() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(5),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            contentInteractive: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(2),
            },
        };
    });
    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('furnaces'),
    };
    const classes = useStyles();
    const [openVdo, setOpenVdo] = React.useState(false);
    const handleCloseVdo = () => {
        setOpenVdo(false);
    };
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Furnaces'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph align={'justify'}>
                            Many homeowners do not know how their central gas heating system (Furnace) works. They just
                            know it is supposed to keep them warm when the outdoor temperatures drop! When it comes time
                            to upgrade or replace your current home heating system, it may be helpful to understand your
                            system before investing in new equipment.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} container className={classes.contentInteractive}>
                        <SubPageInteractive
                            id={'sub-page-01'}
                            title={'Watch this video to learn how a furnace heats your home'}
                            buttonLabel={'Watch'}
                            onClick={() => {
                                setOpenVdo(true);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Furnaces'} />
                    </Grid>
                    <VideoOverlay
                        open={openVdo}
                        onClose={handleCloseVdo}
                        heading={'Furnaces'}
                        src={
                            'https://res.cloudinary.com/motili/video/upload/v1594975985/ecomm/video/Goodman_HowItWorks_Furnace_Clean_1080p.mp4'
                        }
                    />
                </Fragment>
            }
            content2={
                <ProTipAndAdditionalLinks
                    proTip={{
                        headLine: 'Pro Tip',
                        paragraph: (
                            <Fragment>
                                The efficiency ratings represent how efficient the furnace is based on the cost of
                                running the system. For example, a 90% rating indicates that 90 cents of every energy
                                dollar you spend actually goes towards warming your home. Older furnaces may be 70%
                                efficient, meaning 30 cents out every energy dollar you spend is lost.
                            </Fragment>
                        ),
                    }}
                    additionalLinks={{
                        headLine: 'Want to learn more?  Check out these links:',
                        links: [
                            {
                                text: 'Gas Furnace Features: A Comparison Guide',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/before-you-buy/gas-furnace-features-comparison-guide',
                            },
                            {
                                text: 'Heat Pump and Gas Furnace: What’s the Difference?',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/whats-the-difference-between-a-heat-pump-and-gas-furnace',
                            },
                            {
                                text: 'Gas Furnace Energy-Efficiency Ratings',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/before-you-buy/what-s-the-difference-between-gas-furnace-energy-efficiency-ratings',
                            },
                            {
                                text: 'What is Variable Speed HVAC Technology?',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/what-is-variable-speed-technology',
                            },
                            {
                                text: 'Goodman Customer Reviews',
                                url: 'https://www.goodmanmfg.com/resources/customer-reviews',
                            },
                        ],
                    }}
                />
            }
        />
    );
}

export default Furnaces;
