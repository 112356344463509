import React from 'react';
import moment from 'moment';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ContentContainer from '../../components/ContentContainer';
import WizardHeader from '../../components/WizardHeader';
import WizardCriteriaFooter from '../../components/WizardCriteriaFooter';
import HeadlineParagraph from '../../components/HeadlineParagraph';
import { HelpButton } from '../../components/HelpButton';
import { Formik, FormikValues } from 'formik';
import { ContactInformation } from '../../models/cart';
import FormikTextField from '../../components/FormikTextField';
import FormikDateField from '../../components/FormikDateField';
import { ThemeWithImages } from '../../themes/theme';
import ContentOverlay from '../../components/ContentOverlay';
import Container from '@material-ui/core/Container';
import { WizardForm } from '../../components/WizardSelection';
import WizardTips from './WizardTips';
import { schemaEmailNameCapture } from './wizard-schema';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import PhoneField from 'components/FormikPhoneField';
import { validate as validatePhone } from '../../validations/phoneNumber';
import { ExternalLink } from '../../components/Link';

const headline_no_product = 'Let’s Talk';
const headline_multiunit = 'Good News';
const headline_normal = 'Get Your Personalized Pricing';
const paragraph_no_product =
    'We aren’t able to complete your order on this site but one of our representatives will contact you to discuss the best options available to you. Please enter your information and we will contact you as soon as possible.';
const paragraph_multiunit =
    'Depending on how many units you are replacing, we might be able to offer you a volume discount. Let’s talk to get more details and we’ll provide pricing based on the number of units and your location. Complete the contact information and we’ll be in touch as soon as possible.';
const paragraph_normal =
    "On the next screen we'll reveal your personalized name brand heating and air system options with 'all-in' pricing including expert installation, 10-Year limited system warranty, and 1-Year installation labor warranty. All backed by our 100% Customer Satisfaction Pledge. Even if you are not ready to make a purchase today, you'll have our quote to compare.";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${theme.spacing(22)}px)`,
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(14),
        },
    },
    wizard: {
        minHeight: `calc(100vh - ${theme.spacing(28)}px)`,
        [theme.breakpoints.up('md')]: {
            minHeight: `calc(100vh - ${theme.spacing(22)}px)`,
        },
    },
    actions: {
        [theme.breakpoints.up('md')]: {
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
        },
    },
    helpFooter: {
        borderTop: '1px solid #CCC',
        borderBottom: '1px solid #CCC',
        backgroundColor: '#F5F5F5',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

interface FormValues {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    preferreddate: Date | null;
}

interface Props {
    loading: boolean;
    multiUnit: boolean;
    noProduct: boolean;
    customerInfo: ContactInformation;
    onBack: (data: ContactInformation) => void;
    onNext: (data: ContactInformation) => void;
    onExit: () => void;
}

function WizardEmailNameCapture(props: Props) {
    const [processing, setProcessing] = React.useState(false);
    const [openHelp, setOpenHelp] = React.useState(false);
    const [validatingPhone, setValidatingPhone] = React.useState(false);
    const [showPhoneValidMessage, setShowPhoneValidMessage] = React.useState(false);
    const [isMounted, setIsMounted] = React.useState(false);
    const [checkedPhones, setCheckedPhones] = React.useState(new Array<{ phone: string; valid: boolean }>());
    const classes = useStyles();
    const { loading, multiUnit, noProduct, customerInfo } = props;
    const { images } = useTheme<ThemeWithImages>();
    React.useEffect(() => {
        setIsMounted(true);
    }, []);

    const validate = async (values: FormikValues) => {
        // validate phoneNumber
        const validatePhoneMsg = await validatePhone(values.phone?.replace(/_|\./gi, ''), {
            isMounted: isMounted,
            setShowValidMessage: setShowPhoneValidMessage,
            setValidating: setValidatingPhone,
            setCheckedPhones: setCheckedPhones,
            checkedPhones: checkedPhones,
        });
        if (validatePhoneMsg) {
            return {
                phone: validatePhoneMsg,
            };
        }
    };
    const headline = multiUnit ? headline_multiunit : noProduct ? headline_no_product : headline_normal;
    const paragraph = multiUnit ? paragraph_multiunit : noProduct ? paragraph_no_product : paragraph_normal;
    const nextButtonLabel = multiUnit || noProduct ? 'Submit' : 'Show Me Pricing';
    return (
        <Formik
            initialValues={{
                firstname: customerInfo.firstname || '',
                lastname: customerInfo.lastname || '',
                email: customerInfo.email || '',
                phone: customerInfo.phone || '',
                preferreddate: customerInfo.preferredDate
                    ? moment(customerInfo.preferredDate, 'MM/DD/YY').toDate()
                    : null,
            }}
            onSubmit={(values) => {
                props.onNext({
                    firstname: values.firstname,
                    lastname: values.lastname,
                    email: values.email,
                    phone: values.phone,
                    preferredDate: moment(values.preferreddate).format('MM/DD/YY'),
                    remark: multiUnit ? 'multiUnit' : noProduct ? 'noProduct' : undefined,
                });
            }}
            validateOnMount={true}
            validate={validate}
            validationSchema={schemaEmailNameCapture}
            initialTouched={{
                phone: true,
            }}
        >
            {({ submitForm, isValid, values }) => (
                <div className={classes.root}>
                    <LoadingOverlay open={loading || processing} />
                    <WizardHeader showBackButton={false} />
                    <Divider />
                    <div className={classes.main}>
                        <ContentContainer disableGrid background={'bg3'}>
                            <Container maxWidth={'lg'} disableGutters>
                                <ContentContainer size={'wizard'} className={classes.wizard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={5}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12}>
                                                    <HeadlineParagraph id={'headline'} label={headline}>
                                                        <b>{paragraph} </b>
                                                    </HeadlineParagraph>
                                                </Grid>
                                                <Hidden smDown>
                                                    <Grid item xs={12}>
                                                        <HeadlineParagraph headlineSize={'h3'}>
                                                            <HelpButton
                                                                id={'help-button'}
                                                                onClick={() => {
                                                                    setOpenHelp(true);
                                                                }}
                                                            />
                                                        </HeadlineParagraph>
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={7}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12}>
                                                    <WizardForm pic={images.wizardP3Q1}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <FormikTextField
                                                                    id={'firstname'}
                                                                    name="firstname"
                                                                    label={'First Name *'}
                                                                    placeholder={'First Name'}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormikTextField
                                                                    id={'lastname'}
                                                                    name="lastname"
                                                                    label={'Last Name *'}
                                                                    placeholder={'Last Name'}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormikTextField
                                                                    id={'email'}
                                                                    name="email"
                                                                    label={'Email Address *'}
                                                                    type={'email'}
                                                                    placeholder={'Email Address'}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PhoneField
                                                                    id={'phone'}
                                                                    name={'phone'}
                                                                    label={'Phone Number *'}
                                                                    fullWidth
                                                                    placeholder={'000.000.0000'}
                                                                    enableErrorDisplay={showPhoneValidMessage}
                                                                    validating={validatingPhone}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormikDateField
                                                                    id={'preferreddate'}
                                                                    name="preferreddate"
                                                                    label={'Preferred System Replacement Date *'}
                                                                    placeholder={'MM/DD/YY'}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </WizardForm>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ContentContainer>
                            </Container>
                        </ContentContainer>
                        <Hidden mdUp>
                            <ContentContainer background={'bg2'} disableGrid className={classes.helpFooter}>
                                <ContentContainer>
                                    <HelpButton
                                        id={'help-button'}
                                        onClick={() => {
                                            setOpenHelp(true);
                                        }}
                                    />
                                </ContentContainer>
                            </ContentContainer>
                        </Hidden>
                    </div>
                    <div className={classes.actions}>
                        <WizardTips>
                            <b>
                                Did you know? AirAndHeatDoneRight.com only offers HVAC systems with a 4.6 ⭐ or better
                                consumer rating.{' '}
                            </b>
                        </WizardTips>
                        <WizardCriteriaFooter
                            onBack={() => handleOnBack(props, values)}
                            onNext={() => {
                                setProcessing(true);
                                return submitForm();
                            }}
                            disabled={!isValid || loading}
                            nextLabel={nextButtonLabel}
                        />
                    </div>

                    <HelpContent open={openHelp} onClose={() => setOpenHelp(false)} />
                </div>
            )}
        </Formik>
    );
}

interface HelpProps {
    open: boolean;
    onClose: () => void;
}

function HelpContent(props: HelpProps) {
    const { open, onClose } = props;
    const contactUs = (
        <>
            <p>
                Contact Sales:{' '}
                <ExternalLink
                    id={'contactus-link-email-sales'}
                    variant={'subtitle1'}
                    color={'primary'}
                    href={'mailto:sales@airandheatdoneright.com'}
                    label={'sales@airandheatdoneright.com'}
                />
            </p>
            <p>
                Contact Customer Support:{' '}
                <ExternalLink
                    id={'contactus-link-email-cs'}
                    variant={'subtitle1'}
                    color={'primary'}
                    href={'mailto:cs@airandheatdoneright.com'}
                    label={'cs@airandheatdoneright.com'}
                />
            </p>
            <p>
                Call Us:{' '}
                <ExternalLink
                    id={'external-link-tel-sales'}
                    variant={'subtitle1'}
                    color={'primary'}
                    href={'tel:8333717706'}
                    label={'(833) 371-7706'}
                />
            </p>
        </>
    );
    return (
        <ContentOverlay
            open={open}
            onClose={() => onClose()}
            closeButtonOnTop={true}
            justifyContent={'flex-start'}
            helps={[
                {
                    headLine: 'Contact Us',
                    paragraph: contactUs,
                },
                {
                    headLine: 'Why do we need this?',
                    paragraph: `Our system saves your personalized system recommendations so our HVAC specialist can answer questions specific to you and your situation.  Don't worry, we will never sell your contact information to third parties.`,
                },
            ]}
        />
    );
}

function handleOnBack(props: Props, values: FormValues) {
    props.onBack({
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
        preferredDate: moment(values.preferreddate).format('MM/DD/YY'),
    });
}

export default WizardEmailNameCapture;
