import React, { MouseEventHandler } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            height: theme.spacing(4),
            padding: theme.spacing(1, 2),
            minWidth: theme.spacing(10),
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.common.white,
            '&:hover': {
                border: `1px solid ${theme.palette.text.primary}`,
                backgroundColor: theme.palette.common.white,
            },
        },
    }),
    { name: 'common' }
);

interface Props {
    id?: string;
    onClick?: MouseEventHandler;
}

export function HelpButton(props: Props) {
    const classes = useStyles();
    return (
        <MuiButton
            id={props.id ? props.id : 'help-btn'}
            disableElevation
            className={classes.root}
            variant={'outlined'}
            {...props}
            onClick={props.onClick}
        >
            Help
        </MuiButton>
    );
}
