import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable } from 'rxjs';
import { ignoreElements, withLatestFrom, map, tap } from 'rxjs/operators';
import { RootState } from '../reducers';
import { Dependencies } from '../index';
import { selectProductCriteriaState } from '../selectors/productCriteria';
import { SingleOrMultiple } from '../../models/productCriteria';

import { nextHomeInfo } from '../actions/productCriteriaActions';
import { AppRoute } from '../../models/route';

export const processCriteria$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert, history }: Dependencies
) =>
    action$.pipe(
        ofType(nextHomeInfo),
        withLatestFrom(state$.pipe(map(selectProductCriteriaState))),
        tap(([_, criteria]) => {
            if (criteria.singleOrMultiple === SingleOrMultiple.Single) {
                history.push(AppRoute.AboutYourSystem);
            } else {
                history.push(AppRoute.EmailNameCapture);
            }
        }),
        ignoreElements()
    );
