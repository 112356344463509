import React from 'react';
import Container from '@material-ui/core/Container';
import ContentContainer from 'components/ContentContainer';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Heading } from 'components/Heading';
import WizardHeader from 'components/WizardHeader';
import { Paragraph } from 'components/Paragraph';
import ProductDetail from 'components/ProductDetail';
import CartSummary from 'components/CartSummary';
import { Order } from 'models/order';
import { getProductImage, ThemeWithImages } from 'themes/theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    footer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    nav: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    nextStepBox: {
        padding: theme.spacing(2),
    },
    homeAvatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    selectedText: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(2),
    },
    productDetail: {
        paddingBottom: theme.spacing(4),
    },
    divider: {
        paddingBottom: theme.spacing(4),
    },
    summary: {
        paddingBottom: theme.spacing(4),
    },
    content: {},
}));

interface Props {
    order: Order;
}

function Confirmation(props: Props) {
    const classes = useStyles();
    const theme = useTheme<ThemeWithImages>();
    const { images } = theme;
    const order: Order = props.order;
    const realTaxes = order && order.taxAmount && order.subtotal ? order.taxAmount / order.subtotal : 0;
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div>
            <WizardHeader showBackButton={false} showRestartButton={false} />
            <Divider />

            <ContentContainer disableGrid background={'bg3'}>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer className={classes.header}>
                        <Grid container>
                            <Grid item xs={12} md={8} container spacing={2}>
                                <Grid item xs={12}>
                                    <Heading variant={'h2'} label={"What's Next?"} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Paragraph>
                                        You've made an excellent choice and we look forward to working with you
                                        throughout your installation!
                                    </Paragraph>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Container>
            </ContentContainer>

            <ContentContainer disableGrid background={'bg2'}>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer>
                        <Grid container direction={'column'}>
                            <Grid container className={classes.selectedText} justify={isXs ? 'center' : undefined}>
                                <Heading variant={'h3'} color={'primary'} label={'Your Selection:'} />
                                <div id={'order_ref'} data-id={order.id} data-number={order.incrementId} />
                            </Grid>
                            {order.items?.map((item) => (
                                <Grid className={classes.productDetail} item xs={12}>
                                    <ProductDetail
                                        id={item.product.sku}
                                        image={getProductImage(item.product)}
                                        description={item.product.description}
                                        level={item.product.productAttributes.level}
                                        price={item.product.price + item.product.price * realTaxes}
                                        seer={item.product.productAttributes.seer}
                                        final
                                    />
                                </Grid>
                            ))}
                            <Grid className={classes.summary} item container>
                                <Grid item xs={12} sm={12} md={6} />
                                <Grid item xs={12} sm={12} md={6}>
                                    <CartSummary
                                        shipping={order.shippingInclTax}
                                        subTotal={order.subtotal}
                                        taxes={order.taxAmount}
                                    />
                                </Grid>
                            </Grid>
                            <Grid className={classes.divider} item>
                                <Divider />
                            </Grid>
                            <Grid className={classes.content} item container>
                                <Grid item xs={12}>
                                    <Heading variant={'h3'} color={'primary'} label={'Here’s what to expect that: '} />
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Grid container spacing={2} className={classes.nextStepBox}>
                                            <Grid item xs={12} sm={3} md={3} container justify={'center'}>
                                                <Avatar src={images.confirmationStep1} className={classes.homeAvatar} />
                                            </Grid>
                                            <Grid container item xs={12} sm={9} md={9} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Paragraph color={'primary'} align={isXs ? 'center' : 'left'}>
                                                        Schedule your Inspection Visit!{' '}
                                                    </Paragraph>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paragraph align={isXs ? 'center' : 'left'}>
                                                        A member of our team will contact you to confirm your
                                                        availability for the pre-installation inspection. We will send
                                                        out one of our technicians to confirm that the equipment
                                                        selected will work for your home.
                                                    </Paragraph>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Grid container spacing={2} className={classes.nextStepBox}>
                                            <Grid item xs={12} sm={3} md={3} container justify={'center'}>
                                                <Avatar src={images.confirmationStep2} className={classes.homeAvatar} />
                                            </Grid>
                                            <Grid container item xs={12} sm={9} md={9} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Paragraph color={'primary'} align={isXs ? 'center' : 'left'}>
                                                        Schedule Your Installation Day!
                                                    </Paragraph>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paragraph align={isXs ? 'center' : 'left'}>
                                                        You'll follow the same scheduling format to confirm your
                                                        installation date. On that day you'll have your brand new
                                                        Goodman heating and cooling system installed, packed with all
                                                        the latest HVAC efficiency and technology, assembled right here
                                                        in the U.S.
                                                    </Paragraph>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Grid container spacing={2} className={classes.nextStepBox}>
                                            <Grid item xs={12} sm={3} md={3} container justify={'center'}>
                                                <Avatar src={images.confirmationStep3} className={classes.homeAvatar} />
                                            </Grid>
                                            <Grid container item xs={12} sm={9} md={9} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Paragraph color={'primary'} align={isXs ? 'center' : 'left'}>
                                                        Payment will be processed once you have signed off on completion
                                                        of the project.
                                                    </Paragraph>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paragraph align={isXs ? 'center' : 'left'}>
                                                        Yes, we've really made it that simple and straight forward. Our
                                                        goal is for you to have a great experience that you share with
                                                        your friends, neighbors, and family members!
                                                    </Paragraph>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Container>
            </ContentContainer>
        </div>
    );
}

export default Confirmation;
