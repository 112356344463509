import React, { MouseEventHandler, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import CloseIcon from 'components/icons/Close';
import RestartIcon from 'components/icons/Restart';
import BackIcon from 'components/icons/Back';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            height: theme.spacing(4),
            width: theme.spacing(10),
            minWidth: theme.spacing(4),
            padding: 0,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.common.white,
            '&:hover': {
                border: `1px solid ${theme.palette.text.primary}`,
                backgroundColor: theme.palette.common.white,
            },
            [theme.breakpoints.down('xs')]: {
                width: theme.spacing(4),
            },
        },
        square: {
            width: theme.spacing(4),
        },
    }),
    { name: 'common' }
);

interface Props {
    id?: string;
    onClick?: MouseEventHandler;
    className?: string;
    square?: boolean;
}

function ButtonBase(props: PropsWithChildren<Props>) {
    const classes = useStyles();
    const { className, square, children, ...other } = props;
    return (
        <MuiButton
            disableElevation
            className={clsx(classes.root, { [classes.square]: square }, className)}
            variant={'outlined'}
            {...other}
        >
            {children}
        </MuiButton>
    );
}

export function BackButton(props: Props) {
    return (
        <ButtonBase {...props}>
            <BackIcon />
        </ButtonBase>
    );
}

export function CloseButton(props: Props) {
    return (
        <ButtonBase {...props}>
            <CloseIcon />
        </ButtonBase>
    );
}

export function RestartButton(props: Props) {
    return (
        <ButtonBase {...props}>
            <RestartIcon />
        </ButtonBase>
    );
}
