import React, { useContext } from 'react';
import { ProviderContext, SnackbarKey, SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const defaultTimeout = 5000;
export type AlertFunction = (message: string, timeout?: number) => void;
export interface Alert {
    info: AlertFunction;
    success: AlertFunction;
    warning: AlertFunction;
    error: AlertFunction;
}

const snackbarRef = React.createRef<ProviderContext>();
export const alert: Alert = {
    info(message, timeout = defaultTimeout) {
        snackbarRef.current?.enqueueSnackbar(message, { variant: 'info', autoHideDuration: timeout });
    },
    success(message, timeout = defaultTimeout) {
        snackbarRef.current?.enqueueSnackbar(message, { variant: 'success', autoHideDuration: timeout });
    },
    warning(message, timeout = defaultTimeout) {
        snackbarRef.current?.enqueueSnackbar(message, { variant: 'warning', autoHideDuration: timeout });
    },
    error(message, timeout = defaultTimeout) {
        snackbarRef.current?.enqueueSnackbar(message, { variant: 'error', autoHideDuration: timeout });
    },
};
const onClickDismiss = (key: SnackbarKey) => () => {
    snackbarRef.current?.closeSnackbar(key);
};

const AlertContext = React.createContext<Alert>(alert);

export const AlertProvider: React.FC = (props) => (
    <AlertContext.Provider value={alert}>
        <SnackbarProvider
            ref={snackbarRef}
            dense
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            autoHideDuration={5000}
            action={(key) => (
                <IconButton onClick={onClickDismiss(key)} aria-label="close" color={'primary'}>
                    <CloseIcon />
                </IconButton>
            )}
        >
            {props.children}
        </SnackbarProvider>
    </AlertContext.Provider>
);

export function useAlert() {
    return useContext(AlertContext);
}
