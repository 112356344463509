import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Button';
import Hidden from '@material-ui/core/Hidden';
import ContentContainer from 'components/ContentContainer';
import { AppRoute } from 'models/route';
import { useHistory } from 'react-router-dom';
import { Heading } from '../Heading';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {},
    }),
    { name: 'home-knowledge-center' }
);

function KnowledgeCenter(props: WithWidth) {
    const classes = useStyles();
    const history = useHistory();
    return (
        <ContentContainer disableGrid background={'bg1'} className={classes.root}>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer size={'large'}>
                    <Grid container spacing={2}>
                        <Hidden xsDown>
                            <Grid item sm={1} md={1} />
                        </Hidden>
                        <Grid item xs={12} sm={10} md={10}>
                            <Heading
                                variant={'h2'}
                                color={'secondary'}
                                label={'Looking for more information about HVAC before you invest?'}
                                align={'center'}
                            />
                        </Grid>
                        <Hidden xsDown>
                            <Grid item sm={1} md={1} />
                            <Grid item sm={1} md={1} />
                        </Hidden>
                        <Grid item xs={12} />
                        <Grid item xs={12} container justify={'center'}>
                            <Button
                                id={'kc-btn'}
                                color={'primary'}
                                onClick={() => {
                                    history.push(AppRoute.KnowledgeCenter);
                                }}
                            >
                                Check our Knowledge Center
                            </Button>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}

export default withWidth()(KnowledgeCenter);
