import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Dependencies } from '../index';
import { RootState } from '../reducers';
import { getCountryById, getCountryByIdSuccess, getCountryByIdError } from '../actions/countryActions';
import { getCountryById as getServiceCountry } from '../services/storeConfigSevices';

export const getCountry$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(getCountryById),
        switchMap((action) =>
            getServiceCountry(action.payload.id).pipe(
                map((resp) => getCountryByIdSuccess(resp.data)),
                catchError((error) => {
                    alert.error('Unable to load country data');
                    return of(getCountryByIdError(error));
                })
            )
        )
    );
