import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Button';
import { Logo } from 'components/Logo';
import { CloseButton } from 'components/WizardButton';
import ContentContainer from '../ContentContainer';
import { NavigationButton } from '../NavigationButton';
import { Link } from '../Link';

const useStyles = makeStyles((theme: Theme) => ({
    menuIcon: {
        border: `1px solid #CCCCCC`,
        width: '40px',
        height: '40px',
        '& svg': {
            marginTop: '-4px',
        },
    },
    primaryIcon: {
        color: theme.palette.text.primary,
    },
    secondaryIcon: {
        color: theme.palette.text.secondary,
    },
    menuItem: {
        justifyContent: 'center',
        textAlign: 'center',
        paddingBottom: theme.spacing(3.5),
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    header: {
        [theme.breakpoints.only('sm')]: {
            paddingBottom: theme.spacing(11.7),
        },
        [theme.breakpoints.only('xs')]: {
            paddingBottom: theme.spacing(6.7),
        },
    },
    paper: {
        height: '100%',
    },
}));

interface Props {
    color?: 'primary' | 'secondary';
}

interface Menu {
    text: string;
    route: string;
    isButton: boolean;
}

const menus: Menu[] = [
    {
        text: 'Chicagoland (IL)',
        route: `http://chicagoland.${window.location.host}`.toLowerCase(),
        isButton: false,
    },
    {
        text: 'Missouri (MO, KS)',
        route: `http://missouri.${window.location.host}`.toLowerCase(),
        isButton: false,
    },
    {
        text: 'Washington, dc area (VA, MD, DE)',
        route: `http://greaterdc.${window.location.host}`.toLowerCase(),
        isButton: false,
    },
];
export default function MenuDrawer(props: Props) {
    const classes = useStyles();
    const [state, setState] = React.useState(false);
    const { color } = props;

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };
    const list = () => (
        <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <ContentContainer size={'small'}>
                <Grid className={classes.header} item xs={12} container>
                    <Grid item xs={6} container justify={'flex-start'}>
                        <Logo id="menu-logo" color={'primary'} />
                    </Grid>
                    <Grid item xs={6} container justify={'flex-end'}>
                        <CloseButton id={'menu-close-ico'} onClick={toggleDrawer(false)} square />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        {menus.map((menu, index) =>
                            menu.isButton ? (
                                <ListItem key={`menu-${index}`} component={'div'} className={classes.menuItem}>
                                    <Button
                                        color={'primary'}
                                        onClick={() => {
                                            window.location.href = menu.route;
                                        }}
                                    >
                                        {menu.text}
                                    </Button>
                                </ListItem>
                            ) : (
                                <ListItem className={classes.menuItem} component={'div'} button key={`menu-${index}`}>
                                    <ListItemText
                                        secondary={
                                            <Link
                                                label={menu.text}
                                                color={'primary'}
                                                onClick={() => {
                                                    window.location.href = menu.route;
                                                }}
                                            />
                                        }
                                    />
                                </ListItem>
                            )
                        )}
                    </List>
                </Grid>
            </ContentContainer>
        </div>
    );

    return (
        <div>
            <NavigationButton color={color ? color : 'primary'} onClick={toggleDrawer(true)} />
            <Drawer classes={{ paper: classes.paper }} anchor={'top'} open={state} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer>
        </div>
    );
}
