import React, { Fragment } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/energystar/energy-star_story_image.png';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';

function EnergyStar() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
        };
    });
    const classes = useStyles();
    return (
        <SubPage
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Energy Star'} />
                    </Grid>
                    <Grid item xs={12} className={classes.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Energy Star'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Energy Star is a voluntary US Environmental Protection Agency program that provides guidance
                            to homeowners and businesses on the energy efficiency of the products that homeowners and
                            businesses purchase.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Goodman has worked with the ENERGY STAR program to denote a number of its high-efficiency
                            systems for furnaces, central air conditioners, and air-sourced heat pumps. Additional
                            information explaining why a more efficient unit can result in improved home comfort is
                            <Link href={'https://www.goodmanmfg.com/resources/energy-star'} target={'_blank'}>
                                {' located at.'}
                            </Link>
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            <Link
                                href={'https://www.goodmanmfg.com/resources/new-efficiency-standards'}
                                target={'_blank'}
                            >
                                {' Regional Energy Efficiency Standards.'}
                            </Link>
                        </Paragraph>
                    </Grid>
                </Fragment>
            }
        />
    );
}

export default EnergyStar;
