import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {},
        label: {
            marginBottom: theme.spacing(1),
            color: theme.palette.text.primary,
            '&.Mui-focused': {
                color: theme.palette.text.primary,
            },
            '&.Mui-error': {
                color: theme.palette.error.main,
            },
        },
        input: {
            borderRadius: '1px',
            height: theme.spacing(4),
            '& input': {
                padding: theme.spacing(1.2),
            },
            '&.MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: theme.palette.text.primary,
                },
                '& fieldset': {
                    border: `1px solid ${theme.palette.divider}`,
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.text.primary,
                    borderWidth: '1px',
                },
            },
        },
        helperText: {
            color: theme.palette.text.primary,
        },
    }),
    { name: 'common' }
);

interface Props {
    id: string;
    type?: string;
    label?: string;
    fullWidth?: boolean;
    helperText?: string;
    error?: boolean;
    format?: string;
    placeholder?: string;
    disablePast?: boolean;
    enableErrorDisplay?: boolean;
    value?: any;
    onChange: (value: any) => void;
}
export function DateField(props: Props) {
    const classes = useStyles();
    const {
        id,
        label,
        fullWidth,
        helperText,
        error,
        placeholder,
        format,
        enableErrorDisplay = false,
        value,
        onChange,
        ...other
    } = props;
    return (
        <FormControl
            id={`${id}-root`}
            fullWidth={fullWidth}
            className={classes.root}
            error={enableErrorDisplay && error}
        >
            {label && (
                <FormLabel id={`${id}-label`} htmlFor={id} className={classes.label}>
                    {label}
                </FormLabel>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    id={id}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    format={format}
                    disableToolbar
                    value={value}
                    emptyLabel={''}
                    {...other}
                    onChange={(v) => {
                        onChange(v);
                    }}
                />
            </MuiPickersUtilsProvider>
            {enableErrorDisplay && (
                <FormHelperText id={`${id}-helper`} className={classes.helperText}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}
