import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {},
    }),
    { name: 'common' }
);

interface Props {
    id?: string;
    label: string;
    variant?: 'h1' | 'h2' | 'h3';
    color?: 'primary' | 'secondary';
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

export function Heading(props: Props) {
    const classes = useStyles();
    const { label, variant = 'h1', color = 'primary', ...other } = props;
    return (
        <Typography
            variant={variant}
            color={color === 'primary' ? 'textPrimary' : 'textSecondary'}
            className={classes.root}
            {...other}
        >
            {label}
        </Typography>
    );
}
