import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { MosaicModal } from '@solarmosaic/cross-domain-components';
import ContentContainer from 'components/ContentContainer';
import TextField from '../../components/FormikTextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Divider from '@material-ui/core/Divider';
import WizardHeader from '../../components/WizardHeader';
import HeadlineParagraph from '../../components/HeadlineParagraph';
import WizardCriteriaFooter from '../../components/WizardCriteriaFooter';
import Checkbox from '../../components/FormikCheckBox';
import SelectInput from '../../components/common/SelectInput';
import { Formik, FormikValues } from 'formik';
import { Cart, PaymentMethod } from '../../models/cart';
import * as yup from 'yup';
import { Mosaic } from '../../models/financial';
import { Region } from '../../models/country';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import PhoneField from '../../components/FormikPhoneField';
import Container from '@material-ui/core/Container';
import { Hidden } from '@material-ui/core';
import { validate as validatePhone } from 'validations/phoneNumber';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingBottom: 0,
    },
    nav: {
        height: '80px',
    },
    checkbox: {
        '&.MuiCheckbox-root': {
            color: theme.palette.text.primary,
        },
        '&.Mui-checked': {
            color: theme.palette.text.primary,
        },
    },
    MosaicTableBlock: {
        width: '100%',
        left: 0,
        padding: 0,
        top: '100px',
        display: 'table',
        position: 'absolute',
    },
    MosaicTableCellBlock: {
        padding: 0,
        verticalAlign: 'unset',
        display: 'table-cell',
        margin: 0,

        ' & div': {
            height: '800px',
            ' & div': {
                left: '18%',
            },
        },
    },
}));

function MosaicForm(props: Props) {
    const classes = useStyles();
    const { loading } = props;
    const regions = props?.regions?.map((region) => ({ id: region.region, display: region.display }));
    const cart: Cart = {
        ...props.cart,
    };
    const billingAddress = {
        ...cart?.contactInformation,
        ...cart?.billingAddress,
        country: 'US',
    };
    const shippingAddress = {
        ...cart?.contactInformation,
        ...cart?.shippingAddress,
        country: 'US',
    };
    let _billReg = regions ? regions[0] : null;
    let _shipReg = regions ? regions[0] : null;
    if (regions && cart.billingAddress && cart.billingAddress.region) {
        _billReg = regions.find((reg) => reg.id === cart?.billingAddress?.region) || null;
    }

    if (regions && cart.shippingAddress && cart.shippingAddress.region) {
        _shipReg = regions.find((reg) => reg.id === cart?.shippingAddress?.region) || null;
    }
    const [billingRegion, setBillingRegion] = useState(_billReg);
    const [shippingRegion, setShippingRegion] = useState(_shipReg);
    const [mosaicModal, setMosaicModal] = useState(false);
    const [isOrdering, setOrdering] = useState(false);

    const toggleModal = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        if (mosaicModal) {
            props.reloadMosaic();
        }
        mosaicModal ? setMosaicModal(false) : setMosaicModal(true);
    };
    let timer: any;

    const getCart = (values: any) => {
        const _shippingAddress = {
            ...values.shippingAddress,
            region: shippingRegion?.id,
            telephone: values.telephone?.replace(/\./g, ''),
        };
        const _billingAddress = values.sameAddress
            ? _shippingAddress
            : {
                  ...values.billingAddress,
                  region: billingRegion?.id,
                  telephone: values.telephone?.replace(/\./g, ''),
              };
        return {
            ...cart,
            contactInformation: {
                ...cart.contactInformation,
                email: values.email,
                firstname: values.firstname,
                lastname: values.lastname,
            },
            email: values.email,
            shippingAddress: _shippingAddress,
            billingAddress: _billingAddress,
        };
    };

    const [validatingPhone, setValidatingPhone] = React.useState(false);
    const [showPhoneValidMessage, setShowPhoneValidMessage] = React.useState(false);
    const [isMounted, setIsMounted] = React.useState(false);
    const [checkedPhones, setCheckedPhones] = React.useState(new Array<{ phone: string; valid: boolean }>());
    React.useEffect(() => {
        setIsMounted(true);
    }, []);

    const validate = async (values: FormikValues) => {
        // validate phoneNumber
        const validatePhoneMsg = await validatePhone(values.telephone?.replace(/_|\./gi, ''), {
            isMounted: isMounted,
            setShowValidMessage: setShowPhoneValidMessage,
            setValidating: setValidatingPhone,
            setCheckedPhones: setCheckedPhones,
            checkedPhones: checkedPhones,
        });
        if (validatePhoneMsg) {
            return {
                telephone: validatePhoneMsg,
            };
        }
    };
    return (
        <Formik
            initialValues={{
                billingAddress: {
                    ...billingAddress,
                },
                shippingAddress: {
                    ...shippingAddress,
                },
                firstname: cart?.contactInformation?.firstname,
                lastname: cart?.contactInformation?.lastname,
                email: cart.email,
                telephone: cart?.shippingAddress?.telephone || cart?.contactInformation?.phone,
                sameAddress: cart?.billingAddress?.address1 === cart?.shippingAddress?.address1,
            }}
            onSubmit={(values) => {
                props.updateCart(getCart(values));
                window.scrollTo(0, 0);
                setMosaicModal(true);
            }}
            validateOnMount={true}
            validate={validate}
            validationSchema={validationSchema}
            initialTouched={{
                telephone: true,
            }}
        >
            {({ submitForm, isValid, values }) => (
                <div>
                    {((!loading && props.mosaic?.merchantId && cart?.shippingAddress?.postcode && mosaicModal) ||
                        isOrdering) && (
                        <div className={classes.MosaicTableBlock}>
                            <div className={classes.MosaicTableCellBlock}>
                                <MosaicModal
                                    toggleModal={toggleModal}
                                    onComplete={() => {
                                        setOrdering(true);
                                        if (timer) {
                                            clearTimeout(timer);
                                        }
                                        timer = setTimeout(() => {
                                            if (mosaicModal) {
                                                setMosaicModal(false);
                                                setOrdering(false);
                                            }
                                        }, 120000);
                                        props.placeOrderFunction(getCart(values), {
                                            code: 'checkmo',
                                        });
                                    }}
                                    url={props?.mosaic?.baseUrl + '/modal'}
                                    loanAmount={cart?.prices?.grandTotal}
                                    merchantId={props?.mosaic?.merchantId}
                                    requestDateTime={props?.mosaic?.requestDateTime}
                                    requestHash={props?.mosaic?.requestHash}
                                    transactionId={props?.mosaic?.transactionId}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <LoadingOverlay open={loading} />
                        <WizardHeader showBackButton onBack={() => props.onBack()} showRestartButton={false} />
                        <Divider />
                        <ContentContainer disableGrid background={'bg2'}>
                            <Container maxWidth={'lg'} disableGutters>
                                <ContentContainer size={'medium'} className={classes.root}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={5}>
                                            <Grid item>
                                                <HeadlineParagraph
                                                    label={'Get Pre-Approved to Pay Over Time'}
                                                    headlineSize={'h1'}
                                                    textSize={'small'}
                                                >
                                                    Get pre-approved today to start scheduling your pre-installation
                                                    inspection. It’s quick and easy, with low monthly payments, and no
                                                    impact to your credit score.
                                                </HeadlineParagraph>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={7}>
                                            <Grid container spacing={4}>
                                                <Hidden xsDown>
                                                    <Grid item sm={2} md={1} />
                                                </Hidden>
                                                <Grid item xs={12} sm={8} md={10}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <Typography id={'shipping-address-label'}>
                                                                        Project Address *
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        id={'shipping-first-name'}
                                                                        name={'shippingAddress.firstname'}
                                                                        placeholder={'First Name'}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        id={'shipping-last-name'}
                                                                        name={'shippingAddress.lastname'}
                                                                        placeholder={'Last Name'}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        id={'shipping-address'}
                                                                        name={'shippingAddress.address1'}
                                                                        placeholder={'Street Address'}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        id={'shipping-address2'}
                                                                        name={'shippingAddress.address2'}
                                                                        placeholder={'Street Address 2'}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        id={'shipping-city'}
                                                                        name={'shippingAddress.city'}
                                                                        placeholder={'City'}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <SelectInput
                                                                        id={'shipping-region'}
                                                                        value={shippingRegion}
                                                                        options={regions}
                                                                        onSelect={(region: any) =>
                                                                            setShippingRegion(region)
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <TextField
                                                                        id={'shipping-zipcode'}
                                                                        name={'shippingAddress.postcode'}
                                                                        placeholder={'Zip Code'}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Hidden xsDown>
                                                    <Grid item sm={2} md={1} />
                                                </Hidden>

                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>

                                                <Hidden xsDown>
                                                    <Grid item sm={2} md={1} />
                                                </Hidden>

                                                <Grid item xs={12} sm={8} md={10}>
                                                    <Checkbox
                                                        id={'use-shipping-for-billing-checkbox'}
                                                        name={'sameAddress'}
                                                        label={'Use Project Address for Billing Address'}
                                                    />
                                                </Grid>
                                                <Hidden xsDown>
                                                    <Grid item sm={2} md={1} />
                                                </Hidden>

                                                {!values.sameAddress && (
                                                    <>
                                                        <Hidden xsDown>
                                                            <Grid item sm={2} md={1} />
                                                        </Hidden>
                                                        <Grid item xs={12} sm={8} md={10}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Grid container spacing={1}>
                                                                        <Grid item xs={12}>
                                                                            <Typography id={'billing-address-label'}>
                                                                                Billing Address *
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                id={'billing-first-name'}
                                                                                name={'billingAddress.firstname'}
                                                                                placeholder={'First Name'}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                id={'billing-last-name'}
                                                                                name={'billingAddress.lastname'}
                                                                                placeholder={'Last Name'}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                id={'billing-address'}
                                                                                name={'billingAddress.address1'}
                                                                                placeholder={'Street Address'}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                id={'billing-address2'}
                                                                                name={'billingAddress.address2'}
                                                                                placeholder={'Street Address 2'}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                id={'billing-city'}
                                                                                name={'billingAddress.city'}
                                                                                placeholder={'City'}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={12} sm={6} md={6}>
                                                                            <SelectInput
                                                                                id={'billing-region'}
                                                                                value={billingRegion}
                                                                                options={regions}
                                                                                onSelect={(region: any) =>
                                                                                    setBillingRegion(region)
                                                                                }
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6} md={6}>
                                                                            <TextField
                                                                                id={'billing-zipcode'}
                                                                                placeholder={'Zip Code'}
                                                                                name={'billingAddress.postcode'}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>

                                                <Hidden xsDown>
                                                    <Grid item sm={2} md={1} />
                                                </Hidden>

                                                <Grid item xs={12} sm={8} md={10}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                id={'email-address'}
                                                                name={'email'}
                                                                label={'Email  Address *'}
                                                                type={'email'}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <PhoneField
                                                                id={'phone-number'}
                                                                name={'telephone'}
                                                                label={'Phone Number *'}
                                                                fullWidth
                                                                placeholder={'000.000.0000'}
                                                                enableErrorDisplay={showPhoneValidMessage}
                                                                validating={validatingPhone}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Hidden xsDown>
                                                    <Grid item sm={2} md={1} />
                                                </Hidden>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ContentContainer>
                            </Container>
                        </ContentContainer>
                        <WizardCriteriaFooter onNext={submitForm} disabled={!isValid} />
                    </div>
                </div>
            )}
        </Formik>
    );
}

export default MosaicForm;

interface Props {
    loading: boolean;
    cart: Cart;
    mosaic?: Mosaic;
    regions?: [Region];
    updateCart: (cart: Cart) => void;
    placeOrderFunction: (cart: Cart, paymentMethod: PaymentMethod) => void;
    onBack: () => void;
    onExit: () => void;
    reloadMosaic: () => void;
}

const requiredBillingAddressValidation = yup.object().shape({
    address1: yup.string().required('Required'),
    address2: yup.string(),
    city: yup.string().required('Required'),
    postcode: yup
        .string()
        .length(5, 'Only 5 Characters')
        .matches(/^[0-9]{5}$/, 'Invalid Zip Code Characters')
        .required('Required'),
});

const requiredAddressValidation = yup.object().shape({
    address1: yup.string().required('Required'),
    address2: yup.string(),
    city: yup.string().required('Required'),
    postcode: yup
        .string()
        .length(5, 'Only 5 Characters')
        .matches(/^[0-9]{5}$/, 'Invalid Zip Code Characters')
        .required('Required'),
});

const addressValidation = yup.object().shape({
    address1: yup.string(),
    address2: yup.string(),
    city: yup.string(),
    region: yup.string(),
    company: yup.string(),
    postcode: yup.string(),
    telephone: yup.string(),
});

const validationSchema = yup.object().shape({
    shippingAddress: requiredAddressValidation,
    billingAddress: yup.object().when('sameAddress', {
        is: true,
        then: addressValidation,
        otherwise: requiredBillingAddressValidation,
    }),
    sameAddress: yup.boolean(),
    email: yup.string().email().required('Required'),
    firstname: yup.string().required('Required'),
    lastname: yup.string().required('Required'),
});
