import { AppConfig } from './models/appConfig';
import theme1, { images as images1 } from './themes/theme1';
import theme2, { images as images2 } from './themes/theme2';
import theme3, { images as images3 } from './themes/theme3';

const storeCodeUrlMapping: { [key: string]: string } = {
    // LOCAL
    'localhost:3000': 'NONE',
    'chicagoland.localhost:3000': 'chicago_airandheat_com',
    'greaterdc.localhost:3000': 'greaterdc_airandheat_com',
    'missouri.localhost:3000': 'missouri_airandheat_com',
    // DEV
    'motilidev.com': 'NONE',
    'besthvac.motilidev.com': 'besthvac_motilidev_com',
    'chicagoland.motilidev.com': 'chicago_airandheat_com',
    'greaterdc.motilidev.com': 'greaterdc_airandheat_com',
    'missouri.motilidev.com': 'missouri_airandheat_com',
    // QA
    'motilitest.com': 'NONE',
    'chicagoland.motilitest.com': 'chicago_airandheat_com',
    'greaterdc.motilitest.com': 'greaterdc_airandheat_com',
    'missouri.motilitest.com': 'missouri_airandheat_com',
    // STAGE
    'motilistage.com': 'NONE',
    'chicagoland.motilistage.com': 'chicago_airandheat_com',
    'greaterdc.motilistage.com': 'greaterdc_airandheat_com',
    'missouri.motilistage.com': 'missouri_airandheat_com',
    // PROD
    'airandheatdoneright.com': 'NONE',
    'chicagoland.airandheatdoneright.com': 'chicago_airandheat_com',
    'greaterdc.airandheatdoneright.com': 'greaterdc_airandheat_com',
    'missouri.airandheatdoneright.com': 'missouri_airandheat_com',
};

const storeCode = getStoreCode();
const theme = getTheme(storeCode);
const googleAnalyticId = getGoogleAnalyticId(storeCode);
const googleTagManagerId = getGoogleTagManagerId(storeCode);
const hotjarId = getHotjarId(storeCode);
const hotjarSv = getHotjarSv(storeCode);

const config: AppConfig = {
    appName: process.env.REACT_APP_NAME,
    appVersion: process.env.REACT_APP_VERSION,
    apiHost: process.env.REACT_APP_API_HOST,
    httpTimeout: 60000,
    storeCode,
    googleAnalyticId: googleAnalyticId,
    googleTagManagerId: googleTagManagerId,
    hotjarId: hotjarId,
    hotjarSv: hotjarSv,
    ...theme,
};

function getStoreCode() {
    const url = `${window.location.host}`.toLowerCase();
    const storeCodeFromEnv = process.env.REACT_APP_STORE_CODE;
    const storeCodeFromUrl = url.replace(/\W/g, '_');
    const storeCodeFromMapping = storeCodeUrlMapping[url];
    return storeCodeFromEnv || storeCodeFromMapping || storeCodeFromUrl;
}

function getTheme(storeCode: string) {
    switch (storeCode) {
        case 'chicago_airandheat_com':
            return { theme: theme1, images: images1 };
        case 'greaterdc_airandheat_com':
            return { theme: theme2, images: images2 };
        case 'missouri_airandheat_com':
            return { theme: theme3, images: images3 };
        default:
            return { theme: theme1, images: images1 };
    }
}

function getGoogleAnalyticId(storeCode: string) {
    switch (storeCode) {
        case 'chicago_airandheat_com':
            return process.env.REACT_APP_GOOGLE_ANALYTIC_ID_CHICAGO;
        case 'greaterdc_airandheat_com':
            return process.env.REACT_APP_GOOGLE_ANALYTIC_ID_GREATERDC;
        case 'missouri_airandheat_com':
            return process.env.REACT_APP_GOOGLE_ANALYTIC_ID_MISSOURI;
        default:
            return '';
    }
}

function getGoogleTagManagerId(storeCode: string) {
    switch (storeCode) {
        case 'chicago_airandheat_com':
            return process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID_CHICAGO;
        case 'greaterdc_airandheat_com':
            return process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID_GREATERDC;
        case 'missouri_airandheat_com':
            return process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID_MISSOURI;
        default:
            return '';
    }
}

function getHotjarId(storeCode: string) {
    switch (storeCode) {
        case 'chicago_airandheat_com':
            return process.env.REACT_APP_HOTJAR_ID_CHICAGO;
        case 'greaterdc_airandheat_com':
            return process.env.REACT_APP_HOTJAR_ID_GREATERDC;
        case 'missouri_airandheat_com':
            return process.env.REACT_APP_HOTJAR_ID_MISSOURI;
        case 'NONE':
            return process.env.REACT_APP_HOTJAR_ID_AIRANDHEATDONERIGHT;
        default:
            return undefined;
    }
}

function getHotjarSv(storeCode: string) {
    switch (storeCode) {
        case 'chicago_airandheat_com':
            return process.env.REACT_APP_HOTJAR_SV_CHICAGO;
        case 'greaterdc_airandheat_com':
            return process.env.REACT_APP_HOTJAR_SV_GREATERDC;
        case 'missouri_airandheat_com':
            return process.env.REACT_APP_HOTJAR_SV_MISSOURI;
        case 'NONE':
            return process.env.REACT_APP_HOTJAR_SV_AIRANDHEATDONERIGHT;
        default:
            return undefined;
    }
}

export default config;
