import React from 'react';
import CreditCard from './CreditCard';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { resetProductCriteria } from '../../store/actions/productCriteriaActions';
import { AppRoute } from '../../models/route';
import { Cart, PaymentMethod } from '../../models/cart';
import { selectCart, selectCartProcessing } from '../../store/selectors/cart';
import { selectBrainTree } from '../../store/selectors/financial';
import { placeOrder, setCart } from '../../store/actions/cartActions';
import { selectRegions } from '../../store/selectors/country';
import { selectOrderLoading } from '../../store/selectors/order';
import { checkoutGACreditCard } from '../../store/actions/gaEcommerceActions';

function CreditCardContainer() {
    const history = useHistory();
    const dispatch = useDispatch();
    const cart: Cart = useSelector(selectCart);
    const brainTree = useSelector(selectBrainTree);
    const loading = useSelector(selectCartProcessing);
    const orderLoading = useSelector(selectOrderLoading);
    const regions = useSelector(selectRegions);

    if (!brainTree?.clientToken && !orderLoading) {
        return <Redirect to={AppRoute.ChoosePaymentOption} />;
    }

    return (
        <CreditCard
            loading={loading || orderLoading}
            cart={cart}
            regions={regions}
            brainTree={brainTree}
            onBack={() => {
                history.push(AppRoute.ChoosePaymentOption);
            }}
            placeOrderFunction={(cart: Cart, paymentMethod: PaymentMethod) => {
                if (!!cart && cart.cartId && !!paymentMethod) {
                    dispatch(
                        placeOrder({
                            cart: {
                                cartId: cart.cartId,
                                email: cart.email,
                                billingAddress: cart.billingAddress,
                                shippingAddress: cart.shippingAddress,
                            },
                            paymentMethod: paymentMethod,
                        })
                    );
                }
                dispatch(checkoutGACreditCard());
            }}
            onExit={() => {
                dispatch(resetProductCriteria());
                history.push(AppRoute.Home);
            }}
        />
    );
}

export default CreditCardContainer;
