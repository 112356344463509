import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { combineEpics } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';

import {
    getStore,
    getStoreSuccess,
    getStoreError,
    getContentBlock,
    getContentBlockSuccess,
    getContentBlockError,
} from '../actions/storeActions';
import { getCmsBlocks, getStore as getStoreService } from '../services/storeConfigSevices';
import { Dependencies } from '../index';
import { RootState } from '../reducers';
import { selectPromotionCodeBlockId } from '../selectors/storeSelector';

export const getStore$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(getStore),
        switchMap(() =>
            getStoreService().pipe(
                switchMap((resp) => [getStoreSuccess(resp.data), getContentBlock()]),
                catchError((error) => {
                    alert.error('Unable to load store data');
                    return of(getStoreError(error));
                })
            )
        )
    );

export const getContentBlock$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(getContentBlock),
        withLatestFrom(state$.pipe(map(selectPromotionCodeBlockId))),
        switchMap(([_, identifier]) =>
            getCmsBlocks(identifier).pipe(
                map((resp) => getContentBlockSuccess(resp.data[0])),
                catchError((error) => {
                    alert.error('Unable to load content pages');
                    return of(getContentBlockError(error));
                })
            )
        )
    );

export default combineEpics(getStore$, getContentBlock$);
