import React from 'react';
import { FormikProps, withFormik } from 'formik';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ContentContainer from '../../components/ContentContainer';
import WizardHeader from '../../components/WizardHeader';
import WizardCriteriaFooter from '../../components/WizardCriteriaFooter';
import WizardSelection from '../../components/WizardSelection';
import HeadlineParagraph from '../../components/HeadlineParagraph';
import { HelpButton } from '../../components/HelpButton';
import { HomeStyle, SingleOrMultiple, SquareFootage, ProductCriteria } from '../../models/productCriteria';
import { Options } from '../../components/WizardInputs';
import ContentOverlay from '../../components/ContentOverlay';
import { ThemeWithImages } from '../../themes/theme';
import WizardTips from './WizardTips';
import { schemaAboutYouHome } from './wizard-schema';
import { ExternalLink } from '../../components/Link';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${theme.spacing(22)}px)`,
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(14),
        },
    },
    wizard: {
        minHeight: `calc(100vh - ${theme.spacing(28)}px)`,
        [theme.breakpoints.up('md')]: {
            minHeight: `calc(100vh - ${theme.spacing(22)}px)`,
        },
    },
    actions: {
        [theme.breakpoints.up('md')]: {
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
        },
    },
    helpFooter: {
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

interface FormValues {
    singleOrMultiple: string;
    squareFootage: string;
    homeStyle: string;
}

interface Props {
    criteria: ProductCriteria;
    onNext: (data: ProductCriteria) => void;
    onExit: () => void;
}

const HvacSystemOptions: Options[] = [
    { id: 'hvac-system-opt1', value: SingleOrMultiple.Single, label: 'One system' },
    { id: 'hvac-system-opt2', value: SingleOrMultiple.Multiple, label: 'Multiple systems' },
];
const SquareFeetOptions: Options[] = [
    { id: 'square-feet-opt1', value: SquareFootage.Square750, label: '<750 sq ft' },
    { id: 'square-feet-opt2', value: SquareFootage.Square1000, label: '751-1000 sq ft' },
    { id: 'square-feet-opt3', value: SquareFootage.Square1250, label: '1001-1250 sq ft' },
    { id: 'square-feet-opt4', value: SquareFootage.Square1500, label: '1251-1500 sq ft' },
    { id: 'square-feet-opt5', value: SquareFootage.Square1750, label: '1501-1750 sq ft' },
    { id: 'square-feet-opt6', value: SquareFootage.Square2000, label: '1751 – 2000 sq ft' },
    { id: 'square-feet-opt7', value: SquareFootage.Square2500, label: '2001-2500 sq ft' },
    { id: 'square-feet-opt8', value: SquareFootage.Square2600, label: '2500+ sq ft' },
];
const HomeStyleOptions: Options[] = [
    { id: 'home-style-opt1', value: HomeStyle.Ranch, label: 'Ranch' },
    { id: 'home-style-opt2', value: HomeStyle.TwoStoryOrMore, label: '2+ Story or Split Level' },
    { id: 'home-style-opt3', value: HomeStyle.TownhomeOrCondo, label: 'Townhome or Condo' },
];

function WizardHomeInfo(props: Props & FormikProps<FormValues>) {
    const [openHelp, setOpenHelp] = React.useState(false);
    const classes = useStyles();
    const { isValid, submitForm } = props;
    const { images } = useTheme<ThemeWithImages>();
    return (
        <div className={classes.root}>
            <WizardHeader showBackButton={false} showRestartButton={false} />
            <Divider />
            <div className={classes.main}>
                <ContentContainer disableGrid background={'bg3'}>
                    <Container maxWidth={'lg'} disableGutters>
                        <ContentContainer size={'wizard'} className={classes.wizard}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={5}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <HeadlineParagraph label={'Welcome'}>
                                                By answering a few brief questions about your home's heating and air
                                                conditioning system, we'll create a personalized quote for your new
                                                Goodman brand (Made in the USA) Energy Star-rated equipment, and expert
                                                installation that includes our 100% Homeowner Satisfaction Pledge and
                                                1-Year Full Labor Warranty.
                                            </HeadlineParagraph>
                                        </Grid>
                                        <Hidden smDown>
                                            <Grid item xs={12}>
                                                <HeadlineParagraph headlineSize={'h3'}>
                                                    <HelpButton
                                                        onClick={() => {
                                                            setOpenHelp(true);
                                                        }}
                                                    />
                                                </HeadlineParagraph>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <WizardSelection
                                                id={'question-hvac-system'}
                                                name="singleOrMultiple"
                                                pic={images.wizardP1Q1}
                                                label={
                                                    'Some homes have more than one complete HVAC system, I need to replace: '
                                                }
                                                options={HvacSystemOptions}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <WizardSelection
                                                id={'question-square-footage'}
                                                name="squareFootage"
                                                pic={images.wizardP1Q2}
                                                label={'How many total square feet is your home?'}
                                                options={SquareFeetOptions}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <WizardSelection
                                                id={'question-home-style'}
                                                name="homeStyle"
                                                pic={images.wizardP1Q3}
                                                label={'What style of home do you have?'}
                                                options={HomeStyleOptions}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ContentContainer>
                    </Container>
                </ContentContainer>
                <Hidden mdUp>
                    <ContentContainer background={'bg2'} disableGrid className={classes.helpFooter}>
                        <ContentContainer>
                            <HelpButton
                                onClick={() => {
                                    setOpenHelp(true);
                                }}
                            />
                        </ContentContainer>
                    </ContentContainer>
                </Hidden>
            </div>
            <div className={classes.actions}>
                <WizardTips>
                    Did you know your new system will be protected by our 1-Year Full Labor Warranty!
                </WizardTips>
                <WizardCriteriaFooter onNext={submitForm} disabled={!isValid} />
            </div>
            <HelpContent open={openHelp} onClose={() => setOpenHelp(false)} />
        </div>
    );
}

interface HelpProps {
    open: boolean;
    onClose: () => void;
}

function HelpContent(props: HelpProps) {
    const { open, onClose } = props;
    const contactUs = (
        <>
            <p>
                Contact Sales:{' '}
                <ExternalLink
                    id={'contactus-link-email-sales'}
                    variant={'subtitle1'}
                    color={'primary'}
                    href={'mailto:sales@airandheatdoneright.com'}
                    label={'sales@airandheatdoneright.com'}
                />
            </p>
            <p>
                Contact Customer Support:{' '}
                <ExternalLink
                    id={'contactus-link-email-cs'}
                    variant={'subtitle1'}
                    color={'primary'}
                    href={'mailto:cs@airandheatdoneright.com'}
                    label={'cs@airandheatdoneright.com'}
                />
            </p>
            <p>
                Call Us:{' '}
                <ExternalLink
                    id={'external-link-tel-sales'}
                    variant={'subtitle1'}
                    color={'primary'}
                    href={'tel:8333717706'}
                    label={'(833) 371-7706'}
                />
            </p>
        </>
    );
    return (
        <ContentOverlay
            open={open}
            onClose={() => onClose()}
            closeButtonOnTop={true}
            justifyContent={'flex-start'}
            helps={[
                {
                    headLine: 'Contact Us',
                    paragraph: contactUs,
                },
                {
                    headLine: 'Some homes have more than one complete HVAC system, I need to replace:',
                    paragraph:
                        'One system will only have (1) outdoor unit. More than one system will have (2) or more units\n' +
                        '                        outside.',
                },
                {
                    headLine: 'How many total square feet is your home?',
                    paragraph:
                        "The square footage of your home's heated and cooled space is the starting point to size the\n" +
                        "                        right HVAC equipment. And don't worry, one of our HVAC technicians verifies the right equipment\n" +
                        "                        will be installed in your home ahead of any purchase or installation. We're here to help if you\n" +
                        '                        have questions!',
                },
                {
                    headLine: 'What style of home do you have?',
                    paragraph:
                        ' Any one story home would be considered a ranch. Homes with more than one level, including\n' +
                        '                        finished walkout basements. Buildings where you share at least one wall with your neighbor.',
                },
            ]}
        />
    );
}

export default withFormik<Props, FormValues>({
    mapPropsToValues: ({ criteria }) => ({
        singleOrMultiple: criteria.singleOrMultiple || '',
        squareFootage: criteria.squareFootage || '',
        homeStyle: criteria.homeStyle || '',
    }),
    handleSubmit: (values, { props }) => {
        props.onNext({
            singleOrMultiple: values.singleOrMultiple as SingleOrMultiple,
            squareFootage: values.squareFootage as SquareFootage,
            homeStyle: values.homeStyle as HomeStyle,
        });
    },
    validateOnMount: true,
    validationSchema: schemaAboutYouHome,
})(WizardHomeInfo);
