import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ContentContainer from './ContentContainer';
import { Paragraph } from './Paragraph';
import { Heading } from './Heading';

interface Props {
    subTotal?: number;
    shipping?: number;
    taxes?: number;
    isEstimatedTaxes?: boolean;
}

export default function CartSummary(props: Props) {
    const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    const subTotal = props.subTotal || 0;
    const shipping = props.shipping || 0;
    const taxes = props.taxes || 0;
    const total = subTotal + shipping + taxes;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <ContentContainer disableGrid background={'bg2'}>
            <Grid container direction={'column'} spacing={2}>
                <Grid item xs={12} container>
                    <Grid item xs={6}>
                        <Paragraph>Subtotal</Paragraph>
                    </Grid>
                    <Grid item xs={6} container justify={'flex-end'} id={'summary-subtotal'}>
                        <Heading variant={'h3'} label={currencyFormat.format(subTotal)} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={6}>
                        <Paragraph>Shipping</Paragraph>
                    </Grid>
                    <Grid item xs={6} container justify={'flex-end'} id={'summary-shipping'}>
                        <Heading variant={'h3'} label={shipping > 0 ? currencyFormat.format(shipping) : 'free'} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={6}>
                        <Paragraph>{props.isEstimatedTaxes ? 'Estimated Taxes' : 'Taxes'}</Paragraph>
                    </Grid>
                    <Grid item xs={6} container justify={'flex-end'} id={'summary-taxes'}>
                        <Heading
                            variant={'h3'}
                            label={currencyFormat.format(taxes) + (props.isEstimatedTaxes ? '*' : '')}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} container spacing={isXs ? 1 : undefined}>
                    <Grid item xs={12} sm={6} container justify={isXs ? 'center' : undefined}>
                        <Paragraph>Total</Paragraph>
                    </Grid>
                    <Grid item xs={12} sm={6} container justify={isXs ? 'center' : 'flex-end'} id={'summary-total'}>
                        <Heading
                            variant={'h2'}
                            label={currencyFormat.format(total) + (props.isEstimatedTaxes ? '*' : '')}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </ContentContainer>
    );
}
