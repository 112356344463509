import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import LogoBlack from 'components/icons/LogoBlack';
import LogoWhite from 'components/icons/LogoWhite';
import { AppRoute } from '../models/route';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            backgroundColor: 'transparent',
            '&>svg': {
                flex: 1,
            },
        },
    }),
    { name: 'common' }
);

interface Props {
    id?: string;
    color?: 'primary' | 'secondary';
}

export function Logo(props: Props) {
    const classes = useStyles();
    const { color = 'primary', ...other } = props;
    return (
        <Avatar alt="Logo" className={classes.root} {...other}>
            {color === 'primary' ? <LogoBlack /> : <LogoWhite />}
        </Avatar>
    );
}

export function LogoWithLink(props: Props) {
    const { id, ...other } = props;
    return (
        <Link id={id} component={RouterLink} to={AppRoute.Home}>
            <Logo id={`${id}-logo`} {...other} />
        </Link>
    );
}
