import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { ExternalLink } from 'components/Link';
import { LogoWithLink } from '../Logo';
import MenuDrawer from './MenuDrawer';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            width: '100%',
            height: theme.spacing(8),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    }),
    { name: 'nav' }
);

interface Props {
    color?: 'primary' | 'secondary';
}

export default function Navigator(props: Props) {
    const classes = useStyles();
    const { color = 'primary' } = props;
    return (
        <nav className={classes.root}>
            <Grid container spacing={3} alignItems={'center'}>
                <Grid item xs={6} sm={2} md={1}>
                    <LogoWithLink id={'nav-home'} color={color} />
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={6} sm={10} container alignItems={'center'} justify={'flex-end'}>
                        <MenuDrawer color={color} />
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item md={10}>
                        <Grid container spacing={4} alignItems={'center'}>
                            <Grid item>
                                <ExternalLink
                                    id={'nav-link-chicago'}
                                    onClick={() => {
                                        window.location.href = `http://chicagoland.${window.location.host}`.toLowerCase();
                                    }}
                                    label={'CHICAGOLAND (IL)'}
                                    color={color}
                                />
                            </Grid>
                            <Grid item>
                                <ExternalLink
                                    id={'nav-link-missouri'}
                                    onClick={() => {
                                        window.location.href = `http://missouri.${window.location.host}`.toLowerCase();
                                    }}
                                    label={'MISSOURI (MO, KS)'}
                                    color={color}
                                />
                            </Grid>
                            <Grid item>
                                <ExternalLink
                                    id={'nav-link-washington'}
                                    onClick={() => {
                                        window.location.href = `http://greaterdc.${window.location.host}`.toLowerCase();
                                    }}
                                    label={'WASHINGTON, DC AREA (VA, MD, DE)'}
                                    color={color}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Hidden>
            </Grid>
        </nav>
    );
}
