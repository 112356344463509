import { action, payload } from 'ts-action';
import { Cart, PaymentMethod } from '../../models/cart';

export const setCart = action('CART::SET', payload<Cart>());

export const createCart = action('CART::CREATE');
export const saveForLater = action('CART::SAVE_FOR_LATER', payload<Cart>());
export const createCartSuccess = action('CART::CREATE_SUCCESS', payload<Cart>());
export const createCartError = action('CART::CREATE_ERROR', payload<Error>());

export const placeOrder = action('CART::PLACE_ORDER', payload<{ cart: Cart; paymentMethod?: PaymentMethod }>());
export const placeOrderSuccess = action('CART::PLACE_ORDER_SUCCESS', payload<any>());
export const placeOrderError = action('CART::PLACE_ORDER_ERROR', payload<Error>());

export const updateCart = action('CART::UPDATE_CART', payload<{ cart: Cart }>());
export const updateCartSuccess = action('CART::UPDATE_CART_SUCCESS', payload<any>());
export const updateCartError = action('CART::UPDATE_CART_ERROR', payload<Error>());
export const resetCart = action('CART::RESET');
