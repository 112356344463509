import React from 'react';
import { useField } from 'formik';
import { TextField } from './TextField';

interface Props {
    id?: string;
    fullWidth?: boolean;
    name: string;
    label?: string;
    type?: string;
    placeholder?: string;
}

export default function FormikTextField(props: Props) {
    const { id, placeholder, fullWidth = true, name, label, ...other } = props;
    const [field, meta] = useField(props);
    return (
        <TextField
            id={id ? id : `${name}-input`}
            label={label}
            fullWidth={fullWidth}
            placeholder={placeholder}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched ? meta.error : ''}
            {...other}
            {...field}
        />
    );
}
