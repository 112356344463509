import { Observable, of } from 'rxjs';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootState } from '../reducers';

import { Dependencies } from '../index';
import {
    getProductAttribute,
    getProductAttributeError,
    getProductAttributeSuccess,
} from '../actions/productAttributeActions';
import { getProductAttribute as getAttributeService } from '../services/productSevices';

export const getProductAttribute$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert }: Dependencies
) =>
    action$.pipe(
        ofType(getProductAttribute),
        switchMap((action) =>
            getAttributeService(action.payload.id).pipe(
                map((resp) => getProductAttributeSuccess({ id: action.payload.id, value: resp.data })),
                catchError((error) => {
                    alert.error(`Unable to get product attribute: ${action.payload.id}`);
                    return of(getProductAttributeError(error));
                })
            )
        )
    );
