import React, { MouseEventHandler } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import HamburgerWhite from './icons/HamburgerWhite';
import HamburgerBlack from './icons/HamburgerBlack';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            height: theme.spacing(4),
            width: theme.spacing(4),
            minWidth: theme.spacing(4),
            padding: 0,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: 'transparent',
            '&:hover': {
                border: `1px solid ${theme.palette.text.primary}`,
                backgroundColor: 'transparent',
            },
        },
    }),
    { name: 'common' }
);

interface Props {
    id?: string;
    onClick?: MouseEventHandler;
    color: 'primary' | 'secondary';
}

export function NavigationButton(props: Props) {
    const classes = useStyles();
    const { color, ...other } = props;
    return (
        <MuiButton disableElevation className={classes.root} variant={'outlined'} {...other}>
            {color === 'primary' ? <HamburgerBlack /> : <HamburgerWhite />}
        </MuiButton>
    );
}
