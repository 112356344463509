export enum AppRoute {
    Home = '/home',
    Financing = '/financing',
    KnowledgeCenter = '/knowledge-center/how-hvac-works',
    AirConditioners = '/knowledge-center/air-conditioners',
    AirHandlers = '/knowledge-center/air-handlers',
    Furnaces = '/knowledge-center/furnaces',
    HeatPumps = '/knowledge-center/heat-pumps',
    HowHvacWorks = '/knowledge-center/how-hvac-works',
    PackagedUnits = '/knowledge-center/packaged-units',
    WhatIsOurProcess = '/knowledge-center/what-is-our-process',
    WhoWeAre = '/who-we-are',
    ContactUs = '/contact-us',
    Warranty = '/warranty',
    EnergyStar = '/energy-star',
    MadeInUsa = '/made-in-usa',
    Pricing = '/pricing',
    SecurityAndPrivacyPolicy = '/security-privacy-policy',
    TermsAndConditions = '/terms-conditions',
    AboutYourHome = '/wizard/about-your-home',
    AboutYourSystem = '/wizard/about-your-system',
    EmailNameCapture = '/wizard/email-name-capture',
    EquipmentOptions = '/confirmation/equipment-options',
    ChoosePaymentOption = '/confirmation/choose-payment-options',
    CreditCard = '/payment/credit-card',
    Confirmation = '/payment/confirmation',
    Mosaic = '/payment/mosaic',
    Thankyou = '/thankyou',
}
