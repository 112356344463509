import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ChoosePaymentOption from './ChoosePaymentOption';
import { Cart } from '../../models/cart';
import { selectCart } from '../../store/selectors/cart';
import { AppRoute } from '../../models/route';
import { getBrainTree, getMosaic } from '../../store/actions/financialActions';
import { selectLoading } from '../../store/selectors/financial';

function ChoosePaymentOptionContainer() {
    const cart: Cart = useSelector(selectCart);
    const dispatch = useDispatch();
    const loading = useSelector(selectLoading);
    if (!cart || !cart.cartId || !cart.items || !cart.prices) {
        return <Redirect to={AppRoute.EquipmentOptions} />;
    }
    const { cartId, items, prices } = cart;
    return (
        <ChoosePaymentOption
            loading={loading}
            cartItem={items[0]}
            cartPrices={prices}
            onSelectCreditCard={() => {
                dispatch(getBrainTree({ cartId }));
            }}
            onSelectFinancing={() => {
                dispatch(getMosaic({ cartId }));
            }}
        />
    );
}

export default ChoosePaymentOptionContainer;
