import React from 'react';
import { useField } from 'formik';
import { DateField } from './DateField';

interface Props {
    id?: string;
    fullWidth?: boolean;
    name: string;
    label?: string;
    type?: string;
    placeholder?: string;
}

export default function FormikDateField(props: Props) {
    const { id, placeholder, fullWidth = true, name, label, ...other } = props;
    const [field, meta, { setValue }] = useField(props);
    return (
        <DateField
            id={id ? id : `${name}-input`}
            label={label}
            fullWidth={fullWidth}
            placeholder={placeholder}
            disablePast
            format="MM/dd/yy"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched ? meta.error : ''}
            {...other}
            {...field}
            onChange={(v) => setValue(v || null)}
        />
    );
}
