import { action, payload } from 'ts-action';
import { BrainTree, Mosaic } from '../../models/financial';

export const getMosaic = action('MOSAIC::GET', payload<{ cartId: string }>());
export const getMosaicSuccess = action('MOSAIC::GET_SUCCESS', payload<Mosaic>());
export const getMosaicError = action('MOSAIC::GET_ERROR', payload<Error>());

export const getBrainTree = action('BRAIN_TREE::GET', payload<{ cartId: string }>());
export const getBrainTreeSuccess = action('BRAIN_TREE::GET_SUCCESS', payload<BrainTree>());
export const getBrainTreeError = action('BRAIN_TREE::GET_ERROR', payload<Error>());
export const resetFinancial = action('RESET_FINANCIAL::DELETE');
