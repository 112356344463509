import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Button } from 'components/Button';
import ContentContainer from 'components/ContentContainer';
import { useHistory } from 'react-router-dom';
import { AppRoute } from 'models/route';
import { Paragraph } from '../Paragraph';
import Loading from '../common/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { selectContent, selectContentLoading, selectPromotionCodeBlockId } from '../../store/selectors/storeSelector';
import Container from '@material-ui/core/Container';
import { setPromotionCode } from '../../store/actions/productCriteriaActions';

const PromotionContent: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(selectContentLoading);
    const contentBlock = useSelector(selectContent);
    const promotionCode = useSelector(selectPromotionCodeBlockId);
    const classes = useStyles();
    return (
        <ContentContainer disableGrid background={'bg1'}>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer size={'small'}>
                    {loading && <Loading />}
                    {contentBlock?.content && (
                        <Grid container spacing={3} alignItems={'center'}>
                            <Hidden xsDown>
                                <Grid item sm={1} />
                            </Hidden>
                            <Grid item xs={12} sm={7} md={8} className={classes.content}>
                                <Paragraph size={'normal'} color={'secondary'}>
                                    <SanitizeHTML html={contentBlock.content} />
                                </Paragraph>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <Button
                                    id={'sub-content-get-started-btn'}
                                    color={'secondary'}
                                    onClick={() => {
                                        dispatch(setPromotionCode({ promotionCode: promotionCode }));
                                        history.push(AppRoute.AboutYourHome);
                                    }}
                                >
                                    Let's Get Started
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
};

interface SanitizeProps {
    html: string;
    options?: any;
}

const sanitize = (dirty: string, options: any) => ({
    __html: sanitizeHtml(dirty, options),
});

const useStyles = makeStyles({
    content: {
        '& p': {
            margin: 0,
        },
    },
});

const SanitizeHTML: React.FC<SanitizeProps> = ({ html, options }) => {
    const classes = useStyles();
    return <span dangerouslySetInnerHTML={sanitize(html, options)} className={classes.content} />;
};

export default PromotionContent;
