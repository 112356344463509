import React from 'react';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ContentContainer from 'components/ContentContainer';
import Navigator from 'components/Navigator';
import HeadLine from 'components/home/HeadLine';
import ContactUs from 'components/home/ContactUs';
import Footer from 'components/Footer';
import HowThisWork from 'components/home/HowThisWork';
import Financing from 'components/home/Financing';
import KnowledgeCenter from 'components/home/KnowledgeCenter';
import { ThemeWithImages } from '../themes/theme';
import PromotionContent from '../components/home/PromotionContent';

const useStyles = makeStyles(
    (theme: ThemeWithImages) => ({
        root: {},
        marquee: {
            height: '533px',
            backgroundImage: `linear-gradient(rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.5)), url(${theme.images.homeMarqueeImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        },
    }),
    { name: 'home-layout' }
);

export function Home(props: WithWidth) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.marquee}>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer>
                        <Navigator color={'secondary'} />
                        <ContentContainer size={'medium'} disableGrid>
                            <HeadLine />
                        </ContentContainer>
                    </ContentContainer>
                </Container>
            </div>
            <PromotionContent />
            <HowThisWork />
            <Financing />
            <KnowledgeCenter />
            <ContactUs />
            <Footer />
        </div>
    );
}

export default withWidth()(Home);
