import React from 'react';
import { useField } from 'formik';
import WizardInputs, { Options } from './WizardInputs';

interface Props {
    id?: string;
    name: string;
    options: Options[];
}

export default function FormikWizardInput(props: Props) {
    const { id, name, ...other } = props;
    const [, meta, { setValue }] = useField(props);
    return (
        <WizardInputs id={id || `${name}-inputs`} value={meta.value} onChange={(v) => setValue(v || '')} {...other} />
    );
}
