import * as Yup from 'yup';
import { StringSchema } from 'yup';
import {
    FurnaceEfficiency,
    FurnaceOrAirHandler,
    HeatPumpOrGasPack,
    HomeStyle,
    HomeSystem,
    PackageSystemLocation,
    SingleOrMultiple,
    SplitSystemLocation,
    SquareFootage,
} from '../../models/productCriteria';

export const schemaAboutYouHome = Yup.object({
    singleOrMultiple: Yup.string()
        .required('Hvac system is required to continue')
        .oneOf([SingleOrMultiple.Single, SingleOrMultiple.Multiple]),
    squareFootage: Yup.string().required('Please enter square footage').oneOf(Object.values(SquareFootage)),
    homeStyle: Yup.string()
        .required('A home style is required to continue')
        .oneOf([HomeStyle.Ranch, HomeStyle.TownhomeOrCondo, HomeStyle.TwoStoryOrMore]),
});

export const schemaAboutYourSystem = Yup.object({
    homeSystem: Yup.string()
        .required('Select a System before continuing')
        .oneOf([HomeSystem.Package, HomeSystem.Split]),
    splitLocation: Yup.string().when('homeSystem', (homeSystem: string, schema: StringSchema) => {
        if (homeSystem === HomeSystem.Split) {
            return schema
                .required('Select a System location before continuing 2')
                .oneOf(Object.values(SplitSystemLocation));
        }
        return schema.notRequired();
    }),
    packageLocation: Yup.string().when('homeSystem', (homeSystem: string, schema: StringSchema) => {
        if (homeSystem === HomeSystem.Package) {
            return schema
                .required('Select a System location before continuing 2')
                .oneOf(Object.values(PackageSystemLocation));
        }
        return schema.notRequired();
    }),
    furnaceOrAirHandler: Yup.string().when('homeSystem', (homeSystem: string, schema: StringSchema) => {
        if (homeSystem === HomeSystem.Split) {
            return schema
                .required('You must choose either Furnace or Air Handler')
                .oneOf([FurnaceOrAirHandler.Furnace, FurnaceOrAirHandler.AirHandler]);
        }
        return schema.notRequired();
    }),
    efficiency: Yup.string().when(
        ['homeSystem', 'furnaceOrAirHandler'],
        (homeSystem: string, furnaceOrAirHandler: string, schema: StringSchema) => {
            if (homeSystem === HomeSystem.Split && furnaceOrAirHandler === FurnaceOrAirHandler.Furnace) {
                return schema
                    .required('You must choose either 80% or 90%')
                    .oneOf([FurnaceEfficiency.Percent80, FurnaceEfficiency.Percent90]);
            }
            return schema.notRequired();
        }
    ),
    heatPumpOrGasPack: Yup.string().when('homeSystem', (homeSystem: string, schema: StringSchema) => {
        if (homeSystem === HomeSystem.Package) {
            return schema
                .required('You must choose either Heat Pump or Gas Pack')
                .oneOf([HeatPumpOrGasPack.HeatPump, HeatPumpOrGasPack.GasPack]);
        }
        return schema.notRequired();
    }),
});

export const schemaEmailNameCapture = Yup.object({
    firstname: Yup.string().required('Please enter your first name'),
    lastname: Yup.string().required('Please enter your last name'),
    email: Yup.string().email('Invalid email address').required('Please enter your email'),
    preferreddate: Yup.date().required('Please enter your preferred system replacement date'),
});
