import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import LoadingInternal from '../icons/Loading';

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

interface Props {
    open: boolean;
}
export default function LoadingOverlay(props: Props) {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={props.open}>
            <LoadingInternal />
        </Backdrop>
    );
}
