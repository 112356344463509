import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import ContentContainer from 'components/ContentContainer';
import { useHistory } from 'react-router-dom';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { Button } from 'components/Button';
import { AppRoute } from 'models/route';
import { ThemeWithImages } from '../../themes/theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(
    (theme: ThemeWithImages) => ({
        root: {},
        financing: {
            height: '100%',
            backgroundImage: `url(${theme.images.homeFinancingImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            [theme.breakpoints.up('md')]: {
                height: '485px',
            },
            [theme.breakpoints.down('sm')]: {
                height: '350px',
            },
        },
        descriptionContainer: {
            paddingTop: theme.spacing(15),
            paddingLeft: theme.spacing(8.4),
            paddingRight: theme.spacing(8.5),
            paddingBottom: theme.spacing(10),

            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(10),
                paddingLeft: theme.spacing(0),
                paddingRight: theme.spacing(0),
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(5),
                paddingLeft: theme.spacing(1.5),
                paddingRight: theme.spacing(1.5),
                paddingBottom: theme.spacing(5),
            },
        },
        descriptionHeading: {
            paddingBottom: theme.spacing(2),
        },
        descriptionDetail: {
            paddingBottom: theme.spacing(4),
            [theme.breakpoints.only('sm')]: {
                paddingBottom: theme.spacing(0),
            },
        },
    }),
    { name: 'home-financing' }
);

function Financing(props: WithWidth) {
    const classes = useStyles();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const history = useHistory();
    const heading = <Heading variant={'h2'} color={'primary'} label={'Financing Made Easy'} />;
    const content = (
        <Paragraph size={'normal'} color={'primary'}>
            Pre-approval in minutes. Will not impact your credit score. No fees or payments until installation is
            complete.
        </Paragraph>
    );
    const button = (
        <Button
            id={'financing-learn-more-btn'}
            color={'primary'}
            onClick={() => {
                history.push(AppRoute.Financing);
            }}
        >
            Learn More About Financing
        </Button>
    );
    return (
        <ContentContainer disableGrid background={'bg2'} className={classes.root}>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} className={classes.financing} />
                <Grid item xs={12} sm={12} md={6} container>
                    {isSm ? (
                        <Grid item sm={12} container className={classes.descriptionContainer} spacing={2}>
                            <Grid item sm={1} />
                            <Grid item sm={6} container direction={'column'}>
                                <Grid item className={classes.descriptionHeading}>
                                    {heading}
                                </Grid>
                                <Grid item className={classes.descriptionDetail}>
                                    {content}
                                </Grid>
                            </Grid>
                            <Grid item sm={5}>
                                {button}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} container direction={'column'} className={classes.descriptionContainer}>
                            <Grid item className={classes.descriptionHeading}>
                                {heading}
                            </Grid>
                            <Grid item className={classes.descriptionDetail}>
                                {content}
                            </Grid>
                            <Grid item>{button}</Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </ContentContainer>
    );
}

export default withWidth()(Financing);
