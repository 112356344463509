import { combineEpics } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, ignoreElements, catchError, withLatestFrom, map } from 'rxjs/operators';
import { RootState } from '../reducers';
import {
    createContact as createContactAction,
    contactInprogress as contactInprogressAction,
    contactOpendeal as contactOpendealAction,
} from '../actions/hubspotActions';
import { showProduct, showProductSuccess } from '../actions/productSearchActions';
import { createContact, contactInprogress, contactQuotedOptions, contactOpendeal } from '../services/hubspotSevices';
import { selectCart } from '../selectors/cart';

export const createHubspotContact$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(createContactAction),
        switchMap((action) =>
            createContact(action.payload).pipe(
                map((resp) => showProduct()),
                catchError((error) => {
                    console.error(error);
                    return EMPTY;
                })
            )
        )
    );

export const updateContactInProgress$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(contactInprogressAction),
        switchMap((action) =>
            contactInprogress(action.payload).pipe(
                catchError((error) => {
                    console.error(error);
                    return EMPTY;
                })
            )
        ),
        ignoreElements()
    );

export const updateContactQuotedOptions$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(showProductSuccess),
        withLatestFrom(state$.pipe(map(selectCart))),
        switchMap(([action, cart]) =>
            contactQuotedOptions({ ...cart.contactInformation }).pipe(
                catchError((error) => {
                    console.error(error);
                    return EMPTY;
                })
            )
        ),
        ignoreElements()
    );

export const updateContactOpendeal$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(contactOpendealAction),
        switchMap((action) =>
            contactOpendeal(action.payload).pipe(
                catchError((error) => {
                    console.error(error);
                    return EMPTY;
                })
            )
        ),
        ignoreElements()
    );

export default combineEpics(
    createHubspotContact$,
    updateContactInProgress$,
    updateContactQuotedOptions$,
    updateContactOpendeal$
);
