import React from 'react';
import MosaicForm from './Mosaic';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { resetProductCriteria } from '../../store/actions/productCriteriaActions';
import { AppRoute } from '../../models/route';
import { Cart, PaymentMethod } from '../../models/cart';
import { selectCart, selectCartProcessing } from '../../store/selectors/cart';
import { selectMosaic } from '../../store/selectors/financial';
import { placeOrder, setCart, updateCart } from '../../store/actions/cartActions';
import { selectRegions } from '../../store/selectors/country';
import { selectOrderLoading } from '../../store/selectors/order';
import { getMosaic } from '../../store/actions/financialActions';
import { checkoutGAMosaic } from '../../store/actions/gaEcommerceActions';

function MosaicContainer() {
    const history = useHistory();
    const dispatch = useDispatch();
    const cart: Cart = useSelector(selectCart);
    const mosaic = useSelector(selectMosaic);
    const regions = useSelector(selectRegions);
    const loading = useSelector(selectCartProcessing);
    const orderLoading = useSelector(selectOrderLoading);

    if (!mosaic?.merchantId && !orderLoading) {
        return <Redirect to={AppRoute.ChoosePaymentOption} />;
    }

    return (
        <MosaicForm
            loading={loading || orderLoading}
            cart={cart}
            mosaic={mosaic}
            regions={regions}
            onBack={() => {
                history.push(AppRoute.ChoosePaymentOption);
            }}
            placeOrderFunction={(cart: Cart, paymentMethod: PaymentMethod) => {
                if (!!cart && cart.cartId) {
                    dispatch(
                        placeOrder({
                            cart: {
                                cartId: cart.cartId,
                                email: cart.email,
                                billingAddress: cart.billingAddress,
                                shippingAddress: cart.shippingAddress,
                            },
                            paymentMethod: paymentMethod,
                        })
                    );
                }
            }}
            onExit={() => {
                dispatch(resetProductCriteria());
                history.push(AppRoute.Home);
            }}
            updateCart={(cart) => {
                if (!!cart && cart.cartId) {
                    dispatch(
                        updateCart({
                            cart: {
                                cartId: cart.cartId,
                                email: cart.email,
                                billingAddress: cart.billingAddress,
                                shippingAddress: cart.shippingAddress,
                            },
                        })
                    );
                }
                dispatch(checkoutGAMosaic());
            }}
            reloadMosaic={() => {
                if (cart && cart.cartId) {
                    dispatch(getMosaic({ cartId: cart.cartId }));
                }
            }}
        />
    );
}

export default MosaicContainer;
