import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import createStore from './store';
import { swInit, swUpdate } from './store/actions/serviceWorkerActions';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';

const { store, persistor } = createStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onSuccess: (registration) => store.dispatch(swInit(registration)),
    onUpdate: (registration) => store.dispatch(swUpdate(registration)),
});
