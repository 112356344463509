import { on, reducer } from 'ts-action';
import { resetProductCriteria, setProductCriteria, setPromotionCode } from '../actions/productCriteriaActions';
import { ProductCriteria } from '../../models/productCriteria';

const initialState: ProductCriteria = {};

export const productCriteriaReducer = reducer(
    initialState,
    on(setPromotionCode, (state, action) => ({
        ...state,
        ...action.payload,
    })),
    on(setProductCriteria, (state, action) => ({
        ...state,
        ...action.payload,
    })),
    on(resetProductCriteria, () => ({
        ...initialState,
    }))
);

export default productCriteriaReducer;
