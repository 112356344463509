import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ContentContainer from './ContentContainer';
import { Button } from 'components/Button';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
        [theme.breakpoints.only('sm')]: {
            minHeight: '80px',
        },
    },
    button: {
        '&:not(:first-child)': {
            paddingLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            '&:not(:first-child)': {
                paddingTop: theme.spacing(2),
                paddingLeft: 0,
            },
            width: '100%',
            maxWidth: '100%',
            flexBasis: '100%',
        },
    },
}));

interface Props {
    onBack?: () => void;
    onNext?: () => void;
    disabled?: boolean;
    nextLabel?: string;
    backLabel?: string;
}

export default function WizardCriteriaFooter(props: Props) {
    const classes = useStyles();
    const componentId = 'wizard-footer';
    return (
        <ContentContainer size={'footer2'} background={'bg2'} disableGrid>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer>
                    <div className={classes.root}>
                        {!!props.onBack && (
                            <div className={classes.button}>
                                <Button id={`${componentId}-back-btn`} color={'secondary'} onClick={props.onBack}>
                                    {props.backLabel ? props.backLabel : 'Back'}
                                </Button>
                            </div>
                        )}
                        <div className={classes.button}>
                            <Button
                                id={`${componentId}-next-btn`}
                                color={'primary'}
                                onClick={props.onNext}
                                disabled={props.disabled}
                            >
                                {props.nextLabel ? props.nextLabel : 'Next'}
                            </Button>
                        </div>
                    </div>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}
