import React, { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import history from './history';

import { hotjar } from 'react-hotjar';

import { AppRoute } from '../models/route';
import Index from '../pages/airandheatdoneright/AirAndHeatDoneRight';
import Home from '../pages/Home';
import Financing from '../pages/Financing';
import AirConditioners from '../pages/knowledge-center/AirConditioners';
import PackagedUnits from '../pages/knowledge-center/PackagedUnits';
import HeatPumps from '../pages/knowledge-center/HeatPumps';
import AirHandlers from '../pages/knowledge-center/AirHandlers';
import Furnaces from '../pages/knowledge-center/Furnaces';
import HowHVACWorks from '../pages/knowledge-center/HowHVACWorks';
import OurProcess from '../pages/knowledge-center/OurProcess';
import WhoWeAre from '../pages/WhoWeAre';
import ContactUs from '../pages/ContactUs';
import Warranty from '../pages/Warranty';
import EnergyStar from '../pages/EnergyStar';
import MadeInUsa from '../pages/MadeInUsa';
import Pricing from '../pages/Pricing';
import SecurityAndPrivacyPolicy from '../pages/SecurityAndPrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import AboutYourHome from '../pages/wizard/AboutYourHome';
import AboutYourSystem from '../pages/wizard/AboutYourSystem';
import EmailNameCapture from '../pages/wizard/EmailNameCapture';
import EquipmentOptionContainer from '../pages/confirmation/EquipmentOptionContainer';
import ChoosePaymentOptionContainer from '../pages/confirmation/ChoosePaymentOptionContainer';
import ConfirmationContainer from '../pages/confirmation/ConfirmationContainer';
import CreditCardContainer from '../pages/payment/CreditCardContainer';
import MosaicContainer from '../pages/payment/MosaicContainer';
import { useDispatch, useSelector } from 'react-redux';
import { selectStoreLoading, selectStoreCode } from '../store/selectors/storeSelector';
import { getStore } from '../store/actions/storeActions';
import { getCountryById } from '../store/actions/countryActions';
import Loading from '../components/common/Loading';
import ScrollToTop from '../components/ScrollToTop';
import { getProductAttribute } from '../store/actions/productAttributeActions';
import config from '../config';
import Thankyou from '../pages/Thankyou';

const AppRouter: React.FC = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectStoreLoading);
    const storeCode = useSelector(selectStoreCode);
    useEffect(() => {
        if (storeCode !== 'NONE') {
            dispatch(getStore());
            dispatch(getCountryById({ id: 'US' }));
            dispatch(getProductAttribute({ id: 'EFFICIENCY' }));
        }
        hotjar.initialize(Number(config.hotjarId), Number(config.hotjarSv));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) {
        return <Loading />;
    }
    return (
        <Router history={history}>
            <ScrollToTop />
            <Switch>
                <Route path={AppRoute.Home} component={storeCode === 'NONE' ? Index : Home} />
                <Route path={AppRoute.Financing} component={Financing} />
                <Route path={AppRoute.AirConditioners} component={AirConditioners} />
                <Route path={AppRoute.PackagedUnits} component={PackagedUnits} />
                <Route path={AppRoute.HeatPumps} component={HeatPumps} />
                <Route path={AppRoute.AirHandlers} component={AirHandlers} />
                <Route path={AppRoute.Furnaces} component={Furnaces} />
                <Route path={AppRoute.HowHvacWorks} component={HowHVACWorks} />
                <Route path={AppRoute.WhatIsOurProcess} component={OurProcess} />
                <Route path={AppRoute.WhoWeAre} component={WhoWeAre} />
                <Route path={AppRoute.ContactUs} component={ContactUs} />
                <Route path={AppRoute.Warranty} component={Warranty} />
                <Route path={AppRoute.EnergyStar} component={EnergyStar} />
                <Route path={AppRoute.MadeInUsa} component={MadeInUsa} />
                <Route path={AppRoute.Pricing} component={Pricing} />
                <Route path={AppRoute.SecurityAndPrivacyPolicy} component={SecurityAndPrivacyPolicy} />
                <Route path={AppRoute.TermsAndConditions} component={TermsAndConditions} />
                <Route path={AppRoute.AboutYourHome} component={AboutYourHome} />
                <Route path={AppRoute.AboutYourSystem} component={AboutYourSystem} />
                <Route path={AppRoute.EmailNameCapture} component={EmailNameCapture} />
                <Route path={AppRoute.EquipmentOptions} component={EquipmentOptionContainer} />
                <Route path={AppRoute.ChoosePaymentOption} component={ChoosePaymentOptionContainer} />
                <Route path={AppRoute.Confirmation} component={ConfirmationContainer} />
                <Route path={AppRoute.CreditCard} component={CreditCardContainer} />
                <Route path={AppRoute.Mosaic} component={MosaicContainer} />
                <Route path={AppRoute.Thankyou} component={Thankyou} />
                <Redirect to={AppRoute.Home} />
            </Switch>
        </Router>
    );
};

export default AppRouter;
