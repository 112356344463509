import http from './http';
import { Cart, PaymentMethod } from '../../models/cart';
import { BrainTree, Mosaic } from '../../models/financial';
import { Order } from '../../models/order';
import _ from 'lodash';
import config from '../../config';
import Cookies from 'js-cookie';

export const getCart = (cartId: string) => http.get<Cart>(`/cart/${cartId}`);
export const createCart = (cart: Cart, gaParams?: any) => {
    // @ts-ignore
    const gaTracker = _.find(window?.ga?.getAll(), (t) => t.get('trackingId') === config.googleAnalyticId);
    const gaClientId = gaTracker ? gaTracker.get('clientId') : undefined;
    const gaGid = _.reduce(
        Cookies.get('_gid')?.split('.'),
        (result, value, key) => {
            if (key === 2) {
                result = value;
            } else if (key > 2) {
                result = `${result}.${value}`;
            }
            return result;
        },
        ''
    );

    const httpConfig = gaClientId
        ? {
              headers: {
                  ...gaParams,
                  gaClientId,
                  gaGid,
              },
          }
        : undefined;
    return http.post<Cart>('/carts', cart, httpConfig);
};
export const saveForLater = (cart: Cart) => http.patch<Cart>(`/carts/${cart.cartId}/saveForLater`, cart);
export const setupCheckOut = (cartId: string, paymentMethod: PaymentMethod) =>
    http.post<BrainTree>(`/carts/${cartId}/setupCheckout`, paymentMethod);

export const placeOrder = (cartId: string, paymentMethod?: PaymentMethod) =>
    http.post(`/carts/${cartId}/placeOrder`, paymentMethod);

export const updateCart = (cartId: string, cart: Cart) => http.patch<Cart>(`/carts/${cartId}`, cart);

export const mosaicInfo = (cartId: string) => http.get<Mosaic>(`/Carts/${cartId}/payment/mosaic/info`);

export const getOrder = (orderId: number) => http.get<Order>(`/orders/${orderId}`);
