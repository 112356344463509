import React, { MouseEventHandler } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ContentContainer from './ContentContainer';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'components/Button';
import HeadlineParagraph from './HeadlineParagraph';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            minHeight: '100vh',
        },
    };
});
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function ContentOverlay(props: Props) {
    const classes = useStyles();
    const componentId = props.id ? props.id : 'content-overlay';
    const justifyContent = props.justifyContent ? props.justifyContent : 'center';
    return (
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
            className={classes.root}
        >
            <ContentContainer background={'bg2'} disableGrid>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer className={classes.root} size={'medium'}>
                        <Grid container justify={'center'} spacing={3}>
                            <Hidden xsDown>
                                <Grid item xs={12} sm={1} md={3} />
                            </Hidden>
                            <Grid item xs={12} md={6} container direction={'row'} spacing={4}>
                                {props.closeButtonOnTop && (
                                    <Grid item container justify={'center'}>
                                        <Button
                                            id={`${componentId}-close-btn`}
                                            color={'primary'}
                                            onClick={props.onClose}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                )}
                                {props.helps.map((help, index) => (
                                    <Grid key={index} item xs={12} sm={12} container justify={justifyContent}>
                                        <HeadlineParagraph label={help.headLine} headlineSize={'h2'}>
                                            {help.paragraph}
                                        </HeadlineParagraph>
                                    </Grid>
                                ))}
                                {!props.closeButtonOnTop && (
                                    <Grid item container justify={'center'}>
                                        <Button
                                            id={`${componentId}-close-btn`}
                                            color={'primary'}
                                            onClick={props.onClose}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                            <Hidden xsDown>
                                <Grid item xs={12} sm={1} md={3} />
                            </Hidden>
                        </Grid>
                    </ContentContainer>
                </Container>
            </ContentContainer>
        </Dialog>
    );
}

export interface Props {
    id?: string;
    open: boolean;
    heading?: any;
    paragraph?: any;
    onClose: MouseEventHandler;
    closeButtonOnTop?: boolean;
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
    helps: HelpContent[];
}

interface HelpContent {
    headLine: string;
    imgSrc?: string;
    paragraph: string | object;
}
