import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import withWidth, { isWidthDown, WithWidth } from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Button';
import ContentContainer from 'components/ContentContainer';
import { Paragraph } from '../Paragraph';
import { ThemeWithImages } from '../../themes/theme';
import Container from '@material-ui/core/Container';

import chicagoland from '../../assets/images/airandheatdoneright/aahdr_chicagoland.png';
import missouri from '../../assets/images/airandheatdoneright/aahdr_missouri.png';
import washington from '../../assets/images/airandheatdoneright/aahdr_washington.png';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles(
    (theme: Theme) => ({
        content: {
            '& .MuiGrid-item': {
                paddingBottom: theme.spacing(2),
            },
            '& .MuiButton-contained': {
                width: '150px',
            },
            [theme.breakpoints.down('xs')]: {
                '&:not(:first-child)': {
                    paddingTop: theme.spacing(2),
                },
            },
        },
        imageContainer: {
            maxHeight: '100%',
            width: '100%',
        },
        image: {
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            display: 'block',
            margin: '0 auto',
            overflow: 'hidden',
        },
    }),
    { name: 'navigate-site' }
);

function NavigateSite(props: WithWidth) {
    const classes = useStyles();
    const theme = useTheme<ThemeWithImages>();
    const { width } = props;
    const isXs = isWidthDown('xs', width);
    return (
        <ContentContainer disableGrid background={'bg3'}>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer size={'medium'} disableGrid={isXs}>
                    <Grid container alignItems={'flex-start'} justify={'center'} spacing={isXs ? 0 : 4}>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            container
                            direction={'column'}
                            justify={'center'}
                            alignItems={'center'}
                            spacing={isXs ? 0 : 2}
                            className={classes.content}
                        >
                            <Grid item xs={12} className={classes.imageContainer}>
                                <CardMedia component={'img'} image={chicagoland} className={classes.image} />
                            </Grid>
                            <Grid item>
                                <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                    Chicagoland (IL)
                                </Paragraph>
                            </Grid>
                            <Grid item>
                                <Button
                                    id={'navigate-chicago'}
                                    color={'secondary'}
                                    onClick={() => {
                                        window.location.href = `http://chicagoland.${window.location.host}`.toLowerCase();
                                    }}
                                >
                                    Select
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            container
                            direction={'column'}
                            justify={'center'}
                            alignItems={'center'}
                            spacing={isXs ? 0 : 2}
                            className={classes.content}
                        >
                            <Grid item className={classes.imageContainer}>
                                <CardMedia component={'img'} image={missouri} className={classes.image} />
                            </Grid>
                            <Grid item>
                                <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                    Missouri (MO, KS)
                                </Paragraph>
                            </Grid>
                            <Grid item>
                                <Button
                                    id={'navigate-missouri'}
                                    color={'secondary'}
                                    onClick={() => {
                                        window.location.href = `http://missouri.${window.location.host}`.toLowerCase();
                                    }}
                                >
                                    Select
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            container
                            direction={'column'}
                            justify={'center'}
                            alignItems={'center'}
                            spacing={isXs ? 0 : 2}
                            className={classes.content}
                        >
                            <Grid item className={classes.imageContainer}>
                                <CardMedia component={'img'} image={washington} className={classes.image} />
                            </Grid>
                            <Grid item>
                                <Paragraph size={'normal'} color={'primary'} align={'center'}>
                                    Washington, DC Area (VA, MD, DE)
                                </Paragraph>
                            </Grid>
                            <Grid item>
                                <Button
                                    id={'navigate-washington'}
                                    color={'secondary'}
                                    onClick={() => {
                                        window.location.href = `http://greaterdc.${window.location.host}`.toLowerCase();
                                    }}
                                >
                                    Select
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}

export default withWidth()(NavigateSite);
