import React, { Fragment } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';

function SecurityAndPrivacyPolicy() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
        };
    });
    const classes = useStyles();
    return (
        <SubPage
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Security & Privacy Policy'} />
                    </Grid>
                    {/* Privacy Policy Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Privacy Policy'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>Revised 05/04/2020</Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            This Privacy Policy (“Policy”) describes how the data controllers [airandheatdoneright.com]
                            and the licensor of this website, AirandHeatDoneRight.com (collectively “we” or “us” or
                            “our”) collect and use your personal information—that is, any information that identifies or
                            could reasonably identify you—when you access or use our products, websites, software, or
                            applications (collectively “Services”) or when we receive your personal information from
                            third parties, such as our corporate affiliates, subcontractors, or other third parties that
                            we work with.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            For certain Services, there may be a separate privacy policy or a separate written agreement
                            (e.g., an end-user license agreement) between you and us—and, in such instances, that other
                            privacy policy or separate written agreement shall control.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            We may revise this Policy at any time. And when we do, we will notify you that revisions
                            have been made by, for example, updating the revision date provided above or, if
                            circumstances warrant it, contact you directly. Please review this Policy each time you
                            access or use our Services, so you can stay informed about our current privacy practices.
                        </Paragraph>
                    </Grid>
                    {/* Privacy Policy End */}

                    {/* Collection Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Collection'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            We collect personal information in a variety of ways during our interactions with you. For
                            example, we may collect personal information when you create an account or use our Services
                            alone or in conjunction with products and services provided by us, our corporate affiliates,
                            or our subcontractors.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            The type of personal information we collect depends on the particulars of your interaction
                            with us, your choices, and applicable law. But the following are examples of personal
                            information we may collect and when we may collect it.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Information You Voluntarily Provide: We may collect information that you voluntarily provide
                            to us, such as your name, your email address, your physical address, your phone number, your
                            demographic information, your employment history, your transactions with us, any feedback
                            you provide, your credit card information, your preferences, your online shopping cart
                            information, your warranty claims, your customer-service requests, or any other information
                            that you may provide to us. You may voluntarily provide this information to us
                            electronically when you use our applications, websites, products, or services. Or you may
                            also provide this information to us manually, such as when you provide us with a physical
                            document or warranty registration card.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Usage/Account Information: If use of a Service requires you to create an account with us, we
                            may collect information about your account credentials (e.g., password, user-ID,
                            authentication information), your use of the Services or products associated with the
                            Services, or any other information associated with your account. And we may collect
                            information obtained about you from a third-party service (e.g., Facebook, Google) when you
                            use these services to create or log in to the account, pursuant to that third party’s
                            authorizations and practices.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Automatically Collected Information: We may also collect information automatically. For
                            example, when you use our Services, we may collect information about how and when you used
                            our Services, the actions you performed or requested through the use of our Services, the
                            type of device used to access our Services, your location (which may be provided by the
                            device you are accessing our Services from), and your IP address. We may also use “cookies”
                            and other automated tracking systems (also see Section 10 below) to collect information
                            about you.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Information About You or From Equipment: In certain instances, you may be using our Services
                            with equipment provided by us or with equipment or services provided by our corporate
                            affiliates, our subcontractors, or others with whom we may be working (collectively
                            “Equipment”). We may collect information both internal and external to the Equipment, and we
                            may collect information in an automated manner, manually, or both.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>For example, we may collect information about</Paragraph>
                    </Grid>
                    <Grid item xs={12}>
                        <ul>
                            <li>
                                <Paragraph>
                                    the Equipment’s setup, network connections, location, performance, resource
                                    consumption, and operation,
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>adjustments made to the Equipment,</Paragraph>
                            </li>
                            <li>
                                <Paragraph>the Equipment’s usage information,</Paragraph>
                            </li>
                            <li>
                                <Paragraph>any faults or errors detected by the Equipment, and</Paragraph>
                            </li>
                            <li>
                                <Paragraph>technical information related to the Equipment.</Paragraph>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            In addition, we may collect information about the environment surrounding the Equipment
                            using sensors in or connected to the Equipment. For example, we may collect information
                            about
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12}>
                        <ul>
                            <li>
                                <Paragraph>
                                    the occupancy, activity, temperature, air quality, humidity, illuminance, decibel
                                    levels, or other parameters of the environment the Equipment is used in or with,
                                </Paragraph>
                            </li>
                            <li>
                                <Paragraph>you and your activity within the environment.</Paragraph>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Collecting information about you and your environment, and your interactions with the
                            environment, helps the Equipment and Services automatically adjust, for example, the
                            temperature settings of your home or office to a setting that may be more comfortable to
                            you.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Third Party Sources: We may also obtain information from other sources, including from
                            private agencies (e.g., credit-rating services), our corporate affiliates, or public
                            sources. And, as permitted by applicable law, we may de-identify and aggregate information
                            we collect from you.
                        </Paragraph>
                    </Grid>
                    {/* Collection End */}
                    {/* Use Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Use'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            We use your personal information to provide you meaningful interactions with us, to fulfill
                            our contractual and legal obligations, and to advance our legitimate business interests.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            For example, we may use or share (also see Section 3) the information we collect to:
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Communicate with you, including sending you updates, technical information, and
                            customer-service information related to Services or Equipment you used or purchased;
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Provide requested information, Equipment, or Services, including administrating any account
                            you may have with us, providing interactivity and automated actions, updating Services and
                            Equipment, transacting commerce, billing, and personalizing the Services and Equipment for
                            you;
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Comply with our contractual, legal, and regulatory obligations, including responding to
                            legitimate requests from law enforcement, regulatory bodies, or other competent authorities,
                            protecting the safety, rights, and property of us and others, handling legal claims and
                            disputes, preventing or investigating crime, fraud, cyber risks, and risks in general;
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Establish a potential commercial or employment relationship with you, including evaluating
                            any potential business relationship you suggest that we may enter into and, if you apply for
                            employment or currently are or previously were an employee, using the information for making
                            employment and personnel decisions or using the information to improve and manage our
                            internal processes and procedures;
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Conduct research and development, including improving the Services and Equipment, developing
                            new offerings, developing and conducting training, and maintaining and improving the
                            security of the Services and Equipment;
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Analyze the usage, performance, specifications, and operation of the Services and Equipment,
                            including analyzing the Equipment and Services you use and the way you may be using them,
                            analyzing our performance, monitoring trends, providing automated adjustment of the
                            Equipment, investigating suspected violations or malicious or illegal activity, and using
                            the collected information for internal business purposes and development;
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Advertise and market to you, including sending you promotional offers, targeting
                            advertising, and general marketing communications, providing you with information we believe
                            may be of interest to you, and understanding how you interact with such communications; and
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Further any purpose described to you when the information was collected.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            We may share your personal information with the following persons or entities, for the
                            following purposes or for purposes as otherwise described in this Policy.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            With our corporate affiliates. We may share information with our corporate affiliates and
                            any of their current or future subsidiaries to perform one or more of the uses described in
                            this Policy. Some affiliates use their own privacy policy, which may be different from this
                            Policy.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            With third parties. We may share information with third parties—such as agents, other
                            companies, or contractors—to provide Services or Equipment on our behalf or to assist us
                            with the provision or development of such. For example, we may engage third parties to
                            provide marketing, advertising, communications, infrastructure, IT, or processing services.
                            Or we, or our corporate affiliates, may work with third-party HVAC technicians, service
                            providers, or dealers to provide the Services or Equipment to you. Further still, we may
                            work with third parties to develop and improve our Services or Equipment or to improve their
                            Services or Equipment. Some of our Services or Equipment may link to products or services of
                            third parties whose privacy policies may be different than ours. If you provide information
                            to a third party, then their privacy policy will control the information you provide to
                            them.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            For legal proceedings. We may share information as required for legal proceedings and
                            processes, including i) responding to requests for information from public, regulatory,
                            judicial, governmental, or law-enforcement agencies, ii) complying with our legal
                            obligations or when we, in good faith, believe the law requires it, iii) investigating or
                            enforcing potential violations by you of our terms and policies, or iv) protecting the
                            rights, property, or safety of us or others.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            For corporate reorganization, M&A. We may share information in relation to any potential or
                            realized reorganization, merger, sale, liquidation, bankruptcy, or transfer of a portion or
                            all of our assets or businesses.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            With your consent. We may share information collected from you for any purpose that you
                            consented to or that you directed us to do.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            We do not sell or rent your personal information to third parties outside of our corporate
                            affiliates, unless we have your consent. We may, however, provide third parties such
                            information that has been de-identified, aggregated, anonymized, or otherwise processed so
                            that it does not reasonably identify you without your consent, for the purposes of research,
                            advertising, marketing, internal business, strategic planning, vendor management, and
                            development (including developing new Services and Equipment or improving existing ones).
                        </Paragraph>
                    </Grid>
                    {/* Use End */}

                    {/* Retention Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Retention'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            We may retain your personal information as long as necessary to accomplish the purposes and
                            uses outlined in this Policy, but no longer than is permitted by applicable law. When and
                            where required by applicable law, we will delete your information. But even when we delete
                            your information from active databases, such information may be retained in dormancy on
                            automatic back-ups.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Your personal information may be stored locally on the Equipment, locally on devices
                            connected to the Equipment or Services, or remotely on servers located in the United States
                            or other jurisdictions. (Also see Sections 5 & 6 below.)
                        </Paragraph>
                    </Grid>
                    {/* Retention End */}
                    {/* Security Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Security'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            We use reasonable administrative, physical, and technological security measures to safeguard
                            your personal information against theft or other unauthorized access, use, or modification.
                            And we require relevant third parties to, through contracts, do the same. The security
                            measures we use are designed to provide a level of security appropriate to that personal
                            information.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Unfortunately, no security measure or combination of security measures is perfect. As such,
                            we cannot guarantee the security of your personal information that we have collected.
                        </Paragraph>
                    </Grid>
                    {/* Security End */}
                    {/* Transfers Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Transfers'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            The Services are intended for users in the United States and Canada. We are based in the US,
                            and that is where we primarily process and store personal information. However, there may be
                            instances where personal information is transferred internationally. For example, you may be
                            using our Services from outside the United States. If that is the case, you, by using the
                            Services, consent to the international transfer of your personal information, to the extent
                            provided by applicable law.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            As another example, your personal information may have been collected not by us but by a
                            corporate affiliate that may be located inside or outside the United States and that may
                            have a privacy policy different from this Policy. When and where necessary, we take
                            appropriate measures—such as contractual agreements—when such information is transferred to
                            us. For example, if your personal information was collected from the European Economic Area
                            (“EEA”), we use a variety of legal mechanisms to help ensure your personal information is
                            appropriately protected when we transfer it to other countries, including the United States.
                            For example, we may use “model contract clauses” that are approved by the European
                            Commission when we receive personal information from any corporate affiliate located in the
                            EEA or when we transfer personal information we have collected from the EEA to another
                            jurisdiction. Moreover, we may transfer your personal information to third parties—such as
                            service providers that provide processing services for the information or researchers that
                            are helping develop and improve our Services and Equipment—that may be located or have
                            facilities located around the globe, including in countries that may not provide equivalent
                            levels of protection as provided in your home jurisdiction. When and where necessary under
                            applicable law, we use a variety of legal mechanisms to help ensure your personal
                            information is appropriately protected when we transfer it to or within other countries. For
                            example, we may use “model contract clauses” that are approved by the European Commission
                            for personal information from the EEA.
                        </Paragraph>
                    </Grid>
                    {/* Transfers End */}

                    {/* EEA Individuals Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'EEA Individuals'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Our Services are intended for users located in the United States and Canada. But, to the
                            extent you are in the EEA, you may have certain rights under the law regarding the
                            collection and processing of the personal information you have provided to us. In such
                            cases, we will process your personal information only in the following instances:
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            You have given consent. We may use your personal information if you have given us your
                            consent for one or more specific purposes. For example, you may consent to digital marketing
                            or the use of cookies on our website. You may withdraw that consent at any time. You can
                            manage or disable cookies at any time by adjusting your browser settings.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            To fulfill our obligations to you. We may use your personal information to fulfill our
                            contractual obligations to you. For example, we may need to use your personal information
                            because it is reasonably needed to provide the Services or Equipment you have requested, or
                            to take requested steps for you before we enter into a contract.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            To fulfill a legal obligation. We may use your personal information to comply with a legal
                            obligation we are subject to.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            To protect a vital interest. We may use your personal information to protect your vital
                            interests or those of another person, or if necessary to perform a task carried out in the
                            public interest or in the exercise of official authority vested in us.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            For a legitimate interest. We may use your personal information if we have a legitimate
                            interest in doing so. For example, we may use your personal information to send you updates
                            about the Services and Equipment you used or purchased, or we may use your personal
                            information to provide and improve the Services and Equipment.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            To the extent that you are in the EEA, you have certain rights to access the personal
                            information we hold about you. And you may ask us, to the extent provided by applicable law,
                            to correct, erase, or restrict the processing of that information. If you would like to
                            exercise one of those rights, please contact us at: [cs@airandheatdoneright.com].
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            To the extent that you are in the EEA and if you have questions or complaints that we are
                            not able to resolve, you have the right to submit a complaint to the data privacy authority
                            where you reside.
                        </Paragraph>
                    </Grid>
                    {/* EEA Individuals End */}

                    {/* California Privacy Rights Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'California Privacy Rights'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            If you are resident of California, you may have certain rights under California’s
                            “Shine-the-Light” Law (California Civil Code § 1798.83). We may share the personal
                            information of California residents with third parties for those third parties’ direct
                            marketing purposes. In some cases, the Services may provide an “opt-out” or “opt-in” option
                            for such use of your personal information. But, if the Services do not provide such option,
                            then you, if you are a California resident, may request information about the names and
                            address of third parties that we shared your personal information with, and the categories
                            of personal information we shared with them. If you would like to request this information,
                            please contact us at [cs@airandheatdoneright.com].
                        </Paragraph>
                    </Grid>
                    {/* California Privacy Rights End */}
                    {/* Minors Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Minors'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Between the ages of 13 (or the equivalent minimum age in your jurisdiction) and 18, you
                            agree you will only use the Services under the direct supervision of a legal guardian or
                            parent who has agreed to our Terms and this Policy for themselves and on your behalf. You
                            may not use our Services if you are under 13 years of age. We do not knowingly collect or
                            store any personal information from anyone under the age of 18 unless we believe we have
                            received consent from that person’s legal guardian or parent who has agreed to our
                            airandheatdoneright.com/terms-and-conditions and this Policy.
                        </Paragraph>
                    </Grid>
                    {/* Minors End */}
                    {/* Automatically Collected Information Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Automatically Collected Information'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            As described in Section 1, we may automatically collect information about you whenever you
                            visit our website or interact with our Equipment or Services. For example, these automated
                            systems may collect information about the browser you are using, your device’s operating
                            system, or the duration for which you used our Services or Equipment. The following are some
                            examples and descriptions of the ways in which we may automatically collect information:
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Cookies are small files that are stored on your device (g., computer, mobile phone) when you
                            visit or access some of our Services through a web browser. Cookies can, among other things,
                            remember your preferences, remember your login information, and track your visits to our
                            websites. By doing this, the cookies help us customize your experience with our Services. We
                            use the following types of cookies:
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Necessary cookies: These cookies are essential to enable basic functions on the website and
                            make the website usable. Once you close your browser, these cookies disappear.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Functional cookies: These cookies enable the website to save information which has already
                            been entered (such as language choices or your location), so that we can offer you more
                            personalized functions. These cookies collect anonymous information and cannot track your
                            movements on other websites.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Performance cookies: These cookies are used for collecting information about the use of our
                            website, including the number of unique visitors to our website, the number of views for a
                            webpage, the amount of time a user spends on a webpage, and other website statistics. These
                            cookies are used to improve the performance of the website and the user experience. The
                            collected information is aggregated and made anonymous.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Local Shared Objects (Flash cookies): These cookies are used with Adobe Flash Player to
                            deliver certain content.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Web Beacons are small files that collect advertising data. For example, a Web Beacon may
                            count page views or responses to our advertising—allowing us to provide information that may
                            be more relevant to you.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Website Analytics: We use a web analytics service, Google Analytics. This service uses
                            cookies to collect information such as your IP address or other identifiers, browser
                            information, and the content you view. Google Analytics uses this information on our behalf
                            for the purpose of analyzing how visitors use our website, the number of users on our
                            website, the location of our users, the most frequently used parts of our website, the most
                            commonly used browsers of visitors to our website, and the interests of our users.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            You can manage or disable cookies at any time by adjusting your browser settings. You can
                            also set your browser to notify you when you receive certain cookies, giving you the chance
                            to decide whether to accept it or not. Browsers are different, so refer to the settings menu
                            of your browser for instructions on how to change your cookie preferences. If you choose not
                            to receive cookies, our website may not function properly and certain services may not be
                            available. You can find out more information about cookies and how to manage them by
                            visiting the website www.allaboutcookies.org . You can also find out how to disable Flash
                            cookies visiting www.adobe.com/products/flashplayer/security for details. If you have
                            questions or comments about the use of cookies on our website, please contact us at
                            [cs@airandheatdoneright.com].
                        </Paragraph>
                    </Grid>
                    {/* Automatically Collected Information End */}
                    {/* Your Choices Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Your Choices'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            By providing your personal information to us, you agree to the collection, processing,
                            international transfer and use of that information as set forth in this Policy. Where
                            required by applicable law, we will ask for your explicit consent.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            You may also have the right to lodge a complaint with a supervisory authority.
                        </Paragraph>
                    </Grid>
                    {/* Your Choices End */}
                    {/* Direct Marketing Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Direct Marketing'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            We may process personal information provided by you for marketing purposes. You have the
                            right to opt-out of our use of your personal information for marketing purposes. If you no
                            longer wish to receive marketing communications from us, you can let us know by email at
                            [cs@airandheatdoneright.com]. You may also use, if provided, the opt-out mechanism at the
                            bottom of our electronic marketing communications to change your marketing preferences or
                            opt-out of all marketing communications.
                        </Paragraph>
                    </Grid>
                    {/* Direct Marketing End */}
                    {/* Contact Us Begin */}
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading variant={'h3'} color={'primary'} label={'Contact Us'} />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            If you have any questions about this Privacy Policy or the information we have collected
                            about you, please contact us by email at [cs@airandheatdoneright.com].
                        </Paragraph>
                    </Grid>
                    {/* Contact Us End */}
                </Fragment>
            }
        />
    );
}

export default SecurityAndPrivacyPolicy;
