import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectStoreState = (state: RootState) => state.store;
export const selectStoreCode = createSelector(selectStoreState, (store) => store.storeCode);
export const selectStoreConfig = createSelector(selectStoreState, (store) => store.config);
export const selectGoogleAnalyticId = createSelector(selectStoreConfig, (config) => config?.googleId);
export const selectStoreLoading = createSelector(selectStoreState, (store) => store.loading);

export const selectPromotionCodeBlockId = createSelector(
    selectStoreConfig,
    (config) => config?.promotionBlockId || 'promo_code'
);
export const selectContentState = (state: RootState) => state.content;
export const selectContent = createSelector(selectContentState, (state) => state.block);
export const selectContentLoading = createSelector(selectContentState, (state) => state.loading);
