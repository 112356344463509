import React from 'react';
import Container from '@material-ui/core/Container';
import ContentContainer from '../../components/ContentContainer';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Heading } from '../../components/Heading';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import { Button } from '../../components/Button';
import WizardHeader from '../../components/WizardHeader';
import { CartItem, CartPrice } from '../../models/cart';
import { Paragraph } from '../../components/Paragraph';
import { getProductImage, ThemeWithImages } from '../../themes/theme';
import ProductDetail from '../../components/ProductDetail';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import CartSummary from '../../components/CartSummary';
import { useHistory } from 'react-router-dom';
import { AppRoute } from '../../models/route';
import HeadlineParagraph from "../../components/HeadlineParagraph";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    footer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    nav: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    paymentBox: {
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderWidth: '1px',
        padding: theme.spacing(2),
        height: '100%',
    },
    homeAvatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    systemPrice: {
        textAlign: 'right',
        [theme.breakpoints.only('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    subTotal: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    selectedText: {
        paddingTop: theme.spacing(3),
    },
    summary: {
        paddingTop: theme.spacing(4),
    },
    divider: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

interface Prop {
    cartItem: CartItem;
    cartPrices: CartPrice;
    onSelectCreditCard: () => void;
    onSelectFinancing: () => void;
    loading: boolean;
}

function ChoosePaymentOption(props: Prop) {
    const classes = useStyles();
    const history = useHistory();

    const theme = useTheme<ThemeWithImages>();
    const { images } = theme;
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const { cartItem, cartPrices, loading, onSelectFinancing, onSelectCreditCard } = props;
    const summarySubtotal = cartPrices.subtotalExcludingTax | 0;
    const taxesAverage = summarySubtotal * 0.085;

    return (
        <div>
            <LoadingOverlay open={loading} />
            <WizardHeader
                showBackButton
                onBack={() => {
                    history.push(AppRoute.EquipmentOptions);
                }}
            />

            <Divider />

            <ContentContainer disableGrid background={'bg3'}>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer className={classes.header}>
                        <Grid container>
                            <Grid item xs={12} md={8} container spacing={2}>
                                <Grid item xs={12}>
                                    <Heading variant={'h2'} label={'Great choice! Time to finish up.'} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Paragraph>
                                        Now are you ready for the best part?{' '}
                                        <b>
                                            {' '}
                                            You will not be charged until the installation is complete and approved by
                                            you!{' '}
                                        </b>{' '}
                                        The final step is to book your home inspection. We confirm you've got the right
                                        system, answer your questions, and learn about any special needs you might have.
                                        No worries if you need to change something later on.
                                    </Paragraph>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Container>
            </ContentContainer>

            <ContentContainer>
                <Grid container spacing={2} className={classes.footer}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes.paymentBox}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3} md={3} container justify={'center'}>
                                    <Avatar src={images.paymentCreditCard} className={classes.homeAvatar} />
                                </Grid>
                                <Grid container item xs={12} sm={9} md={9} spacing={2}>
                                    <Grid item xs={12}>
                                        <Heading
                                            align={isXs ? 'center' : 'left'}
                                            variant={'h3'}
                                            color={'primary'}
                                            label={'Schedule your home visit.'}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paragraph align={isXs ? 'center' : 'left'}>
                                            You won't be charged or have a hold on your credit card.
                                            Let's get your pre-installation inspection scheduled.
                                        </Paragraph>
                                    </Grid>
                                    <Hidden xsDown>
                                        <Grid item xs={12} />
                                    </Hidden>
                                    <Grid item xs={12}>
                                        <Button
                                            color={'primary'}
                                            onClick={onSelectCreditCard}
                                            id={'credit-card-submit-btn'}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes.paymentBox}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3} md={3} container justify={'center'}>
                                    <Avatar src={images.paymentFinancing} className={classes.homeAvatar} />
                                </Grid>
                                <Grid container item xs={12} sm={9} md={9} spacing={2}>
                                    <Grid item xs={12}>
                                        <Heading
                                            align={isXs ? 'center' : 'left'}
                                            variant={'h3'}
                                            color={'primary'}
                                            label={'Prefer to pay over time?'}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paragraph align={isXs ? 'center' : 'left'}>
                                            Apply online with no impact to your credit score and an immediate decision.
                                        </Paragraph>
                                    </Grid>
                                    <Hidden xsDown>
                                        <Grid item xs={12} />
                                    </Hidden>
                                    <Grid item xs={12}>
                                        <Button
                                            color={'primary'}
                                            onClick={onSelectFinancing}
                                            id={'mosaic-card-submit-btn'}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </ContentContainer>

            <ContentContainer disableGrid background={'bg2'}>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer>
                        <Grid container className={classes.selectedText} justify={isXs ? 'center' : undefined}>
                            <Heading variant={'h3'} color={'primary'} label={'Your Selection:'} />
                        </Grid>
                    </ContentContainer>

                    {cartItem && cartItem.productAttributes && (
                        <ContentContainer size={'small'}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ProductDetail
                                        id={cartItem.sku}
                                        level={cartItem.productAttributes.level}
                                        image={getProductImage(cartItem)}
                                        seer={cartItem.productAttributes.seer}
                                        description={cartItem.description}
                                        price={cartItem.price}
                                    />
                                </Grid>
                                <Grid className={classes.summary} item container>
                                    <Grid item xs={12} sm={12} md={6} />
                                    <Grid item xs={12} sm={12} md={6}>
                                        <CartSummary
                                            shipping={undefined}
                                            subTotal={summarySubtotal}
                                            taxes={taxesAverage}
                                            isEstimatedTaxes
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.divider}>
                                    <Divider />
                                </Grid>
                                <Grid item container xs={12} spacing={1}>
                                    <Grid item xs={12}>
                                        <Heading variant="h3" label={'*System Pricing, Finance Pricing, and Taxes'} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paragraph>
                                            The prices displayed are the fully installed prices. These prices include a
                                            national average 8.5% sales tax rate. Once you select a payment method and
                                            provide your complete address, your corrected local sales tax rate will be
                                            applied any changes will be reflected in the System Pricing or Finance
                                            Pricing before you proceed.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ContentContainer>
                    )}

                    <ContentContainer>
                        <Divider />
                    </ContentContainer>

                    <ContentContainer>
                        <Divider />
                    </ContentContainer>
                </Container>
            </ContentContainer>
        </div>
    );
}

export default ChoosePaymentOption;
