import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormikWizardInputs from './FormikWizardInputs';
import { Options } from './WizardInputs';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/FormLabel';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: `calc(100% + ${theme.spacing(2)}px)`,
        margin: -theme.spacing(1),
    },
    image: {
        width: 100 + theme.spacing(2),
        flex: `0 0 100px`,
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
            flex: `0 0 100%`,
            width: '100%',
        },
    },
    formControl: {
        flex: 1,
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            flex: `0 0 100%`,
            width: '100%',
        },
    },
    img: {
        width: 100,
        height: 100,
    },
    label: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary,
    },
}));

interface Props {
    id: string;
    name: string;
    label: string;
    pic: string;
    options: Options[];
}

export default function WizardSelection(props: Props) {
    const classes = useStyles();
    const { id, label, pic, ...other } = props;
    return (
        <WizardForm pic={pic}>
            <FormControl fullWidth>
                <InputLabel id={`${id}-label`} htmlFor={id} className={classes.label}>
                    {label}
                </InputLabel>
                <FormikWizardInputs id={id} {...other} />
            </FormControl>
        </WizardForm>
    );
}

interface WizardFormProps {
    pic: string;
}

export function WizardForm(props: PropsWithChildren<WizardFormProps>) {
    const classes = useStyles();
    const { pic, children } = props;
    return (
        <div className={classes.root}>
            <div className={classes.image}>
                <Avatar src={pic} className={classes.img} />
            </div>
            <div className={classes.formControl}>{children}</div>
        </div>
    );
}
