import { on, reducer } from 'ts-action';
import { Cart } from '../../models/cart';

import {
    setCart,
    createCart,
    createCartSuccess,
    createCartError,
    saveForLater,
    updateCart,
    updateCartSuccess,
    updateCartError,
    placeOrder,
    placeOrderSuccess,
    placeOrderError,
    resetCart,
} from '../actions/cartActions';
import { showProductSuccess } from '../actions/productSearchActions';
import { createContact, contactInprogress } from '../actions/hubspotActions';

const initialState: Cart = {};
export const cartReducer = reducer(
    initialState,
    on(setCart, (state, action) => ({
        ...state,
        ...action.payload,
    })),
    on(createCart, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(createCartSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
    })),
    on(saveForLater, (state, action) => ({
        ...state,
        ...action.payload,
        loading: true,
    })),
    on(createCartError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),
    on(updateCart, (state, action) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(updateCartSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
        error: null,
    })),
    on(updateCartError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),
    on(placeOrder, (state, action) => ({
        ...state,
        ...action.payload.cart,
        paymentMethod: action.payload.paymentMethod,
        loading: true,
        error: null,
    })),
    on(placeOrderSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
        error: null,
    })),
    on(placeOrderError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    })),
    on(resetCart, (state, action) => ({
        ...initialState,
    })),
    on(showProductSuccess, (state, action) => ({
        contactInformation: {
            ...state.contactInformation,
            quotedOptions: `${action.payload.products
                ?.map((p) => `${p.name} - $${p.price}`)
                .toString()
                .replace(/,/g, '\n')}`,
        },
    })),
    on(createContact, (state, action) => ({
        contactInformation: {
            ...action.payload,
        },
    })),
    on(contactInprogress, (state, action) => ({
        contactInformation: {
            ...action.payload,
        },
    }))
);

export default cartReducer;
