import React from 'react';
import { hot } from 'react-hot-loader/root';
import Router from './router';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AlertProvider } from './components/common/AlertProvider';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './themes/theme';

const App: React.FC = () => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <AlertProvider>
            <Router />
        </AlertProvider>
    </ThemeProvider>
);

export default hot(App);
