import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Heading } from '../Heading';
import ContentContainer from '../ContentContainer';
import { Paragraph } from '../Paragraph';
import _ from 'lodash';
import { ExternalLink } from 'components/Link';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
        additionalContainer: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(4),
            },
        },
        additionalLink: {},
        contentHeading: {
            paddingBottom: theme.spacing(2),
        },
    };
});

export default function ProTipAndAdditionalLinks(props: ProTipAndAdditionalLinksProps) {
    const classes = useStyles();
    return (
        <ContentContainer background={'bg2'} disableGrid className={classes.root}>
            <Container maxWidth={'lg'} disableGutters>
                <Grid item xs={12} container>
                    <Grid item sm={1} xs={1} />
                    <Grid item sm={10} xs={12} container>
                        <Grid
                            item
                            sm={props.additionalLinks ? 6 : 12}
                            xs={12}
                            container
                            direction={'column'}
                            alignItems={'flex-start'}
                            justify={'flex-start'}
                            className={classes.additionalContainer}
                        >
                            <Grid item className={classes.contentHeading}>
                                <Heading variant={'h3'} color={'primary'} label={props.proTip?.headLine} />
                            </Grid>
                            <Grid item>
                                <Paragraph align={'justify'}>{props.proTip?.paragraph}</Paragraph>
                            </Grid>
                        </Grid>
                        {props.additionalLinks && (
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                container
                                direction={'column'}
                                alignItems={'flex-start'}
                                justify={'flex-start'}
                                className={classes.additionalContainer}
                            >
                                <Grid item className={classes.contentHeading}>
                                    <Heading variant={'h3'} color={'primary'} label={props.additionalLinks?.headLine} />
                                </Grid>
                                <Grid item container direction={'column'}>
                                    {_.map(props.additionalLinks?.links, (link, index) => (
                                        <Grid
                                            item
                                            key={`addition-link-grid-${index}`}
                                            className={classes.additionalLink}
                                        >
                                            <ExternalLink
                                                key={`addition-link-${index}`}
                                                id={`addition-link-${index}`}
                                                color={'primary'}
                                                label={link.text}
                                                href={link.url}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item sm={1} xs={1} />
                </Grid>
            </Container>
        </ContentContainer>
    );
}

export interface ProTipAndAdditionalLinksProps {
    proTip?: ProTip;
    additionalLinks?: AdditionalLinks;
}

interface ProTip {
    headLine: any;
    paragraph: any;
}

interface AdditionalLinks {
    headLine: any;
    links: Link[];
}

interface Link {
    text: string;
    url: string;
}
