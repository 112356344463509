import React, { Fragment } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/warranty/warranty_story_image.png';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';

function Warranty() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(1),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
        };
    });
    const classes = useStyles();
    return (
        <SubPage
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Warranties you can count on'} />
                    </Grid>
                    <Grid item xs={12} className={classes.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Warranty'} />
                    </Grid>
                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading
                            variant={'h3'}
                            color={'primary'}
                            label={
                                'We want you to feel 100% confident in your newly installed HVAC equipment, which is why we provide a full 1-Year Labor Warranty at no charge. '
                            }
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            If the installation of the HVAC system you purchased from us proves faulty within one year,
                            we will arrange to fix, repair, or replace at our discretion any equipment installed at the
                            time of the original job. There is no additional cost to you for this warranty.
                        </Paragraph>
                    </Grid>

                    <Grid item xs={12} className={classes.contentSecondaryHeading}>
                        <Heading
                            variant={'h3'}
                            color={'primary'}
                            label={'Goodman HVAC Equipment Limited Warranties '}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Our team will automatically register your Goodman HVAC equipment to be further covered by
                            the appropriate and corresponding 10 or 20-year Extended Limited Warranty according to the
                            manufacturers' policy and procedures.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            If you purchase any additional indoor air quality products or thermostats with your system,
                            we will ensure that those items are installed according to the manufacturers’ guidelines so
                            that you're also covered by the appropriate manufacturers' limited product warranties.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            To view all warranty information,{' '}
                            <Link
                                href={'https://www.goodmanmfg.com/resources/hvac-learning-center/warranty'}
                                target={'_blank'}
                            >
                                {'click here'}
                            </Link>
                        </Paragraph>
                    </Grid>
                </Fragment>
            }
        />
    );
}

export default Warranty;
