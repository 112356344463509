import React, { Fragment, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Props as MenuProps, SecondaryNavigation } from '../SecondaryNavigation';
import Navigator from '../Navigator';
import Content3 from './Content3';
import Footer from 'components/Footer';
import ContentContainer from '../ContentContainer';
import { useHistory } from 'react-router-dom';
import { AppRoute } from '../../models/route';
import PromotionContent from '../home/PromotionContent';

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {},
        header: {
            display: 'flex',
            height: '110px',
        },
        contentContainer: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(10),
        },
        content2Container: {},
        content3Container: {
            background: '#333333',
        },
    };
});

export default function SubPage(props: SubPageProps) {
    const classes = useStyles();
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className={classes.root}>
            <ContentContainer disableGrid background={'bg3'}>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer>
                        <Navigator color={'primary'} />
                    </ContentContainer>
                </Container>
            </ContentContainer>
            <PromotionContent />
            <ContentContainer background={'bg3'} disableGrid>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer size={'small'} className={classes.contentContainer}>
                        <Grid container spacing={3}>
                            <Grid item md={3} xs={12}>
                                {props.menu && <SecondaryNavigation {...props.menu} />}
                            </Grid>
                            <Grid item md={7} xs={12} container>
                                {props.content1}
                            </Grid>
                            <Grid item md={2} xs={12} />
                        </Grid>
                    </ContentContainer>
                </Container>
            </ContentContainer>
            {props.content2}
            <ContentContainer background={'bg1'} disableGrid>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer>
                        <Content3
                            title={
                                "We've made it simple for you to get the best total installed price available and it only takes few minutes!"
                            }
                            detail={<Fragment />}
                            buttonLabel={'Get started'}
                            buttonOnClick={() => {
                                history.push(AppRoute.AboutYourHome);
                            }}
                        />
                    </ContentContainer>
                </Container>
            </ContentContainer>
            <Footer />
        </div>
    );
}

export interface SubPageProps {
    menu?: MenuProps;
    content1: any;
    content2?: any;
}
