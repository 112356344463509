import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ContentContainer from '../../components/ContentContainer';
import Container from '@material-ui/core/Container';
import WizardHeader from '../../components/WizardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Heading } from '../../components/Heading';
import { Paragraph } from '../../components/Paragraph';
import { Product } from '../../models/product';
import { ProductCriteria } from '../../models/productCriteria';
import ProductDetail from '../../components/ProductDetail';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import { getProductImage } from '../../themes/theme';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    footer: {
        paddingBottom: theme.spacing(3),
    },
    salesTaxDesc: {
        paddingTop: theme.spacing(3),
    },
    divider: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    dividerFinalMobile: {
        paddingTop: theme.spacing(4),
    },
    dividerFinal: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    productList: {
        paddingTop: theme.spacing(5),
    },
    noProductList: {
        textAlign: 'center',
        minHeight: '100vh',
    },
}));

interface Props {
    loading: boolean;
    products?: Product[];
    criteria: ProductCriteria;
    onSelectProduct: (product: Product) => void;
}

function EquipmentOptions(props: Props) {
    const { products, loading } = props;
    const classes = useStyles();

    return (
        <div>
            <LoadingOverlay open={loading} />
            <WizardHeader />
            <Divider />
            <ContentContainer disableGrid background={'bg3'}>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer className={classes.header}>
                        <Grid container>
                            {products && products.length > 0 && (
                                <Grid item xs={12} md={8} container spacing={2}>
                                    <Grid item xs={12}>
                                        <Heading
                                            variant={'h2'}
                                            label={
                                                'Based on your answers, we recommend a Goodman system for your home.'
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paragraph>
                                            Goodman brand systems are{' '}
                                            <Link
                                                href={'https://www.goodmanmfg.com/resources/customer-reviews'}
                                                target={'_blank'}
                                            >
                                                {'highly rated by consumers'}
                                            </Link>
                                            &nbsp;and proudly made in the USA.
                                        </Paragraph>

                                        <Paragraph>
                                            <b>
                                                All prices INCLUDE expert installation, 10 Year Limited System Warranty,
                                                1 Year Full Labor Warranty, taxes, and our 100% Homeowner Satisfaction
                                                Pledge. Our expert HVAC installers will confirm your selection is the
                                                best fit for your home prior to final purchase and installation.{' '}
                                            </b>
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            )}
                            {(!products || !products.length) && (
                                <Grid item xs={12} container spacing={2} className={classes.noProductList}>
                                    <Grid item xs={12}>
                                        <Heading
                                            variant={'h2'}
                                            label={'Could not find any products based on your requirements.'}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </ContentContainer>
                </Container>
            </ContentContainer>
            {products && products.length > 0 && (
                <ContentContainer disableGrid background={'bg2'}>
                    <Container maxWidth={'lg'} disableGutters>
                        <ContentContainer>
                            <Grid className={classes.productList} container>
                                {products?.map((product, index) => (
                                    <Grid key={index} item xs={12} container direction={'column'}>
                                        <ProductDetail
                                            id={product.sku}
                                            level={product.productAttributes.level}
                                            image={getProductImage(product)}
                                            seer={product.productAttributes.seer}
                                            description={product.description}
                                            price={product.price}
                                            onSelect={() => {
                                                props.onSelectProduct(product);
                                            }}
                                        />
                                        {products?.length !== index + 1 && (
                                            <Grid item className={classes.divider}>
                                                <Divider />
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                                <Grid item xs={12} container spacing={1} className={classes.salesTaxDesc}>
                                    <Grid item xs={12}>
                                        <Heading variant="h3" label={'*System Pricing, Finance Pricing, and Taxes'} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paragraph>
                                            The prices displayed are the fully installed prices. These prices include a
                                            national average 8.5% sales tax rate. Once you select a payment method and
                                            provide your complete address, your corrected local sales tax rate will be
                                            applied any changes will be reflected in the System Pricing or Finance
                                            Pricing before you proceed.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.dividerFinal}>
                                    <Divider />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} className={classes.footer}>
                                    <Paragraph italic id={'wizard-tips'}>
                                        Let's not forget our 100% Homeowner Satisfaction Pledge. Our pledge to you is
                                        that you will be 100% satisfied with the installation and operation of your new
                                        HVAC system or we'll make it right! We're able to make that pledge because; We
                                        first take the guesswork and hassle out of selecting and scheduling your new
                                        HVAC system! Next, we back that up with some of the best warranties, HVAC
                                        contractors, and products in the industry! And finally, Homeowners really
                                        appreciate our simple online payment methods, knowing you pay only when the work
                                        is done! Now let's get started selecting the right HVAC system for your home so
                                        you too can be our next 100% Satisfied Homeowner, it's our Pledge to you.
                                    </Paragraph>
                                </Grid>
                            </Grid>
                        </ContentContainer>
                    </Container>
                </ContentContainer>
            )}
        </div>
    );
}

export default EquipmentOptions;
