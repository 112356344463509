import { createMuiTheme, Theme } from '@material-ui/core/styles';
import config from '../config';
import { ThemeImages } from '../models/ThemeImages';
import { Product } from '../models/product';
import { CartItem } from '../models/cart';
import packageSystem from 'assets/equipments/goodman_package_system.png';
import packageSystem1 from 'assets/equipments/goodman_package_system_1.png';
import splitFurnace from 'assets/equipments/goodman_split_furnace.png';
import splitHeatPump from 'assets/equipments/goodman_split_heat_pump.png';

export interface ThemeWithImages extends Theme {
    images: ThemeImages;
}

// A custom theme for this app
export const theme = createMuiTheme(config.theme, { images: config.images });

export function getProductImage(product: Product | CartItem): string {
    if (/goodman_package_system_1/.test(product.image)) {
        return packageSystem1;
    }
    if (/goodman_package_system/.test(product.image)) {
        return packageSystem;
    }
    if (/goodman_split_furnace/.test(product.image)) {
        return splitFurnace;
    }
    if (/goodman_split_heat_pump/.test(product.image)) {
        return splitHeatPump;
    }
    return product.image;
}
