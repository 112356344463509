import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';
import WizardHomeInfo from './WizardHomeInfo';
import { nextHomeInfo, resetProductCriteria, setProductCriteria } from '../../store/actions/productCriteriaActions';
import { AppRoute } from '../../models/route';

function AboutYourHome() {
    const history = useHistory();
    const dispatch = useDispatch();
    const criteria = useSelector(selectProductCriteriaState);
    return (
        <WizardHomeInfo
            criteria={criteria}
            onNext={(payload) => {
                dispatch(setProductCriteria(payload));
                dispatch(nextHomeInfo());
            }}
            onExit={() => {
                dispatch(resetProductCriteria());
                history.push(AppRoute.Home);
            }}
        />
    );
}

export default AboutYourHome;
