import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Button } from 'components/Button';
import { Link } from 'components/Link';
import MenuDrawer from 'components/MenuDrawer';
import { AppRoute } from 'models/route';
import { LogoWithLink } from './Logo';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            width: '100%',
            height: theme.spacing(8),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    }),
    { name: 'nav' }
);

interface Props {
    color?: 'primary' | 'secondary';
}

export default function Navigator(props: Props) {
    const classes = useStyles();
    const history = useHistory();
    const path = history.location.pathname;
    const { color = 'primary' } = props;
    return (
        <nav className={classes.root}>
            <Grid container spacing={3} alignItems={'center'}>
                <Grid item xs={6} sm={2} md={1}>
                    <LogoWithLink id={'nav-home'} color={color} />
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={6} sm={10} container alignItems={'center'} justify={'flex-end'}>
                        <MenuDrawer color={color} />
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item md={8}>
                        <Grid container spacing={4} alignItems={'center'}>
                            <Grid item>
                                <Link
                                    id={'nav-link-financing'}
                                    href={AppRoute.Financing}
                                    label={'Financing Made Easy'}
                                    color={color}
                                    active={[AppRoute.Financing as string].includes(path)}
                                />
                            </Grid>
                            <Grid item>
                                <Link
                                    id={'nav-link-who-we-are'}
                                    href={AppRoute.WhoWeAre}
                                    label={'Who We Are'}
                                    color={color}
                                    active={[AppRoute.WhoWeAre as string].includes(path)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3}>
                        <Grid container spacing={4} justify={'flex-end'} alignItems={'center'}>
                            <Grid item>
                                <Button
                                    id={'nav-get-started-btn'}
                                    color={'primary'}
                                    onClick={() => {
                                        history.push(AppRoute.AboutYourHome);
                                    }}
                                >
                                    Let's Get Started
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
            </Grid>
        </nav>
    );
}
