import { on, reducer } from 'ts-action';

import {
    getMosaic,
    getMosaicError,
    getMosaicSuccess,
    getBrainTreeError,
    getBrainTreeSuccess,
    getBrainTree,
    resetFinancial,
} from '../actions/financialActions';
import { Financial } from '../../models/financial';

interface FinancialState extends Financial {
    loading: boolean;
    error?: Error;
    cartId?: string;
}

const initialState: FinancialState = {
    loading: false,
};

export const financialReducer = reducer(
    initialState,
    on(getBrainTree, (state, action) => ({
        ...state,
        loading: true,
        mosaic: {},
        cartId: action.payload.cartId,
    })),
    on(getBrainTreeSuccess, (state, action) => ({
        ...state,
        brainTree: action.payload,
        loading: false,
    })),
    on(getBrainTreeError, (state, action) => ({
        ...state,
        brainTree: {},
        error: action.payload,
        loading: false,
    })),
    on(getMosaic, (state, action) => ({
        ...state,
        loading: true,
        brainTree: {},
        cartId: action.payload.cartId,
    })),
    on(getMosaicSuccess, (state, action) => ({
        ...state,
        mosaic: action.payload,
        loading: false,
    })),
    on(getMosaicError, (state, action) => ({
        ...state,
        mosaic: {},
        error: action.payload,
        loading: false,
    })),
    on(resetFinancial, (state, action) => ({
        ...initialState,
    }))
);

export default financialReducer;
