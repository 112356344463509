import { on, reducer } from 'ts-action';
import {
    resetProductSearch,
    searchProduct,
    searchProductError,
    searchProductSuccess,
} from '../actions/productSearchActions';
import { ProductSearch } from '../../models/productSearch';

export interface ProductSearchState extends ProductSearch {
    loading: boolean;
    error: Error | null;
}

const initialState: ProductSearchState = {
    loading: false,
    error: null,
};

export const productSearchReducer = reducer(
    initialState,
    on(searchProduct, (state, action) => ({
        ...state,
        error: null,
        products: [],
        loading: true,
    })),
    on(searchProductSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
    })),
    on(searchProductError, (state, action) => ({
        ...initialState,
        loading: false,
        error: action.payload,
    })),
    on(resetProductSearch, () => ({
        ...initialState,
    }))
);

export default productSearchReducer;
