import React, { PropsWithChildren } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Paragraph } from '../../components/Paragraph';
import Container from '@material-ui/core/Container';
import ContentContainer from '../../components/ContentContainer';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default function WizardTips(props: PropsWithChildren<Props>) {
    const classes = useStyles();
    return (
        <ContentContainer size={'wizardtip'} background={'wizardtip'} disableGrid>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer>
                    <div className={classes.root}>
                        <Paragraph id={'wizard-tips'} color={'primary'} size={'small'} italic align={'center'}>
                            {props.children}
                        </Paragraph>
                    </div>
                </ContentContainer>
            </Container>
        </ContentContainer>
    );
}
