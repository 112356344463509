import { EMPTY, Observable } from 'rxjs';
import { Action } from 'ts-action';
import { ignoreElements, tap, map, withLatestFrom, catchError } from 'rxjs/operators';
import { ofType } from 'ts-action-operators';
import { setProductCriteria, resetProductCriteria } from '../actions/productCriteriaActions';
import { selectProductCriteriaState } from '../selectors/productCriteria';
import { selectCart } from '../selectors/cart';
import { createCart } from '../actions/cartActions';
import { RootState } from '../reducers';

export const acquireChat$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(setProductCriteria, resetProductCriteria, createCart),
        withLatestFrom(state$.pipe(map(selectProductCriteriaState)), state$.pipe(map(selectCart))),
        tap(([_, productCriteria, cart]) => {
            if (window.acquire) {
                const productCriteriaSelected = JSON.stringify(productCriteria);
                const cartInfo =
                    cart && cart.contactInformation
                        ? {
                              name: `${cart.contactInformation.firstname} ${cart.contactInformation.lastname}`,
                              phone: cart.contactInformation.phone,
                              email: cart.contactInformation.email,
                          }
                        : {};
                window.acquire.push({
                    userDetails: {
                        remarks: `criteria selected : ${productCriteriaSelected}`,
                        ...cartInfo,
                    },
                });
            }
        }),
        catchError((error) => {
            console.error(error);
            return EMPTY;
        }),
        ignoreElements()
    );
