import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from './Link';
import ArrowUp from 'components/icons/ArrowUp';
import ArrowDown from 'components/icons/ArrowDown';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& .MuiCollapse-wrapperInner': {
            '& $linkItem:last-child': {
                borderBottomWidth: 0,
            },
        },
    },
    menuToggle: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '1px',
    },
    menuToggleText: {
        margin: 0,
    },
    linkItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRadius: 0,
    },
    noBg: {
        backgroundColor: 'inherit',
        '&:hover': {
            backgroundColor: 'inherit',
        },
        '&.Mui-selected': {
            backgroundColor: 'inherit',
            '&:hover': {
                backgroundColor: 'inherit',
            },
        },
    },
    menuItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '1px',
        borderTopWidth: 0,
    },
}));

export interface Menu {
    id: string;
    onClick?: () => void;
    href?: string;
    color: 'primary' | 'secondary';
    selected?: boolean;
    label: string;
}

export interface Props extends Menu {
    subMenus: Menu[];
}

export function SecondaryNavigation(props: Props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [collapseTimeout, setCollapseTimeout] = React.useState<number | 'auto'>(0);
    const { subMenus = [] } = props;
    const handleClick = () => {
        if (subMenus.length > 0) {
            setCollapseTimeout('auto');
            setOpen(!open);
        } else if (props.onClick) {
            props.onClick();
        }
    };
    return (
        <List component="nav" className={classes.root}>
            {isMobile && (
                <ListItem
                    className={clsx(classes.menuToggle, classes.noBg)}
                    selected={props.selected}
                    id={props.id}
                    button
                    onClick={() => handleClick()}
                >
                    <ListItemText
                        secondary={<Link label={props.label} color={'primary'} />}
                        className={classes.menuToggleText}
                    />
                    {isMobile && subMenus.length > 0 && (open ? <ArrowUp /> : <ArrowDown />)}
                </ListItem>
            )}
            {subMenus.length > 0 && (
                <Collapse in={isMobile ? open : true} timeout={collapseTimeout} unmountOnExit>
                    {subMenus.map((menu, index) => (
                        <ListItem
                            key={`menu-item-${index}`}
                            className={clsx({
                                [classes.menuItem]: isMobile,
                                [classes.linkItem]: !isMobile,
                                [classes.noBg]: true,
                            })}
                            selected={menu.selected}
                            id={menu.id}
                            button
                            onClick={() => {
                                history.push(menu.href ? menu.href : '#');
                            }}
                        >
                            <Link
                                key={`menu-item-link-${index}`}
                                href={menu.href}
                                label={menu.label}
                                color={'primary'}
                                active={menu.selected}
                            />
                        </ListItem>
                    ))}
                </Collapse>
            )}
        </List>
    );
}
