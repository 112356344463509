import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EquipmentOptions from './EquipmentOptions';
import { Cart, CartItem } from '../../models/cart';
import { selectCart, selectCartProcessing } from '../../store/selectors/cart';
import { createCart, setCart } from '../../store/actions/cartActions';
import { contactInprogress } from '../../store/actions/hubspotActions';
import { selectProducts } from '../../store/selectors/productSearch';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';

function EquipmentOptionContainer() {
    const dispatch = useDispatch();
    const criteria = useSelector(selectProductCriteriaState);
    const products = useSelector(selectProducts);
    const loading = useSelector(selectCartProcessing);
    const cart: Cart = useSelector(selectCart);

    return (
        <EquipmentOptions
            products={products}
            criteria={criteria}
            loading={loading}
            onSelectProduct={(product) => {
                const item: CartItem = {
                    categories: product.categories || [],
                    name: product.name,
                    sku: product.sku,
                    quantity: 1,
                    id: product.id,
                    description: product.description,
                    image: product.image,
                    price: product.price,
                    thumbnail: product.thumbnail,
                    totalPrice: product.price,
                };
                const contact = { ...cart.contactInformation, selectedPackage: `${product.name} - $${product.price}` };
                dispatch(contactInprogress(contact));
                dispatch(setCart({ ...cart, items: [item], contactInformation: contact }));
                dispatch(createCart());
            }}
        />
    );
}

export default EquipmentOptionContainer;
