import { Observable, of } from 'rxjs';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import {
    searchProduct,
    searchProductError,
    searchProductSuccess,
    showProduct,
    showProductSuccess,
} from '../actions/productSearchActions';
import { catchError, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { searchProduct as searchProductService } from '../services/productSevices';
import { RootState } from '../reducers';
import { selectProductCriteriaState } from '../selectors/productCriteria';
import { Dependencies } from '../index';
import { AppRoute } from '../../models/route';
import { FurnaceEfficiency, HomeSystem, SingleOrMultiple } from '../../models/productCriteria';
import { selectProductAttributeEfficiency } from '../selectors/productAttributes';
import { selectProductSearchState } from '../selectors/productSearch';
import { navigateThankyouPage } from '../actions/navigateActions';
import { combineEpics } from 'redux-observable';
import { orderBy } from 'lodash';

const order = ['Platinum', 'Gold', 'Silver', 'Bronze'];

export const productSearch$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert, history }: Dependencies
) =>
    action$.pipe(
        ofType(searchProduct),
        withLatestFrom(
            state$.pipe(map(selectProductCriteriaState)),
            state$.pipe(map(selectProductAttributeEfficiency))
        ),
        map(([_, criteria, efficiencyAttr]) => {
            const { splitLocation, packageLocation, efficiency, ...other } = criteria;
            const location = criteria.homeSystem === HomeSystem.Split ? splitLocation : packageLocation;
            let alterEfficiency = efficiency;
            if (efficiency && efficiencyAttr?.options.length) {
                if (efficiency === FurnaceEfficiency.Percent80) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o) => +o.label <= 80)
                        .map((o) => o.label)
                        .join();
                }
                if (efficiency === FurnaceEfficiency.Percent90) {
                    alterEfficiency = efficiencyAttr.options
                        .filter((o) => +o.label >= 90)
                        .map((o) => o.label)
                        .join();
                }
            }
            return {
                ...other,
                location,
                efficiency: alterEfficiency,
            };
        }),
        switchMap((criteria) =>
            searchProductService(criteria).pipe(
                map((resp) => resp.data || []),
                map((products) =>
                    searchProductSuccess({
                        products: orderBy(
                            products
                                .map((p) => ({ ...p, id: p.id ? p.id : +p.extId }))
                                .sort((a, b) => {
                                    return (
                                        order.findIndex((o) => o === a.productAttributes.level) -
                                        order.findIndex((o) => o === b.productAttributes.level)
                                    );
                                }),
                            ['price'],
                            ['asc']
                        ),
                    })
                ),
                catchError((error) => {
                    alert.error('Unable to load product base on you criteria');
                    return of(searchProductError(error));
                })
            )
        )
    );

export const processContact$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(showProduct),
        withLatestFrom(state$.pipe(map(selectProductCriteriaState)), state$.pipe(map(selectProductSearchState))),
        map(([_, criteria, productSearch]) => {
            if (
                criteria.singleOrMultiple === SingleOrMultiple.Multiple ||
                !productSearch.products ||
                productSearch.products.length < 1
            ) {
                return navigateThankyouPage();
            } else {
                return showProductSuccess(productSearch);
            }
        })
    );

export const navigateToEmailCapture$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert, history }: Dependencies
) =>
    action$.pipe(
        ofType(searchProductSuccess),
        tap(() => history.push(AppRoute.EmailNameCapture)),
        ignoreElements()
    );

export const navigateToEquipmentOptions$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert, history }: Dependencies
) =>
    action$.pipe(
        ofType(showProductSuccess),
        tap(() => history.push(AppRoute.EquipmentOptions)),
        ignoreElements()
    );

export const navigateToThankyou$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { alert, history }: Dependencies
) =>
    action$.pipe(
        ofType(navigateThankyouPage),
        tap(() => history.push(AppRoute.Thankyou)),
        ignoreElements()
    );

export default combineEpics(
    productSearch$,
    processContact$,
    navigateToEmailCapture$,
    navigateToEquipmentOptions$,
    navigateToThankyou$
);
