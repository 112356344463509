import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles(
    (theme: Theme) => ({
        background1: {
            backgroundColor: theme.palette.primary.light,
        },
        background2: {
            backgroundColor: theme.palette.secondary.dark,
        },
        background3: {
            backgroundColor: theme.palette.secondary.light,
        },
        wizardTipBackground: {
            backgroundColor: theme.palette.info.light,
        },
        footerBackground: {
            backgroundColor: theme.palette.primary.dark,
        },
        gridContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(8.5),
                paddingRight: theme.spacing(8.5),
            },
            [theme.breakpoints.only('sm')]: {
                paddingLeft: theme.spacing(6),
                paddingRight: theme.spacing(6),
            },
            [theme.breakpoints.down('xs')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
        },
        small: {
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
            },
            [theme.breakpoints.only('sm')]: {
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
            },
        },
        medium: {
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(10),
                paddingBottom: theme.spacing(10),
            },
            [theme.breakpoints.only('sm')]: {
                paddingTop: theme.spacing(10),
                paddingBottom: theme.spacing(10),
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5),
            },
        },
        large: {
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(15),
                paddingBottom: theme.spacing(15),
            },
            [theme.breakpoints.only('sm')]: {
                paddingTop: theme.spacing(10),
                paddingBottom: theme.spacing(10),
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5),
            },
        },
        footer: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(10),
        },
        footer2: {
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5),
            },
            [theme.breakpoints.only('sm')]: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5),
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(10),
            },
        },
        wizard: {
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(10),
                paddingBottom: theme.spacing(10),
            },
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(5),
            },
        },
        wizardtip: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    }),
    { name: 'layout' }
);

interface Props {
    size?: 'small' | 'medium' | 'large' | 'footer' | 'footer2' | 'wizard' | 'wizardtip';
    disableGrid?: boolean;
    background?: 'bg1' | 'bg2' | 'bg3' | 'wizardtip' | 'footer';
    className?: string;
}

export default function ContentContainer(props: PropsWithChildren<Props>) {
    const classes = useStyles();
    const { size, disableGrid = false, background, className } = props;
    return (
        <div
            className={clsx(
                {
                    [classes.gridContainer]: !disableGrid,
                    [classes.small]: size === 'small',
                    [classes.medium]: size === 'medium',
                    [classes.large]: size === 'large',
                    [classes.footer]: size === 'footer',
                    [classes.footer2]: size === 'footer2',
                    [classes.wizard]: size === 'wizard',
                    [classes.wizardtip]: size === 'wizardtip',
                    [classes.background1]: background === 'bg1',
                    [classes.background2]: background === 'bg2',
                    [classes.background3]: background === 'bg3',
                    [classes.wizardTipBackground]: background === 'wizardtip',
                    [classes.footerBackground]: background === 'footer',
                },
                className
            )}
        >
            {props.children}
        </div>
    );
}
