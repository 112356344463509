import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import SubPageInteractive from 'components/subpage/SubPageInteractive';
import ProTipAndAdditionalLinks from 'components/subpage/ProTipAndAdditionalLinks';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/knowledges/kc_air-conditioners_story_image.png';
import { getSubPageMenus } from './menu-util';
import VideoOverlay from '../../components/VideoOverlay';

function AirConditioners() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(5),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            contentInteractive: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(2),
            },
        };
    });

    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('air_conditioners'),
    };
    const classes = useStyles();
    const [openVdo, setOpenVdo] = React.useState(false);
    const handleCloseVdo = () => {
        setOpenVdo(false);
    };
    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Air Conditioners'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph align={'justify'}>
                            The best air conditioner is the one you don’t have to think about. It comes on the moment
                            the indoor temperature set on the thermostat requires cooling, and then runs quietly and
                            efficiently as needed. But when it’s time to upgrade or replace your system, it’s helpful to
                            understand how an air conditioning system works. Did you know that your air conditioning
                            system typically consists of two pieces of equipment?
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} container className={classes.contentInteractive}>
                        <SubPageInteractive
                            id={'sub-page-01'}
                            title={'Watch this video to learn how an air conditioning system keeps you comfortable'}
                            buttonLabel={'Watch'}
                            onClick={() => {
                                setOpenVdo(true);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Air Conditioners'} />
                    </Grid>
                    <VideoOverlay
                        open={openVdo}
                        onClose={handleCloseVdo}
                        heading={'Air Conditioners'}
                        src={
                            'https://res.cloudinary.com/motili/video/upload/v1594975953/ecomm/video/Goodman_HowItWorks_AC_Clean_1080p.mp4'
                        }
                    />
                </Fragment>
            }
            content2={
                <ProTipAndAdditionalLinks
                    proTip={{
                        headLine: 'Pro Tip',
                        paragraph: (
                            <Fragment>
                                SEER ratings of new systems we install start at 14 SEER and go up from there. The higher
                                the SEER rating, the less energy the unit uses, which means you save money on your
                                electricity bill.
                            </Fragment>
                        ),
                    }}
                />
            }
        />
    );
}

export default AirConditioners;
