import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            textAlign: 'center',
            paddingBottom: theme.spacing(15),
            [theme.breakpoints.down('sm')]: {
                paddingBottom: theme.spacing(10),
            },
            [theme.breakpoints.down('xs')]: {
                paddingBottom: theme.spacing(5),
            },
        },
        title: {
            paddingTop: theme.spacing(15),
            paddingBottom: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(5),
            },
        },
        detail: {
            paddingBottom: theme.spacing(5),
            [theme.breakpoints.down('xs')]: {
                paddingBottom: theme.spacing(3),
            },
        },
    };
});

export default function Content3(props: Content3Props) {
    const classes = useStyles();
    const componentId = props.id ? props.id : 'content-3';
    return (
        <Grid id={componentId} container className={classes.root} direction={'column'}>
            <Grid item xs={12} className={classes.title}>
                <Heading id={`${componentId}-title`} color={'secondary'} label={props.title} variant={'h2'} />
            </Grid>
            <Grid item xs={12} className={classes.detail}>
                <Paragraph id={`${componentId}-detail`} color={'secondary'}>
                    {props.detail}
                </Paragraph>
            </Grid>
            <Grid item xs={12} container justify={'center'}>
                <Button color={'primary'} id={`${componentId}-btn`} onClick={props.buttonOnClick}>
                    {props.buttonLabel}
                </Button>
            </Grid>
        </Grid>
    );
}

interface Content3Props {
    id?: string;
    title: string;
    detail: any;
    buttonLabel: string;
    buttonOnClick: () => void;
}
