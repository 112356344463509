import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function SelectInput(props: Prop) {
    const classes = useStyles();
    const { id, label, value, options, helperText, error, enableErrorDisplay, onSelect, fullWidth, ...other } = props;
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (options?.length > 0) {
            const selectedItem = options.find((item: any) => item.id === event.target.value);
            if (selectedItem) {
                onSelect(selectedItem);
            }
        }
    };
    return (
        <FormControl
            variant={'outlined'}
            className={classes.formControl}
            fullWidth={fullWidth}
            error={enableErrorDisplay && error}
        >
            {label && (
                <FormLabel id={`${id}-select-filled-label`} className={classes.label}>
                    {label}
                </FormLabel>
            )}
            <Select
                id={`${id}-select-filled`}
                value={value?.id || ''}
                fullWidth={fullWidth}
                onChange={handleChange}
                className={classes.select}
                MenuProps={{
                    className: classes.menu,
                    id: `${id}-select-menu`,
                    MenuListProps: { id: `${id}-select-list` },
                }}
                {...other}
            >
                {' '}
                {options?.length > 0 &&
                    options.map((item: any, index: number) => {
                        return (
                            <MenuItem key={index} value={item.id}>
                                {item.display}
                            </MenuItem>
                        );
                    })}
            </Select>
            {enableErrorDisplay && (
                <FormHelperText id={`${id}-helper`} className={classes.helperText}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: 100,
        },
        label: {
            marginBottom: theme.spacing(1),
            color: theme.palette.text.primary,
            '&.Mui-focused': {
                color: theme.palette.text.primary,
            },
            '&.Mui-error': {
                color: theme.palette.error.main,
            },
        },
        select: {
            borderRadius: '1px',
            height: theme.spacing(4),
            '&.MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: theme.palette.text.primary,
                },
                '& fieldset': {
                    border: `1px solid ${theme.palette.divider}`,
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme.palette.text.primary,
                    borderWidth: '1px',
                },
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: 'inherit',
            },
        },
        menu: {
            '& .MuiPaper-rounded': {
                borderRadius: 1,
            },
            '& .MuiListItem-root.Mui-selected': {
                backgroundColor: theme.palette.action.selected,
            },
            '& .MuiListItem-root.Mui-selected:hover': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.action.selected,
            },
            '& .MuiListItem-button:hover': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.action.hover,
            },
        },
        helperText: {
            color: theme.palette.text.primary,
        },
    })
);

export interface Prop {
    id: string;
    options?: any;
    value?: any;
    label?: string;
    onSelect: (item: any) => void;
    error?: boolean;
    enableErrorDisplay?: boolean;
    helperText?: string;
    fullWidth?: boolean;
}
