import { Menu } from '../../components/SecondaryNavigation';
import { AppRoute } from '../../models/route';

export function getSubPageMenus(selectedMenu?: string) {
    const menus: Menu[] = [
        {
            selected: false,
            id: `how_hvac_works`,
            color: 'primary',
            label: `How HVAC Works`,
            href: AppRoute.HowHvacWorks,
        },
        {
            selected: false,
            id: 'furnaces',
            label: `Furnaces`,
            color: 'primary',
            href: AppRoute.Furnaces,
        },
        {
            selected: false,
            id: 'air_handlers_and_indoor_coils',
            label: `Air Handlers & Coils`,
            color: 'primary',
            href: AppRoute.AirHandlers,
        },
        {
            selected: false,
            id: 'air_conditioners',
            label: `Air Conditioners`,
            color: 'primary',
            href: AppRoute.AirConditioners,
        },
        {
            selected: true,
            id: 'heat_pumps',
            label: `Heat Pumps`,
            color: 'primary',
            href: AppRoute.HeatPumps,
        },
        {
            selected: false,
            id: 'packaged_units',
            label: 'Packaged Units',
            color: 'primary',
            href: AppRoute.PackagedUnits,
        },
        {
            selected: false,
            id: 'our_process',
            label: `What is Our Process`,
            color: 'primary',
            href: AppRoute.WhatIsOurProcess,
        },
    ];
    return menus.map((m) => ({
        ...m,
        selected: m.id === selectedMenu,
    }));
}
