import React, { Fragment } from 'react';
import { Props as MenuProps } from 'components/SecondaryNavigation';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubPage from 'components/subpage/SubPage';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import ProTipAndAdditionalLinks from 'components/subpage/ProTipAndAdditionalLinks';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import Image from 'assets/images/knowledges/kc_air-handlers_story_image.png';
import { getSubPageMenus } from './menu-util';

function AirHandlers() {
    const useStyles = makeStyles((theme: Theme) => {
        return {
            contentHeading: {
                paddingBottom: theme.spacing(5),
            },
            contentImage: {
                paddingTop: theme.spacing(2),
            },
            contentInteractive: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            contentSecondaryHeading: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(2),
            },
            content: {
                paddingTop: theme.spacing(2),
            },
        };
    });
    const menu: MenuProps = {
        id: 'menu',
        label: 'More Knowledge Center Topic',
        color: 'primary',
        subMenus: getSubPageMenus('air_handlers_and_indoor_coils'),
    };
    const classes = useStyles();

    return (
        <SubPage
            menu={menu}
            content1={
                <Fragment>
                    <Grid item xs={12} className={classes.contentHeading}>
                        <Heading variant={'h1'} color={'primary'} label={'Air Handlers & Coils'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paragraph align={'justify'}>
                            What do FedEx and air handlers have in common? They both deliver!
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Your air handler assists in regulating the circulation of indoor air and the temperature of
                            the air in your home. Your air handler consists of an evaporator coil, blower motor, air
                            filter and electronic components required to deliver enhanced levels of indoor comfort.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                        <Paragraph align={'justify'}>
                            Depending on the design of your home, an air handler may be a primary indoor component of
                            your heat pump or air conditioning system.
                        </Paragraph>
                    </Grid>
                    <Grid item xs={12} className={classes.contentImage}>
                        <CardMedia component={'img'} image={Image} title={'Air Handlers'} />
                    </Grid>
                </Fragment>
            }
            content2={
                <ProTipAndAdditionalLinks
                    proTip={{
                        headLine: 'Pro Tip',
                        paragraph: (
                            <Fragment>
                                Residential HVAC systems are sized from 1.5 to 5 tons. Our online selection tool takes a
                                lot of the guess work out, but we still have local technicians complete a
                                pre-installation inspection to confirm the right size HVAC system for your home.{' '}
                            </Fragment>
                        ),
                    }}
                    additionalLinks={{
                        headLine: 'Want to learn more?  Check out these links:',
                        links: [
                            {
                                text: 'What is Variable Speed HVAC Technology?',
                                url:
                                    'https://www.goodmanmfg.com/resources/hvac-learning-center/hvac-101/what-is-variable-speed-technology',
                            },
                            {
                                text: 'Goodman Customer Reviews',
                                url: 'https://www.goodmanmfg.com/resources/customer-reviews',
                            },
                        ],
                    }}
                />
            }
        />
    );
}

export default AirHandlers;
