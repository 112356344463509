import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetProductCriteria } from '../store/actions/productCriteriaActions';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BackButton, CloseButton, RestartButton } from './WizardButton';
import ContentContainer from './ContentContainer';
import { Logo } from './Logo';
import { AppRoute } from '../models/route';
import { resetCart } from '../store/actions/cartActions';
import { resetOrder } from '../store/actions/orderActions';
import { resetGAProduct } from '../store/actions/gaEcommerceActions';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { Button } from './Button';
import { Paragraph } from './Paragraph';
import { resetFinancial } from '../store/actions/financialActions';
import { resetProductSearch } from '../store/actions/productSearchActions';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '80px',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    dialog: {
        height: '100%',
    },
    dialogContainer: {
        minHeight: '100vh',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

interface Props {
    color?: 'primary' | 'secondary';
    showBackButton?: true | false;
    showRestartButton?: true | false;
    disableGrid?: true | false;
    onBack?: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function WizardHeader(props: Props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { color = 'primary', showBackButton = false, showRestartButton = true, disableGrid = false } = props;
    const componentId = 'wizard-header';
    const [confirm, setConfirm] = React.useState(false);
    const [action, setAction] = React.useState('');
    const [confirmMsg, setConfirmMsg] = React.useState('');
    return (
        <ContentContainer disableGrid background={'bg3'} className={classes.root}>
            <Container maxWidth={'lg'} disableGutters>
                <ContentContainer disableGrid={disableGrid}>
                    <Grid container spacing={2} alignItems={'center'}>
                        <Grid item xs={4} sm={2} md={1}>
                            <Logo id={'nav-logo'} color={color} />
                        </Grid>
                        <Grid item xs={8} sm={10} md={11}>
                            <Grid container justify={'flex-end'} alignContent={'center'} spacing={2}>
                                {showBackButton && !!props.onBack && (
                                    <Grid item>
                                        <BackButton id={`${componentId}-back-btn`} onClick={props.onBack} />
                                    </Grid>
                                )}
                                {showRestartButton && (
                                    <Grid item>
                                        <RestartButton
                                            id={`${componentId}-restart-btn`}
                                            onClick={() => {
                                                setAction('restart');
                                                setConfirmMsg('Are you sure you want to restart?');
                                                setConfirm(true);
                                            }}
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <CloseButton
                                        id={`${componentId}-close-btn`}
                                        onClick={() => {
                                            setAction('close');
                                            setConfirmMsg('Are you sure you want to exit?');
                                            setConfirm(true);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Container>
            <Dialog
                fullScreen
                open={confirm}
                onClose={() => {
                    setConfirm(false);
                }}
                TransitionComponent={Transition}
            >
                <ContentContainer disableGrid background={'bg2'} className={classes.dialog}>
                    <Grid
                        container
                        direction={'column'}
                        alignItems={'center'}
                        justify={'center'}
                        spacing={2}
                        className={classes.dialogContainer}
                    >
                        <Grid item xs={12}>
                            <Paragraph align={'center'}>{confirmMsg}</Paragraph>
                            <Paragraph align={'center'}>Your progress will be lost.</Paragraph>
                        </Grid>
                        <Grid item xs={12} container spacing={2} justify={'center'}>
                            <Grid item>
                                <Button
                                    id={'confirm-yes-btn'}
                                    color={'secondary'}
                                    onClick={() => {
                                        if (action === 'restart') {
                                            dispatch(resetProductCriteria());
                                            dispatch(resetProductSearch());
                                            dispatch(resetCart());
                                            dispatch(resetGAProduct());
                                            dispatch(resetFinancial());
                                            dispatch(resetOrder());
                                            history.push(AppRoute.AboutYourHome);
                                        } else if (action === 'close') {
                                            dispatch(resetProductCriteria());
                                            dispatch(resetProductSearch());
                                            dispatch(resetCart());
                                            dispatch(resetGAProduct());
                                            dispatch(resetFinancial());
                                            dispatch(resetOrder());
                                            history.push(AppRoute.Home);
                                        }
                                    }}
                                >
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    id={'confirm-no-btn'}
                                    color={'primary'}
                                    onClick={() => {
                                        setConfirm(false);
                                    }}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Dialog>
        </ContentContainer>
    );
}
