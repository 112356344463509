import { combineReducers } from 'redux';
import serviceWorker from './serviceWorkerReducer';
import store from './storeReducer';
import productCriteria from './productCriteriaReducer';
import productSearch from './productSearchReducer';
import cart from './cartReducer';
import financial from './financialReducer';
import order from './orderReducer';
import country from './countryReducer';
import content from './contentReducer';
import productAttributes from './productAttributesReducer';
import gaSelectedProduct from './gaEcommerceReducer';

export const rootReducer = combineReducers({
    serviceWorker,
    store,
    country,
    productCriteria,
    productSearch,
    cart,
    financial,
    order,
    content,
    productAttributes,
    gaSelectedProduct,
});
export type RootState = ReturnType<typeof rootReducer>;
