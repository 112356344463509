import React, { MouseEventHandler, PropsWithChildren } from 'react';
import clsx from 'clsx';
import { fade, makeStyles, Theme } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            height: '40px',
            padding: theme.spacing(1, 2),
            minWidth: theme.spacing(10),
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            '&:hover': {
                background: theme.palette.info.dark,
            },
            whiteSpace: 'nowrap',
        },
        primary: {
            '&.Mui-disabled': {
                color: `${fade(theme.palette.primary.contrastText, 0.4)}`,
                backgroundColor: `${fade(theme.palette.primary.main, 0.4)}`,
            },
        },
        secondary: {
            '&.Mui-disabled': {
                color: `${fade(theme.palette.secondary.contrastText, 0.4)}`,
                backgroundColor: `${fade(theme.palette.secondary.main, 0.4)}`,
            },
        },
    }),
    { name: 'common' }
);

interface Props {
    id?: string;
    color?: 'primary' | 'secondary';
    onClick?: MouseEventHandler;
    disabled?: boolean;
}

export function Button(props: PropsWithChildren<Props>) {
    const classes = useStyles();
    const { children, color, ...other } = props;
    return (
        <MuiButton
            disableElevation
            className={clsx(classes.root, {
                [classes.primary]: color === 'primary',
                [classes.secondary]: color === 'secondary',
            })}
            variant={'contained'}
            color={color}
            {...other}
        >
            {children}
        </MuiButton>
    );
}
