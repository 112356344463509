import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button } from '../Button';
import ContentContainer from '../ContentContainer';
import { Heading } from '../Heading';

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: '0px',
                paddingRight: '0px',
            },
            width: '100%',
        },
        title: {
            [theme.breakpoints.down('xs')]: {
                paddingBottom: theme.spacing(2),
            },
        },
    };
});

export default function SubPageInteractive(props: SubPageInteractiveProps) {
    const classes = useStyles();
    return (
        <ContentContainer background={'bg2'} disableGrid className={classes.root}>
            <Grid id={props.id} item xs={12} container>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    container
                    alignItems={'center'}
                    justify={'flex-start'}
                    className={classes.title}
                >
                    <Heading id={props.id ? `${props.id}-title` : undefined} variant={'h3'} label={props.title} />
                </Grid>
                <Grid item xs={12} sm={6} container justify={'flex-end'}>
                    <Button color={'secondary'} id={props.id ? `${props.id}-bth` : undefined} onClick={props.onClick}>
                        {props.buttonLabel}
                    </Button>
                </Grid>
            </Grid>
        </ContentContainer>
    );
}

export interface SubPageInteractiveProps {
    id?: string;
    title: string;
    buttonLabel: string;
    onClick: () => void;
}
