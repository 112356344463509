import React, { Fragment } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Hidden from '@material-ui/core/Hidden';
import CardMedia from '@material-ui/core/CardMedia';

import ContentContainer from './ContentContainer';
import { Button } from './Button';

import Level from 'components/icons/Level';
import { Level as ProductLevel } from '../models/product';
import { Paragraph } from './Paragraph';
import { Heading } from './Heading';
import striptags from 'striptags';
import ArrowDown from './icons/ArrowDown';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '0px',
    },
    header: {
        paddingBottom: theme.spacing(2),
    },
    levelInfo: {
        [theme.breakpoints.only('xs')]: {
            paddingBottom: theme.spacing(1),
        },
    },
    systemPrice: {
        textAlign: 'right',
        [theme.breakpoints.only('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            paddingBottom: theme.spacing(1),
        },
    },
    systemPriceSelected: {
        textAlign: 'right',
        [theme.breakpoints.only('xs')]: {
            textAlign: 'center',
            paddingBottom: theme.spacing(1),
        },
    },
    buttonBlock: {
        textAlign: 'right',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    productDetail: {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
    },
    imageBox: {},
    img: {
        maxWidth: '340px',
        maxHeight: '170px',
        objectFit: 'contain',
    },
    panel: {
        border: `1px solid ${theme.palette.divider}`,
    },
    panelSummary: {
        padding: theme.spacing(0, 1),
        '&.MuiExpansionPanelSummary-root': {
            height: theme.spacing(4),
            minHeight: theme.spacing(4),
        },
    },
    panelDetail: {
        borderTop: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
    },
    seer: {
        paddingBottom: theme.spacing(1),
    },
    productDetailLabel: {
        paddingBottom: theme.spacing(1),
    },
}));

interface Props {
    id?: string;
    level: ProductLevel;
    price: number;
    seer: string;
    description: any;
    image: string;
    onSelect?: () => void;
    final?: boolean;
}

export default function ConfirmationProductDetail(props: Props) {
    const classes = useStyles();
    const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const currencyFormatNoDigit = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    });
    const componentId = props.id ? props.id : 'product-detail';
    const isSelected = !props.onSelect;
    const level = <Level level={props.level} />;
    const seer = (
        <Grid className={classes.seer}>
            <Paragraph id={`seer-level-${props.level}`}>SEER {props.seer}</Paragraph>
        </Grid>
    );
    const descriptionLabel = (
        <Grid className={classes.productDetailLabel}>
            <Paragraph>Product Details</Paragraph>
        </Grid>
    );
    const description = <Paragraph id={`description-level-${props.level}`}>{striptags(props.description)}</Paragraph>;

    const price = props.final ? props.price : props.price + props.price * 0.085;

    const systemPrice = <Heading variant="h2" label={currencyFormat.format(price) + (props.final ? '' : '*')} />;
    const systemPriceLabel = <Paragraph size={'small'}>System Price</Paragraph>;

    const financialPrice = (
        <Heading variant="h2" label={currencyFormatNoDigit.format(Math.ceil(price * 0.0137026)) + '/mo*'} />
    );
    const financialPriceLabel = <Paragraph size={'small'}>Instant Online Financing Decision</Paragraph>;

    const image = <CardMedia image={props.image} component={'img'} className={classes.img} />;
    const selectButton = (
        <Button id={`${componentId}-select-btn`} color={'primary'} onClick={props.onSelect}>
            Select
        </Button>
    );
    const productDetailLabel = (
        <Typography component={'label'} variant={'subtitle1'} color={'textPrimary'}>
            Product Details
        </Typography>
    );

    return (
        <ContentContainer disableGrid>
            <Container maxWidth={false} className={classes.root} id={`card-level-${props.level}`}>
                <ContentContainer disableGrid background={'bg2'}>
                    <Grid className={classes.header} container>
                        <Hidden xsDown>
                            <Grid item sm={isSelected ? 4 : 12} md={isSelected ? 6 : 4} container>
                                {level}
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid item xs={12} container className={classes.levelInfo}>
                                {level}
                            </Grid>
                        </Hidden>

                        {props.final ? (
                            <Grid item xs={12} sm={8} md={6} container>
                                <Grid
                                    item
                                    container
                                    direction={'column'}
                                    className={classes.systemPriceSelected}
                                    id={`system-price-level-${props.level}`}
                                >
                                    {systemPrice}
                                    {systemPriceLabel}
                                </Grid>
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid item xs={12} sm={4} md={3} container>
                                    <Grid
                                        item
                                        container
                                        direction={'column'}
                                        className={isSelected ? classes.systemPriceSelected : classes.systemPrice}
                                        id={`system-price-level-${props.level}`}
                                    >
                                        {systemPrice}
                                        {systemPriceLabel}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Grid
                                        container
                                        direction={'column'}
                                        className={isSelected ? classes.systemPriceSelected : classes.systemPrice}
                                        id={`financial-price-level-${props.level}`}
                                    >
                                        {financialPrice}
                                        {financialPriceLabel}
                                    </Grid>
                                </Grid>
                            </Fragment>
                        )}
                        {!isSelected && (
                            <Grid item xs={12} sm={4} md={2} className={classes.buttonBlock}>
                                {selectButton}
                            </Grid>
                        )}
                    </Grid>
                </ContentContainer>
                <Hidden smDown>
                    <ContentContainer disableGrid background={'bg3'} className={classes.productDetail}>
                        <Grid container spacing={3}>
                            <Grid item md={4} className={classes.imageBox} justify={'center'}>
                                {image}
                            </Grid>
                            <Grid item md={8}>
                                <Grid container direction={'column'}>
                                    {seer}
                                    {descriptionLabel}
                                    {description}
                                </Grid>
                            </Grid>
                        </Grid>
                    </ContentContainer>
                </Hidden>
                <Hidden mdUp>
                    <ContentContainer disableGrid background={'bg2'}>
                        <ExpansionPanel className={classes.panel} square={true} elevation={0}>
                            <ExpansionPanelSummary
                                className={classes.panelSummary}
                                expandIcon={<ArrowDown />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {productDetailLabel}
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.panelDetail}>
                                <Grid container spacing={3} justify={'center'}>
                                    <Grid item xs={12} sm={5} className={classes.imageBox}>
                                        {image}
                                    </Grid>
                                    <Grid item xs={12} sm={7}>
                                        <Grid container direction={'column'}>
                                            {seer}
                                            {descriptionLabel}
                                            {description}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </ContentContainer>
                </Hidden>
            </Container>
        </ContentContainer>
    );
}
