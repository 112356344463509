import WizardHeader from '../components/WizardHeader';
import Divider from '@material-ui/core/Divider';
import ContentContainer from '../components/ContentContainer';
import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Heading } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '../components/Button';
import { resetProductCriteria } from '../store/actions/productCriteriaActions';
import { resetProductSearch } from '../store/actions/productSearchActions';
import { resetCart } from '../store/actions/cartActions';
import { resetGAProduct } from '../store/actions/gaEcommerceActions';
import { resetFinancial } from '../store/actions/financialActions';
import { resetOrder } from '../store/actions/orderActions';
import { AppRoute } from '../models/route';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${theme.spacing(22)}px)`,
    },
    wizard: {
        minHeight: `calc(100vh - ${theme.spacing(28)}px)`,
        [theme.breakpoints.up('md')]: {
            minHeight: `calc(100vh - ${theme.spacing(22)}px)`,
        },
    },
    body: {
        justifyContent: 'center',
    },
    header: {
        textAlign: 'center',
    },
    content: {
        textAlign: 'center',
    },
    footer: {
        paddingTop: theme.spacing(5),
        justifyContent: 'center',
    },
    bottom: {
        textAlign: 'center',
    },
}));

function Thankyou() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <div className={classes.root}>
            <WizardHeader showBackButton={false} showRestartButton={false} />
            <Divider />
            <div className={classes.main}>
                <ContentContainer disableGrid background={'bg3'}>
                    <Container maxWidth={'lg'} disableGutters>
                        <ContentContainer size={'wizard'} className={classes.wizard}>
                            <Grid container className={classes.body}>
                                <Grid item xs={12} md={8} container spacing={2}>
                                    <Grid item xs={12} className={classes.header}>
                                        <Heading align={'center'} variant={'h1'} label={'Thank You'} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.content}>
                                        <Paragraph align={'center'}>
                                            Your information has successfully been submitted. We will reach out to you
                                            directly as soon as possible.
                                        </Paragraph>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.footer}>
                                <Grid item xs={12} md={8} container spacing={2}>
                                    <Grid item xs={12} className={classes.bottom}>
                                        <Button
                                            id={'thankyou-close-btn'}
                                            color={'primary'}
                                            onClick={() => {
                                                dispatch(resetProductCriteria());
                                                dispatch(resetProductSearch());
                                                dispatch(resetCart());
                                                dispatch(resetGAProduct());
                                                dispatch(resetFinancial());
                                                dispatch(resetOrder());
                                                history.push(AppRoute.Home);
                                            }}
                                        >
                                            Return to Homepage
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ContentContainer>
                    </Container>
                </ContentContainer>
            </div>
        </div>
    );
}

export default Thankyou;
