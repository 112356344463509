import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import { Button } from 'components/Button';
import Hidden from '@material-ui/core/Hidden';
import { AppRoute } from 'models/route';
import { useHistory } from 'react-router-dom';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';

const useStyles = makeStyles(
    (theme: Theme) => ({
        heading: {
            [theme.breakpoints.up('sm')]: {
                // paddingTop: theme.spacing(10),
                paddingBottom: theme.spacing(2),
            },
            [theme.breakpoints.down('xs')]: {
                // paddingTop: theme.spacing(5),
                paddingBottom: theme.spacing(2),
            },
        },
        description: {
            [theme.breakpoints.up('sm')]: {
                paddingBottom: theme.spacing(4),
            },
            [theme.breakpoints.down('xs')]: {
                paddingBottom: theme.spacing(4.9),
            },
        },
    }),
    { name: 'home-headline' }
);

function HeadLine(props: WithWidth) {
    const classes = useStyles();
    const history = useHistory();
    return (
        <div>
            <Grid container spacing={3}>
                <Hidden xsDown>
                    <Grid item sm={1} />
                </Hidden>
                <Grid item xs={12} sm={10} md={8}>
                    <Grid container direction={'column'}>
                        <Grid item className={classes.heading}>
                            <Heading
                                id={'head-line-heading'}
                                variant={'h1'}
                                color={'secondary'}
                                label={'Looking for a new HVAC system?'}
                            />
                        </Grid>
                        <Grid item className={classes.description}>
                            <Paragraph id={'head-line-paragraph'} size={'normal'} color={'secondary'}>
                                We've made it simple for you to get the best total installed price available and it only
                                takes few minutes!
                            </Paragraph>
                        </Grid>
                        <Grid item>
                            <Button
                                id={'head-line-get-started-btn'}
                                color={'primary'}
                                onClick={() => {
                                    history.push(AppRoute.AboutYourHome);
                                }}
                            >
                                Let's Get Started
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden xsDown>
                    <Grid item sm={1} md={3} />
                </Hidden>
            </Grid>
        </div>
    );
}

export default withWidth()(HeadLine);
