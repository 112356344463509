import { withStyles, Theme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';

const WizardInput = withStyles(
    (theme: Theme) => ({
        root: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.divider}`,
            minWidth: theme.spacing(10),
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            '&$selected': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.action.selected,
                '&:hover': {
                    borderColor: theme.palette.action.hover,
                    backgroundColor: theme.palette.action.selected,
                },
            },
            '&:hover': {
                borderColor: theme.palette.action.hover,
                backgroundColor: theme.palette.common.white,
            },
        },
        /* Pseudo-class applied to the root element if `selected={true}`. */
        selected: {},
    }),
    { name: 'common' }
)(ToggleButton);

export default WizardInput;
