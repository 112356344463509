import { ThemeOptions } from '@material-ui/core/styles';
import { ThemeImages } from '../models/ThemeImages';

import homeMarqueeImage from 'assets/images/t3_home_marquee_image.png';
import homeFinancingImage from 'assets/images/t3_home_financing_image.png';
import homeHow1 from 'assets/images/t3_home_how_1.png';
import homeHow2 from 'assets/images/t3_home_how_2.png';
import homeHow3 from 'assets/images/t3_home_how_3.png';
import homeHow4 from 'assets/images/t3_home_how_4.png';
import homeHow5 from 'assets/images/t3_home_how_5.png';

import wizardP1Q1 from 'assets/images/t3_wizard_p1_q1.png';
import wizardP1Q2 from 'assets/images/t3_wizard_p1_q2.png';
import wizardP1Q3 from 'assets/images/t3_wizard_p1_q3.png';
import wizardP2Q1 from 'assets/images/t3_wizard_p2_q1.png';
import wizardP2Q2 from 'assets/images/t3_wizard_p2_q2.png';
import wizardP2Q3 from 'assets/images/t3_wizard_p2_q3.png';
import wizardP2Q4 from 'assets/images/t3_wizard_p2_q4.png';
import wizardP2Q5 from 'assets/images/t3_wizard_p2_q5.png';
import wizardP2Q6 from 'assets/images/t3_wizard_p2_q6.png';
import wizardP3Q1 from 'assets/images/t3_wizard_p3_q1.png';
import confirmationStep1 from 'assets/images/t3_confirmation-step-1.png';
import confirmationStep2 from 'assets/images/t3_confirmation-step-2.png';
import confirmationStep3 from 'assets/images/t3_confirmation-step-3.png';

import paymentCreditCard from 'assets/images/t3_payment_credit-card.png';
import paymentFinancing from 'assets/images/t3_payment_financing.png';

export const images: ThemeImages = {
    homeMarqueeImage,
    homeFinancingImage,
    homeHow1,
    homeHow2,
    homeHow3,
    homeHow4,
    homeHow5,
    wizardP1Q1,
    wizardP1Q2,
    wizardP1Q3,
    wizardP2Q1,
    wizardP2Q2,
    wizardP2Q3,
    wizardP2Q4,
    wizardP2Q5,
    wizardP2Q6,
    wizardP3Q1,
    paymentCreditCard,
    paymentFinancing,
    confirmationStep1,
    confirmationStep2,
    confirmationStep3,
};

// A theme for Missouri
export const theme: ThemeOptions = {
    palette: {
        primary: {
            main: '#5D9820', // variable; Button background
            light: '#8F9197', // variable; background1
            dark: '#4F525C', // variable; footerBackground
            contrastText: '#FFFFFF', // Button Text color for contained
        },
        secondary: {
            main: '#6A6F80', // variable; Button background
            light: '#FFFFFF', // variable; background3
            dark: '#FBFAF9', // variable; background2
            contrastText: '#FFFFFF', // Button Text color for contained
        },
        info: {
            main: '#2196f3',
            dark: '#222222', // variable; Button contained hover state
            light: '#F4F8EF', // variable; Wizard tip background color
        },
        text: {
            primary: '#333333', // Text
            secondary: '#FFFFFF', // Text
        },
        divider: '#CCCCCC', // also used in border color
        action: {
            active: '#CCCCCC', // Link active/hover state
            selected: '#8F9197', // variable; Wizard button selected
            hover: '#333333', // Outlined button border color in hover state
        },
    },
    typography: {
        h1: {
            fontFamily: 'Barlow', // variable
            fontWeight: 700, // variable
            fontSize: '45px',
            lineHeight: '50px',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        h2: {
            fontFamily: 'Barlow', // variable
            fontWeight: 700, // variable
            fontSize: '32px',
            lineHeight: '37px',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        h3: {
            fontFamily: 'Barlow', // variable
            fontWeight: 700, // variable
            fontSize: '24px',
            lineHeight: '28px',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        body1: {
            // Paragraph
            fontFamily: 'Barlow', // variable
            fontWeight: 400, // variable
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: 0,
        },
        body2: {
            // Paragraph Small
            fontFamily: 'Barlow', // variable
            fontWeight: 400, // variable
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: 0,
        },
        subtitle1: {
            // Link
            fontFamily: 'Barlow', // variable
            fontWeight: 700, // variable
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        subtitle2: {
            // Footer
            fontFamily: 'Barlow', // variable
            fontWeight: 700, // variable
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: 0,
            textTransform: 'uppercase',
        },
        button: {
            // Button
            fontFamily: 'Barlow', // variable
            fontWeight: 700, // variable
            fontSize: '14px',
            lineHeight: '19px',
            textAlign: 'center',
            textTransform: 'uppercase',
        },
    },
    spacing: 10,
    shape: {
        borderRadius: 20,
    },
};

export default theme;
