import http from './http';
import { ProductCriteria } from '../../models/productCriteria';
import { Product } from '../../models/product';
import { ProductAttribute, ProductAttributeId } from '../../models/productAttributes';

export const searchProduct = (criteria: ProductCriteria) =>
    http.get<Product[]>('/products/search', {
        params: { criteria },
    });

export const getProductAttribute = (id: ProductAttributeId) =>
    http.get<ProductAttribute>('/products/customAttributes', {
        params: { id },
    });
