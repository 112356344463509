import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WizardEmailName from './WizardEmailNameCapture';
import { resetProductCriteria } from '../../store/actions/productCriteriaActions';
import { AppRoute } from '../../models/route';
import { Cart, ContactInformation } from '../../models/cart';
import { selectCart } from '../../store/selectors/cart';
import { setCart } from '../../store/actions/cartActions';
import { schemaAboutYouHome, schemaAboutYourSystem } from './wizard-schema';
import { selectProductCriteriaState } from '../../store/selectors/productCriteria';
import { selectProductSearchState } from '../../store/selectors/productSearch';
import { selectProductLoading } from '../../store/selectors/productSearch';
import { createContact } from '../../store/actions/hubspotActions';
import { SingleOrMultiple } from '../../models/productCriteria';

function EmailNameCapture() {
    const history = useHistory();
    const dispatch = useDispatch();
    const criteria = useSelector(selectProductCriteriaState);
    const productSearch = useSelector(selectProductSearchState);
    const cart: Cart = useSelector(selectCart);
    const loading = useSelector(selectProductLoading);
    if (
        !schemaAboutYouHome.isValidSync(criteria) ||
        (criteria.singleOrMultiple === SingleOrMultiple.Single && !schemaAboutYourSystem.isValidSync(criteria))
    ) {
        return <Redirect to={AppRoute.AboutYourSystem} />;
    }
    const contactInformation: ContactInformation = {
        firstname: cart.contactInformation?.firstname,
        lastname: cart.contactInformation?.lastname,
        email: cart.contactInformation?.email,
        phone: cart.contactInformation?.phone,
    };
    return (
        <WizardEmailName
            loading={loading}
            multiUnit={criteria.singleOrMultiple === SingleOrMultiple.Multiple}
            noProduct={!productSearch.products || productSearch.products.length < 1}
            customerInfo={contactInformation}
            onBack={(customerInfo) => {
                dispatch(setCart({ ...cart, contactInformation: customerInfo }));
                history.push(
                    criteria.singleOrMultiple === SingleOrMultiple.Multiple
                        ? AppRoute.AboutYourHome
                        : AppRoute.AboutYourSystem
                );
            }}
            onNext={(customerInfo) => {
                dispatch(createContact(customerInfo));
            }}
            onExit={() => {
                dispatch(resetProductCriteria());
                history.push(AppRoute.Home);
            }}
        />
    );
}

export default EmailNameCapture;
