import React from 'react';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import homeMarqueeImage from '../../assets/images/airandheatdoneright/aahdr_marquee-image.png';

import ContentContainer from 'components/ContentContainer';
import Navigator from 'components/airandheatdoneright/Navigator';
import HeadLine from 'components/airandheatdoneright/HeadLine';
import Footer from 'components/airandheatdoneright/Footer';
import NavigateSite from 'components/airandheatdoneright/NavigateSite';
import { ThemeWithImages } from '../../themes/theme';

const useStyles = makeStyles(
    (theme: ThemeWithImages) => ({
        root: {},
        marquee: {
            height: '533px',
            backgroundImage: `linear-gradient(rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.5)), url(${homeMarqueeImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        },
    }),
    { name: 'home-layout' }
);

export function Index(props: WithWidth) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.marquee}>
                <Container maxWidth={'lg'} disableGutters>
                    <ContentContainer>
                        <Navigator color={'secondary'} />
                        <ContentContainer size={'medium'} disableGrid>
                            <HeadLine />
                        </ContentContainer>
                    </ContentContainer>
                </Container>
            </div>
            <NavigateSite />
            <Footer />
        </div>
    );
}

export default withWidth()(Index);
