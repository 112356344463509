import { reducer, on } from 'ts-action';
import { getStore, getStoreSuccess, getStoreError } from '../actions/storeActions';
import { StoreConfig } from '../../models/storeConfig';
import config from '../../config';

export interface StoreState {
    storeCode?: string;
    config?: StoreConfig;
    error?: Error | null;
    loading: boolean;
}

const initialState: StoreState = {
    storeCode: config.storeCode,
    loading: false,
};

export const storeReducer = reducer(
    initialState,
    on(getStore, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(getStoreSuccess, (state, action) => ({
        ...state,
        ...action.payload,
        loading: false,
    })),
    on(getStoreError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    }))
);

export default storeReducer;
