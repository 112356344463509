import { combineEpics } from 'redux-observable';
import { Action } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, tap, ignoreElements } from 'rxjs/operators';

import { getOrder as getOrderService } from '../services/cartSevices';
import { RootState } from '../reducers';
import { Dependencies } from '../index';
import { getOrder, getOrderError, getOrderSuccess } from '../actions/orderActions';
import { AppRoute } from '../../models/route';
import { resetCart } from '../actions/cartActions';
import { resetGAProduct } from '../actions/gaEcommerceActions';
import { resetProductSearch } from '../actions/productSearchActions';
import { resetProductCriteria } from '../actions/productCriteriaActions';

export const getOrder$ = (action$: Observable<Action>, state$: Observable<RootState>, { alert }: Dependencies) =>
    action$.pipe(
        ofType(getOrder),
        switchMap((action) =>
            getOrderService(action.payload.id).pipe(
                switchMap((resp) => [
                    getOrderSuccess(resp.data),
                    resetCart(),
                    resetGAProduct(),
                    resetProductSearch(),
                    resetProductCriteria(),
                ]),
                catchError((error) => {
                    alert.error('Unable get an order');
                    return of(getOrderError(error));
                })
            )
        )
    );

export const navigateToConfirmation$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(getOrderSuccess),
        tap(() => history.push(AppRoute.Confirmation)),
        ignoreElements()
    );

export default combineEpics(getOrder$, navigateToConfirmation$);
