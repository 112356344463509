import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {},
        checkbox: {
            '&.MuiCheckbox-root': {
                color: theme.palette.text.primary,
            },
            '&.Mui-checked': {
                color: theme.palette.text.primary,
            },
        },
    }),
    { name: 'common' }
);

interface Props {
    id: string;
    label?: string;
    checked: boolean;
    onChange: () => void;
    error?: boolean;
    enableErrorDisplay?: boolean;
    helperText?: string;
    fullWidth?: boolean;
}
export function CheckBox(props: Props) {
    const classes = useStyles();
    const { id, label, checked, onChange } = props;
    return (
        <FormControlLabel
            id={`${id}-label`}
            control={
                <Checkbox
                    id={`${id}-check-box`}
                    color={'primary'}
                    checked={checked}
                    onChange={onChange}
                    className={classes.checkbox}
                />
            }
            label={label}
            labelPlacement={'end'}
        />
    );
}
