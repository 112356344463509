import { Action, isType } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { EMPTY, Observable } from 'rxjs';
import { ignoreElements, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { RootState } from '../reducers';
import config from '../../config';
import { searchProduct, showProductSuccess } from '../actions/productSearchActions';
import { placeOrder, createCartSuccess } from '../actions/cartActions';
import { Dependencies } from '../index';
import { appInitial } from '../actions/appActions';
import { ProductSearch } from '../../models/productSearch';
import { Cart, CartItem } from '../../models/cart';
import { getBrainTreeSuccess, getMosaicSuccess } from '../actions/financialActions';
import { selectCart } from '../selectors/cart';
import { selectProductCriteriaState } from '../selectors/productCriteria';
import { FurnaceOrAirHandler, HomeSystem, ProductCriteria } from '../../models/productCriteria';
import { addGAProduct, checkoutGACreditCard, checkoutGAMosaic } from '../actions/gaEcommerceActions';
import { gaSelectedProduct } from '../selectors/gaEcommerce';
import { selectProductSearchState } from '../selectors/productSearch';

const EVENT_CATEGORY_CART = 'Cart Behavior';
const EVENT_CATEGORY_SEARCH = 'Search Behavior';
export const googleAnalytic$ = (
    action$: Observable<Action>,
    state$: Observable<RootState>,
    { history }: Dependencies
) =>
    action$.pipe(
        ofType(appInitial),
        mergeMap(() => {
            if (!config.googleAnalyticId) {
                return EMPTY;
            }
            ReactGA.initialize(config.googleAnalyticId, {
                debug: true,
            });
            ReactGA.plugin.require('ec');
            ReactGA.pageview(history.location.pathname);
            if (config.googleTagManagerId) {
                TagManager.initialize({ gtmId: config.googleTagManagerId });
            }
            history.listen((location) => {
                ReactGA.pageview(location.pathname);
            });

            return action$.pipe(
                withLatestFrom(
                    state$.pipe(map(selectCart)),
                    state$.pipe(map(selectProductCriteriaState)),
                    state$.pipe(map(selectProductSearchState))
                ),
                tap(([action, cart, productCriteria, productSearch]) => {
                    // console.log(`GOOGLE TRACKING ACTION: ${action.type}`);
                    trackAction(action, cart, productCriteria, productSearch);
                })
            );
        }),
        ignoreElements()
    );

function sendGAEvent(category: string, action: string, label: string | undefined = undefined) {
    const newEvent = {
        category,
        action,
        label,
        nonInteraction: true,
    };
    ReactGA.event(newEvent);
}

export const googleAnalyticAddItem$ = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        ofType(getBrainTreeSuccess, getMosaicSuccess),
        withLatestFrom(
            state$.pipe(map(selectCart)),
            state$.pipe(map(gaSelectedProduct)),
            state$.pipe(map(selectProductCriteriaState))
        ),
        map(([action, cart, gaProduct, productCriteria]) => {
            // @ts-ignore
            const selectedProduct: CartItem = cart.items[0];
            // @ts-ignore
            if (gaProduct && gaProduct.sku && gaProduct.sku !== selectedProduct.sku) {
                // @ts-ignore
                sendGARemoveToCart(gaProduct, productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search');
            }
            // @ts-ignore
            if (!gaProduct || !gaProduct.sku || gaProduct.sku !== selectedProduct.sku) {
                sendGAAddToCart(cart, productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search');
            }
            return addGAProduct(selectedProduct);
        })
    );

function sendGAProductsImpression(productsearch: ProductSearch, productListName: string) {
    if (productsearch && productsearch.products && productsearch.products.length > 0) {
        productsearch.products.forEach((p, index) => {
            ReactGA.plugin.execute('ec', 'addImpression', {
                id: p.sku,
                name: p.name,
                variant: p.productAttributes.level,
                list: productListName,
                position: index + 1,
            });
        });
        sendGAEvent(EVENT_CATEGORY_CART, 'impression');
    }
}

function sendGAProductDetail(cart: Cart, productListName: string) {
    if (cart && cart.items && cart.items.length > 0) {
        cart.items.forEach((p) => {
            ReactGA.plugin.execute('ec', 'addProduct', {
                id: p.sku,
                name: p.name,
                variant: p.productAttributes.level,
                quantity: p.quantity,
                price: p.price,
            });
        });
        ReactGA.plugin.execute('ec', 'setAction', 'detail', {
            list: productListName,
        });
        sendGAEvent(EVENT_CATEGORY_CART, 'detail');
    }
}

function sendGAAddToCart(cart: Cart, productListName: string) {
    if (cart && cart.items && cart.items.length > 0) {
        cart.items.forEach((p) => {
            ReactGA.plugin.execute('ec', 'addProduct', {
                id: p.sku,
                name: p.name,
                variant: p.productAttributes.level,
                quantity: p.quantity,
                price: p.price,
            });
        });
        ReactGA.plugin.execute('ec', 'setAction', 'add', {
            list: productListName,
        });
        sendGAEvent(EVENT_CATEGORY_CART, 'add');
    }
}

function sendGARemoveToCart(cartItem: CartItem, productListName: string) {
    ReactGA.plugin.execute('ec', 'addProduct', {
        id: cartItem.sku,
        name: cartItem.name,
        variant: cartItem.productAttributes.level,
        quantity: cartItem.quantity,
        price: cartItem.price,
    });
    ReactGA.plugin.execute('ec', 'setAction', 'remove', {
        list: productListName,
    });
    sendGAEvent(EVENT_CATEGORY_CART, 'remove');
}

function sendGACheckout(cart: Cart, paymentType: string, productListName: string) {
    if (cart && cart.items && cart.items.length > 0) {
        cart.items.forEach((p) => {
            ReactGA.plugin.execute('ec', 'addProduct', {
                id: p.sku,
                name: p.name,
                variant: p.productAttributes.level,
                quantity: p.quantity,
                price: p.price,
            });
        });
        ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
            option: paymentType,
            list: productListName,
        });
        sendGAEvent(EVENT_CATEGORY_CART, 'checkout');
    }
}

function trackAction(action: Action, cart: Cart, productCriteria: ProductCriteria, productSearch: ProductSearch) {
    if (isType(action, searchProduct)) {
        return sendGAEvent(EVENT_CATEGORY_SEARCH, 'Criteria', JSON.stringify(buildGASearchCriteria(productCriteria)));
    }
    if (isType(action, placeOrder)) {
        return sendGAEvent(EVENT_CATEGORY_CART, 'PlaceOrder', JSON.stringify(action.payload));
    }
    // Enhance Ecommerce: tracking product impression
    if (isType(action, showProductSuccess)) {
        return sendGAProductsImpression(
            productSearch,
            productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search'
        );
    }
    // Enhance Ecommerce: tracking product detail
    if (isType(action, createCartSuccess)) {
        return sendGAProductDetail(cart, productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search');
    }
    // Enhance Ecommerce: tracking checkout credit card
    if (isType(action, checkoutGACreditCard)) {
        return sendGACheckout(
            cart,
            'CreditCard',
            productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search'
        );
    }
    // Enhance Ecommerce: tracking checkout mosaic
    if (isType(action, checkoutGAMosaic)) {
        return sendGACheckout(
            cart,
            'Financing',
            productCriteria?.promotionCode ? 'Marketing Campaign' : 'Product Search'
        );
    }
}

function buildGASearchCriteria(productCriteria: ProductCriteria) {
    const _criteria: ProductCriteria = {};
    _criteria.promotionCode = productCriteria.promotionCode;
    _criteria.singleOrMultiple = productCriteria.singleOrMultiple;
    _criteria.homeStyle = productCriteria.homeStyle;
    _criteria.squareFootage = productCriteria.squareFootage;
    if (!productCriteria.homeSystem) {
        return _criteria;
    }
    _criteria.homeSystem = productCriteria.homeSystem;
    if (productCriteria.homeSystem === HomeSystem.Split) {
        _criteria.splitLocation = productCriteria.splitLocation;
        _criteria.furnaceOrAirHandler = productCriteria.furnaceOrAirHandler;
        if (productCriteria.furnaceOrAirHandler === FurnaceOrAirHandler.Furnace) {
            _criteria.efficiency = productCriteria.efficiency;
        }
    } else {
        _criteria.packageLocation = productCriteria.packageLocation;
        _criteria.heatPumpOrGasPack = productCriteria.heatPumpOrGasPack;
    }
    return _criteria;
}
