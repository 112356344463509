import React, { MouseEventHandler } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ContentContainer from './ContentContainer';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';
import Slide from '@material-ui/core/Slide';
import CardMedia from '@material-ui/core/CardMedia';
import { TransitionProps } from '@material-ui/core/transitions';
import Dialog from '@material-ui/core/Dialog';
import { Button } from 'components/Button';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            height: '100%',
            paddingTop: theme.spacing(10),
            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(5),
                paddingLeft: theme.spacing(1.5),
                paddingRight: theme.spacing(1.5),
            },
        },
        heading: {
            paddingBottom: theme.spacing(2.4),
            [theme.breakpoints.down('xs')]: {
                paddingBottom: theme.spacing(2),
            },
        },
        paragraph: {
            paddingBottom: theme.spacing(4),
        },
        vdo: {
            paddingBottom: theme.spacing(10),
            [theme.breakpoints.down('xs')]: {
                paddingBottom: theme.spacing(5),
            },
        },
    };
});
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function VideoOverlay(props: Props) {
    const classes = useStyles();
    const componentId = props.id ? props.id : 'vdo-overlay';
    return (
        <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
            <ContentContainer disableGrid background={'bg2'} className={classes.root}>
                <Grid container>
                    <Hidden xsDown>
                        <Grid item sm={1} md={3} />
                    </Hidden>
                    <Grid item xs={12} sm={10} md={6} container direction={'column'}>
                        <Grid item className={classes.heading}>
                            <Heading variant={'h3'} label={props.heading} />
                        </Grid>
                        {props.paragraph && (
                            <Grid item className={classes.paragraph}>
                                <Paragraph color={'primary'}>{props.paragraph}</Paragraph>
                            </Grid>
                        )}
                        <CardMedia
                            className={classes.vdo}
                            src={props.src}
                            component={'video'}
                            autoPlay
                            controls
                            preload={'auto'}
                        />

                        <Grid item container justify={'center'}>
                            <Button id={`${componentId}-close-btn`} color={'primary'} onClick={props.onClose}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item sm={1} md={3} />
                    </Hidden>
                </Grid>
            </ContentContainer>
        </Dialog>
    );
}

export interface Props {
    id?: string;
    open: boolean;
    heading: any;
    paragraph?: any;
    src: string;
    onClose: MouseEventHandler;
}
