import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Platinum from 'components/icons/Platinum';
import Gold from 'components/icons/Gold';
import Silver from 'components/icons/Silver';
import Bronze from 'components/icons/Bronze';
import { Heading } from '../Heading';
import { Level as LevelType } from '../../models/product';

interface Props {
    level: LevelType;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
        [theme.breakpoints.only('sm')]: {
            paddingBottom: theme.spacing(1),
        },
    },
}));

export default function Level(props: Props) {
    const classes = useStyles();
    const Component = getComponent(props.level);
    if (!Component) return null;
    return (
        <Grid container className={classes.root} spacing={1} alignItems={'center'} id={`level-${props.level}`}>
            <Grid item>
                <Component />
            </Grid>
            <Grid item>
                <Heading variant={'h2'} color={'primary'} label={props.level} />
            </Grid>
        </Grid>
    );
}

export function getComponent(level: LevelType) {
    switch (level) {
        case 'Platinum':
            return Platinum;
        case 'Gold':
            return Gold;
        case 'Silver':
            return Silver;
        case 'Bronze':
            return Bronze;
        default:
            return null;
    }
}
